.v2-table {
  width: 100%;
  height: 100%;

  background-color: $white;

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    width: 100%;
    border-bottom: 1px solid $primary-blue;
  }

  &__title {
    font-size: $font-size-ml;
    font-weight: $font-weight-bolder;
  }

  &__link {
    width: 24px;
    height: 24px;
    cursor: pointer;
    transform: rotate(45deg);

    img {
      width: 24px;
      height: 24px;
    }
  }

  &__data {
    width: 100%;
    height: 95%;
    overflow: auto;
  }

  &__table {
    width: 100%;
    white-space: nowrap;
    border-collapse: collapse;
    overflow-x: scroll;
    border-collapse: collapse;

    tr {
      // border-bottom: 1px solid $primary-blue;
      height: 50px;
      vertical-align: middle;
    }

    th {
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      padding: 15px;
      max-width: 150px;

      & > div {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      & img {
        margin-top: 2px;
        max-width: 7px;
        object-fit: contain;
        cursor: pointer;
      }
    }

    td {
      text-align: left;
      font-size: $font-size-sm2;
      padding: 15px;
      max-width: 180px;
    }

    &-flexcell {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      & input[type="checkbox"] {
        accent-color: $primary-black;
        cursor: pointer;
      }

      & img {
        max-width: 16px;
        object-fit: contain;
      }
    }

    &-cell-subtext {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 8px;
    }

    &-subtext {
      font-family: $lato;
      font-size: $font-size-xxs;
      font-weight: $font-weight-thin;
      line-height: 12.1px;
      text-align: left;
    }

    &-cell-smalltag {
      display: flex;
      gap: 5px;
    }

    &-smalltag {
      margin-top: -2px;
    }

    &-cell-toggle {
      & > p {
        font-size: $font-size-md;
        font-weight: 400;
        text-decoration: underline;
      }

      &:hover {
        & > p {
          color: $primary-blue;
        }
      }
    }

    &-cell-hztext {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      & > span {
        font-size: $font-size-xxs;
        color: $primary-blue;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &-hztext {
      font-family: $lato;
      font-size: $font-size-xxs;
      font-weight: $font-weight-default;
      line-height: 12.1px;
      text-align: center;
      margin-bottom: -2px;
    }

    &-action {
      width: 70%;
      display: flex;
      justify-content: center;

      & button {
        flex-grow: 1;
      }
    }

    &-imglink {
      max-width: 24px;
      object-fit: contain;
    }

    &--align-left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &--align-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &--align-center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__data-row {
    border-bottom: 1px solid $wood-grey;
    &:hover {
      cursor: pointer;
      box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
        0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
    }

    &:hover td:first-child {
      border-radius: 10px 0px 0px 10px;
    }

    &:hover td:last-child {
      border-radius: 0px 10px 10px 0px;
    }

    &--active {
      background-color: $bg-neutral;
    }
  }

  &__data-head {
    border-bottom: 1px solid $wood-grey;
  }

  &--border {
    border: 1px solid $wood-grey;
    border-radius: $radius-home-cards;
    padding: 24px;
  }

  &__ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__group {
    &--align-center {
      text-align: center;
    }

    &--align-left {
      text-align: left;
    }

    &--align-right {
      text-align: right;
    }

    &--border {
      border-bottom: 1px solid $wood-grey;
    }
  }

  &__help {
    width: 100%;
    display: flex;
    text-align: end;
    justify-content: flex-end;

    &-text {
      font-size: $font-size-md;

      & > span {
        font-size: $font-size-md;
        font-weight: $font-weight-bolder;
      }
    }

    &-close {
      font-size: $font-size-md;
      font-weight: $font-weight-bold;
      color: $primary-blue;
      cursor: pointer;
      width: max-content;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__subgroup {
    display: flex;
    align-items: flex-end;
    gap: 4px;

    &--center {
      justify-content: center;
    }

    &--left {
      justify-content: left;
    }

    &--right {
      justify-content: right;
    }

    & > span {
      font-size: $font-size-xxs;
    }
  }
}
