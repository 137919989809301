.o-company-details {
  display: flex;
  flex-direction: row;
  gap: 60px;
  justify-content: center;

  &__basic-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__text {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    &--heading {
      font-weight: $font-weight-default;
      font-size: $font-size-ml;
      color: $placeholder-grey;
    }

    &--company-name {
      font-weight: $font-weight-bolder;
      font-size: $font-size-ml;
      color: $primary-blue;
    }

    &--address {
      font-weight: $font-weight-bolder;
      font-size: $font-size-ml;
      width: 280px;
      word-wrap: break-word;
    }

    &__radio {
      gap: 12px;

      &__button {
        gap: 12px;
        padding: 12px 50px 12px 16px;
        border: 1px solid;
        border-radius: 8px;
        border-color: $border-modal;
      }
    }
  }
  &__advanced-details {
    display: flex;
    flex-direction: column;
    gap: 28px;

    &--closer {
      gap: 10px;
    }

    &__button {
      padding: 12px 55px;
    }

    &__text {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &--heading {
        font-size: $font-size-ml;
        font-weight: $font-weight-default;
        color: $placeholder-grey;
      }

      &--value {
        font-size: $font-size-ml;
        font-weight: $font-weight-bolder;
        color: $black-shade;
      }

      &--label {
        font-size: $font-size-sm;
        font-weight: $font-weight-default;
      }
    }
  }
}
