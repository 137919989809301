.home {
  width: 100%;
  height: 100%;
  padding: 0px 0px 16px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  // overflow-y: hidden;
  overflow-y: auto;

  &__banner {
    width: 100%;
  }

  &__container {
    width: 100%;
    display: flex;
    gap: 10px;
  }

  &__header {
    height: 8%;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__left {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 75%;
    overflow-y: hidden;

    &-top {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &-bottom {
      flex-grow: 1;
      width: 100%;
      // height: calc(100vh - 365px);
      display: flex;
      flex-direction: column;
      gap: 10px;
      border-radius: $radius-home-cards;
      overflow-y: hidden;
    }
  }

  &__right {
    width: 25%;
    display: flex;
    flex-direction: column;

    &-top {
      width: 100%;
      flex: 1;
      // height: 50%;
      display: flex;
      flex-direction: column;

      // &--expand {
      //     height: 100%;
      // }
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      overflow: hidden;
      position: sticky;
      top: 10px;
    }
  }

  &__top-panel {
    width: 100%;
  }

  &__add-sb {
    width: 100%;
    min-height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
    padding-right: 20px;

    &--extra {
      height: 16px !important;
      width: 16px !important;
    }
  }

  &__summary {
    width: 100%;
    display: flex;
    gap: 10px;
  }

  &__sb-summary,
  &__ir-summary {
    width: 50%;
  }

  &__alerts {
    width: 100%;
    min-height: 300px;
    // height: 300px;
    // flex: 1;
    // padding: 10px 24px;
    border-radius: $radius-home-cards;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border: 1px solid $bg-white-gray;
    overflow: hidden;
    transition: max-height 0.6s ease, height 0.6s ease;

    // &:hover {
    //     border: 1px solid $primary-blue;
    //     box-shadow: $shadow-card;
    // }

    &-top {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    &-tabs {
      position: relative;
      // padding: 6px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border-bottom: 1px solid $border-modal;

      &--collapse {
        // margin-bottom: 10px;
      }
    }

    &-tab {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      padding: 6px;

      &-content {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        border-bottom: 2px solid transparent;

        &--active {
          border-bottom: 2px solid $primary-blue;
        }

        &:hover {
          border-bottom: 2px solid $primary-blue;

          & > p {
            // font-weight: $font-weight-bolder;
            color: $primary-blue;
            // text-decoration: underline 1px solid $primary-blue;
            // text-underline-offset: 5px;
          }
          & > span {
            background-color: $blue-bg-light;
          }
        }
      }

      &-text {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-default;
        line-height: 24px;
        text-align: center;

        &--active {
          font-weight: $font-weight-bolder;
          color: $primary-blue;
          // text-decoration: underline 1px solid $primary-blue;
          // text-underline-offset: 5px;
        }
      }

      &-count {
        min-width: 16px;
        min-height: 15px;
        padding: 2px 6px;
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-default;
        line-height: 15.73px;
        text-align: center;
        border-radius: $radius-circle;
        background-color: $bg-white-gray;
        color: $primary-black;

        &--active {
          background-color: $blue-bg-light;
        }
      }

      &-minimise {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0px;
        top: -12px;
        cursor: pointer;
        background-color: transparent;
        border-radius: $radius-circle;
        padding: 2px;

        &:hover {
          background-color: $border-blue;
        }

        & > img {
          max-width: 22px;
          object-fit: contain;
        }
      }
    }

    &-items {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 5px;
      overflow-y: auto;
    }

    &-container {
      display: flex;
      flex-direction: column;
      // gap: 6px;
      height: 100%;
      overflow-y: auto;

      &-task {
        gap: 6px;
      }
    }

    &-tasks {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 5px;
      overflow-y: auto;
    }

    &-viewmore {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 0;

      &-top {
        border-top: 1px solid $border-modal;
      }

      &-content {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        cursor: pointer;

        &:hover {
          color: $primary-blue;
          text-decoration: underline 1px solid $primary-blue;
          text-underline-offset: 5px;

          & > img {
            filter: $filter-primary-blue;
          }
        }
      }

      &-text {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-default;
        line-height: 24px;
        text-align: center;
      }

      & img {
        max-width: 16px;
        object-fit: contain;
      }
    }

    &--expand {
      height: 55vh;
      // height: calc(100vh - 252px);
    }

    &--collapse {
      height: 70px;
    }
  }

  &__title {
    font-size: $font-size-ml;
    font-weight: $font-weight-bolder;
    line-height: 24px;
  }

  &__rsb {
    // height: calc(100vh - 365px);
    // height: 100%;
    width: 100%;
    border-radius: $radius-home-cards;
    border: 1px solid $bg-white-gray;
    overflow-y: auto;

    &:hover {
      // border: 1px solid $primary-blue;
      box-shadow: $shadow-card;
    }
  }

  &__hebrc {
    min-height: 275px;
    flex: 1;
    // height: 100%;
    // height: 50%;
    // height: calc(100vh - 405px);

    width: 100%;
    border: 1px solid $bg-white-gray;

    border-radius: $radius-home-cards;
    background: $white;
    overflow-y: auto;
    transition: max-height 0.6s ease, height 0.6s ease;

    &--expand {
      height: calc(100vh - 252px);
    }
    &--collapse {
      min-height: 70px;
      overflow: hidden;
    }
  }

  &__no-alert {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  &__no-alert-img {
    max-width: 110px;
    object-fit: contain;
  }

  &__no-alert-text {
    font-family: $lato;
    font-size: $font-size-md;
    font-weight: $font-weight-default;
    line-height: 22px;
    text-align: center;
    opacity: 0.5;
  }
}
