.s-modal {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: $primary-black, $alpha: 0.8);

  &__card {
    width: 510px;
    height: 527px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    align-items: center;
    position: relative;
    padding: 14px 35px;
    border-radius: $radius-modal;
    background-color: $white;
  }

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid $border-light-gray;
    gap: 30px;

    & > img {
      width: 80px;
      height: 80px;
    }

    &-header {
      font-family: $lato;
      font-size: $font-size-xlg;
      font-weight: $font-weight-boldest;
      line-height: 48px;
      letter-spacing: 0em;
      text-align: center;
    }

    &-content {
      margin-top: -25px;
      margin-bottom: 15px;
      padding: 0px 30px;
      font-family: $lato;
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
      color: rgba($color: $primary-black, $alpha: 0.5);
    }
  }

  &__bot {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;

    &-pending {
      margin-top: 20px;
      font-family: $lato;
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
    }

    &-status {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
      color: $primary-blue;
      cursor: pointer;
    }
  }

  &__cross {
    position: absolute;
    top: 6px;
    right: 6px;
    & > img {
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
  }
}
