.recon-summary {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__heading {
    display: flex;
    justify-content: space-between;

    &--show {
      padding-bottom: 10px;
      border-bottom: 1px solid $summary-border-gray;
    }
  }

  &__heading-v2 {
    display: flex;
    justify-content: end;
  }

  &__title {
    font-family: $lato;
    font-size: $font-size-ml;
    font-weight: $font-weight-bold;
    line-height: 30px;
    text-align: left;

    > p {
      font-size: $font-size-sm2;
      color: $wood-smoke;
      line-height: 150%;
    }
  }

  &__buttons {
    display: flex;
    gap: 10px;

    & > section {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-right: 3px;
    padding-bottom: 10px;
    overflow-y: auto;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 16px 0px;
    border-bottom: 1.5px solid $primary-blue;

    &-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }

    &-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;

      & > img {
        max-width: 101px;
        object-fit: contain;
      }
    }
    &-company {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      padding: 0px 10px 0px 0;

      & > img {
        max-width: 42px;
        object-fit: contain;
      }

      &-name {
        font-family: $lato;
        font-size: $font-size-sllg;
        font-weight: $font-weight-default;
        line-height: 30.68px;
        text-align: left;
      }
    }

    &-buyer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;

      &-key {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-default;
        line-height: 15.73px;
        text-align: left;
      }

      &-value {
        font-size: $font-size-lg;
        font-weight: $font-weight-default;
        line-height: 125%;
        text-align: left;
      }
    }

    &-powered {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 24px;
      text-align: left;
    }
  }

  &__pair {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;

    &-key {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 15.73px;
      text-align: left;
      color: $wood-smoke;
    }
    &-value {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 15.73px;
      text-align: left;
    }

    & > img {
      margin-left: 10px;
      max-width: 12px;
      object-fit: contain;
    }

    &--highlighted {
      color: $primary-blue;
    }

    &--bold {
      font-weight: $font-weight-bolder;
    }
  }

  &__section {
    &-title {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 24px;
      text-align: left;
    }
    &-count {
      width: 20px;
      height: 20px;
      background-color: $wood-grey;
      border-radius: $radius-circle;
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 19px;
      text-align: center;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 6px 0 16px 0;
    border-bottom: 1px solid $summary-border-gray;

    &-info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      align-content: center;
      gap: 14px 260px;
      margin: 0 auto;
    }

    &-sb {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-color: $gray-white;
      padding: 10px 80px;
      border-radius: $radius-home-cards;
      border: 1px solid $wood-grey;

      &-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
      }

      &-left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 50px;
        padding-right: 50px;
      }
      &-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding-left: 50px;
        border-left: 1px solid $primary-black;
      }

      &-key {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-default;
        line-height: 15.73px;
        text-align: left;
      }

      &-value {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-bolder;
        line-height: 16.94px;
        text-align: left;
        display: flex;
        flex-direction: row;
        align-items: baseline;
      }
      &--amt {
        font-size: $font-size-lg;
        // color: $green;
      }
      &-decimal {
        font-size: $font-size-xxs;
        font-weight: $font-weight-bolder;
        line-height: 100%;
        text-align: left;
      }
    }
  }

  &__tr-details {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-cont {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    &-label {
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 125%;
      color: $border-black;
    }

    &-icon {
      height: 16px;
    }

    &-value {
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 125%;
    }
  }

  &__port {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 10px 10px 20px 10px;
    border-bottom: 1px solid $summary-border-gray;

    & > img {
      max-width: 285px;
      object-fit: contain;
    }
  }

  &__invoice {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    padding: 10px 0px;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
      }

      &-right {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-right: 20px;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 20px;
    }

    &-invoice {
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: flex-start;
      align-items: center;
      border: 1px solid $summary-border-gray;
      border-radius: $radius-home-cards;
      padding: 20px 20px 10px 20px;
    }

    &-info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      align-items: center;
      gap: 14px 260px;
      margin: 0 auto;
    }

    &-toggle {
      width: max-content;
      background-color: $gray-white;
      border-radius: $radius-home-cards;
      border: 1px solid $wood-grey;
      margin: 10px auto;
      padding: 12px 42px;
      display: flex;
      align-items: center;
      gap: 24px;
    }

    &-box {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &-img {
      max-width: 16px;
      object-fit: contain;
    }

    &-title {
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
    }

    &-number {
      font-size: $font-size-md;
      font-weight: $font-weight-bolder;
    }

    &-currency {
      font-size: $font-size-xxs;
      font-weight: $font-weight-thin;
    }
  }

  &__transport {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    padding: 10px 0;

    &-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
    }

    &-mode {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 20px;
    }

    &-body {
      display: flex;
      border: 1px solid $summary-border-gray;
      border-radius: $radius-home-cards;
      padding: 20px 0px;
    }

    &-docs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      align-content: center;
      gap: 14px 260px;
      margin: 0 auto;
    }
  }

  &__mapirm {
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    padding-top: 10px;

    &-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
    }
  }

  &__submission {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    border-bottom: 1px solid $summary-border-gray;

    &-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
    }
    &-body {
      display: flex;
      padding: 0px 20px 20px 20px;
    }
    &-reasons {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;
    }
  }

  &__documents {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;

    &-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
    }

    &-body {
      display: flex;
      border: 1px solid $summary-border-gray;
      border-radius: $radius-home-cards;
      padding: 20px 0px;
    }

    &-docs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      align-content: center;
      gap: 14px 260px;
      margin: 0 auto;
    }
  }

  &__declaration {
    width: 70%;
    padding: 10px 0;

    &-label {
      font-family: $lato;
      font-size: $font-size-sm;
      font-weight: $font-weight-default;
      line-height: 20px;
      text-align: left;
      margin-left: 10px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 40px 10px 40px;

    &-title {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 19px;
      text-align: left;
    }
    &-text {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 19px;
      text-align: left;
    }
  }

  &__download {
    padding: 10px 10px 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  &__makerchecker {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &-cont {
      display: flex;
      flex-direction: column;
      align-items: end;
      gap: 6px;

      > div {
        display: flex;
        align-items: end;
        gap: 6px;
      }

      > span {
        display: flex;
        flex-direction: column;
        font-size: $font-size-xs;
        font-weight: $font-weight-default;
        line-height: 150%;
        color: $wood-smoke;
      }
    }

    &-label {
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 125%;
      color: $border-black;
    }

    &-value {
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 125%;
    }
  }

  &__sb-number {
    font-size: $font-size-ml;
    margin-left: -10px;
  }
}
