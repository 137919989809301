.g-settings {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: calc(100vh - 115px);
  width: 100%;
  overflow-y: hidden;
  border-radius: $radius-home-cards;
  gap: 10px;

  &__popup {
    padding: 5px 32px 18px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    gap: 18px;
    &-close {
      position: absolute;
      top: 0;
      right: 0;
      margin: 6px;
      max-width: 25px;
      object-fit: contain;
      cursor: pointer;
    }
    &-heading {
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      width: 100%;
      text-align: center;
      line-height: 24px;
      border-bottom: solid $primary-blue 1px;
      padding-bottom: 5px;
    }
    &-text {
      font-size: $font-size-md;
      text-align: center;
    }
    &-button {
      width: 105px;
      &-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }
    }
    &-container {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.55);
      z-index: 200;
      top: 0;
      left: 0;
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: $radius-home-cards;
    gap: 10px;
    width: 20%;
    max-width: 200px;
    background-color: $white;

    // &-sign-out {
    //   height: 36px;
    //   width: 180px;
    //   &-button {
    //     width: 100%;
    //     opacity: 0.53;
    //     &:not(.s-button--disabled):hover {
    //       opacity: 1;
    //     }
    //   }
    // }

    &-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      height: 36px;
      width: 180px;
      padding: 10px 20px;
      border-radius: $radius-button;
      cursor: pointer;

      &:hover {
        transform: scale(1.02);
      }

      &-icon {
        max-width: 16px;
        object-fit: contain;
      }

      &-text {
        font-weight: $font-weight-default;
        font-size: $font-size-sm2;
        color: $natural-black;
      }

      &--selected {
        font-weight: $font-weight-bolder;
        background-color: $bg-white-gray;
      }

      &--disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }

      &:not(&--selected):hover {
        border-radius: $radius-modal;
        background-color: $bg-white-gray;
      }

      &--logout {
        justify-content: center;
      }
    }
  }

  &__body {
    flex-grow: 1;
    height: 100%;
    // max-width: 830px;
    overflow-y: auto;
  }

  &__buttons {
    position: absolute;
    right: 0px;
    top: 0px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 100%;
  }

  &__cont {
    width: 100%;
    background-color: $white;
    padding: 10px;
    border-radius: $radius-home-cards;
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 10px 20px;
    font-size: $font-size-sm2;
    font-weight: $font-weight-bolder;
    background-color: $bg-settings-blue;
    border-radius: 5px 10px 0px 0px;

    & > span {
      font-weight: $font-weight-default;
      color: $wood-smoke;
    }
  }

  &__card {
    display: flex;
    gap: 30px;
    width: 100%;
    border-radius: $radius-home-cards;
    padding: 10px 20px;

    &-title {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 15.73px;
      text-align: left;
      margin: 10px 0px;
    }
  }

  &__left {
    width: 25%;
    max-width: 185px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    border-right: 1px solid $wood-grey;

    > img {
      width: 90px;
      height: 90px;
      border-radius: $radius-circle;
      border: 1px solid $wood-grey;
      object-fit: cover;
      object-position: center;
    }
  }

  &__right {
    width: 75%;
    max-width: 575px;
    position: relative;

    &-left {
      flex: 1;
    }

    &-right {
      flex: 1;
    }
  }

  &__fields {
    width: 100%;
    display: grid;
    grid-gap: 16px 38px;

    &-item {
      width: 100%;
    }

    &-label {
      font-size: $font-size-sm2;
      color: $wood-smoke;
      line-height: 125%;
      display: flex;
      align-items: center;
      gap: 10px;
      min-height: 18px;
      &-hyperlink {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
      }
    }

    &-value {
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 150%;
      padding: 8px 0px 0px 0px;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &-status {
      width: 16px;
      height: 16px;
    }

    &-edit {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      &-img {
        width: 16px;
        height: 16px;
      }
      &:hover {
        background: $border-blue;
        border-radius: 50%;
        & > img {
          filter: brightness(0) saturate(100%) invert(28%) sepia(97%)
            saturate(1330%) hue-rotate(185deg) brightness(92%) contrast(97%);
        }
      }

      &--address {
        background: transparent;
        border-radius: 50%;
        & > img {
          filter: invert(59%) sepia(9%) saturate(17%) hue-rotate(315deg)
            brightness(90%) contrast(80%);
        }

        &:hover {
          background: transparent;
          border-radius: 50%;
          & > img {
            filter: brightness(0) saturate(100%) invert(28%) sepia(97%)
              saturate(1330%) hue-rotate(185deg) brightness(92%) contrast(97%);
          }
        }
      }
    }

    &-input {
      padding-top: 6px;
      width: 100%;
    }

    &-comp {
      margin-top: 10px;
    }

    &-editable {
      grid-column: span 1;
      width: 100%;
      display: flex;
      gap: 38px;
      &--phone {
        grid-column: 1/2;
        grid-row: 2;
      }
      &--email {
        grid-column: 2/3;
        grid-row: 2;
      }
    }

    &-star {
      width: 20px;
      height: 20px;
    }

    &-italics {
      font-weight: $font-weight-default;
    }

    &-subtext {
      font-size: $font-size-sm;
    }

    &-hyperlink {
      font-size: $font-size-sm2;
      font-weight: $font-weight-bold;
      line-height: 150%;
      color: $primary-blue;
      text-decoration: underline;
      word-break: break-all;
    }
    &--no-padding {
      padding: 0px;
    }
  }

  &__edit-btn {
    position: absolute;
    top: -20px;
    right: -10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 12px;
  }

  &__director {
    margin-top: 0px;
  }

  &__director-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__consignee {
    display: flex;
    margin-bottom: 16px;
    gap: 35px;
  }

  &__switch {
    display: flex;
    gap: 60px;
    margin-top: 16px;
  }

  &__toggle {
    padding: 8px 0px 0px 20px;
  }

  &__address {
    width: 100%;
    display: flex;
    flex-direction: column;

    &-line {
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 150%;
    }
  }

  &__horizontal {
    height: 1px;
    grid-column: span 2;
    background-color: $grey-200;
    border: none;
    outline: none;
  }

  &__additional {
    margin-top: 24px;
    padding-right: 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: $radius-circle;

    &-cont {
      cursor: pointer;
      position: relative;
      width: 90px;
      height: 90px;
      border-radius: $radius-circle;
      border: 1px solid $wood-smoke;
    }

    &-edit {
      position: absolute;
      bottom: -2px;
      right: -2px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $wood-grey;
      border-radius: $radius-circle;
      &-image {
        width: 17px;
        height: 17px;
        border-radius: $radius-circle;
      }
    }
  }

  &__modal-cont {
    z-index: 5;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba($color: $primary-black, $alpha: 0.7);
  }

  &__identity-popup {
    width: 420px;
    height: 332px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: $radius-help;
    background-color: $white;
    padding: 20px;

    &-header {
      padding: 10px;
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: $radius-search $radius-search 0 0;
      border-bottom: 0.5px solid $grey-200;
      background-color: $bg-settings-blue;
    }

    &-close {
      max-width: 24px;
      object-fit: contain;
      cursor: pointer;
    }

    &-title {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 16px;
      text-align: left;
    }

    &-inputs {
      flex-grow: 1;
      padding: 10px 10px 0px 10px;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    &-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }

  &__address-popup {
    // width: 420px;
    // height: 332px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: $radius-help;
    background-color: $white;
    padding: 20px;
    min-width: 520px;
  }
}

.sec-settings {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 100%;
  border-radius: $radius-home-cards;

  &__top-section {
    width: 100%;
  }

  &__bottom-section {
    // max-height: 64%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }

  &__body {
    width: 80%;
    max-width: 830px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
  }

  &__login-details {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 28px;

    &-left {
      width: 25%;
      max-width: 185px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-right: 1px solid $wood-grey;

      & > img {
        max-width: 82px;
        object-fit: contain;
      }
    }

    &-right {
      display: flex;
      gap: 40px;
    }
    &-button {
      width: 100%;
    }
  }

  &__2fa {
    &--label {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 15.73px;
      text-align: left;
      margin-left: 5px;
    }
  }

  &__pre-logins {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__devices {
    height: 90%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $wood-grey;
    overflow-y: auto;
    padding-right: 10px;
  }

  &__connections {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 0 10px 0;

    &--social {
      justify-content: space-between;
    }
  }

  &__social {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;

    &-title {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-bold;
      line-height: 15.73px;
      text-align: left;
      color: $wood-smoke;
    }
    &-apps {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
    }

    &-link {
      max-width: 22px;
      object-fit: contain;
      cursor: pointer;
    }
  }

  &__policy {
    // flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    &-title {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-bold;
      line-height: 15.73px;
      text-align: left;
      color: $wood-smoke;
    }
    &-terms {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-bold;
      line-height: 15.73px;
      text-align: left;
      color: $primary-blue;
      text-decoration: underline 1px solid $primary-blue;
      text-underline-offset: 5px;
      cursor: pointer;
    }
    &-policy {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-bold;
      line-height: 15.73px;
      text-align: left;
      color: $primary-blue;
      text-decoration: underline 1px solid $primary-blue;
      text-underline-offset: 5px;
      cursor: pointer;
    }
  }

  &__popup {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 420px;
    border-radius: $radius-help;
    background-color: $white;
    padding: 20px;

    &-header {
      padding: 10px;
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: $radius-search $radius-search 0 0;
      border-bottom: 0.5px solid $grey-200;
      background-color: $bg-settings-blue;
    }

    &-close {
      max-width: 24px;
      object-fit: contain;
      cursor: pointer;
    }

    &-title {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 16px;
      text-align: left;
    }

    &-inputs {
      padding: 10px 10px 24px 10px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      border-bottom: 1px solid $grey-200;
    }

    &-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
}

.b-settings {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 100%;
  border-radius: $radius-home-cards;

  &__header {
    height: 44px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    padding: 10px 20px;
    border-radius: $radius-home-cards;
    background-color: $white;

    &-button {
      & > img {
        filter: $filter-primary-blue;
      }
    }
  }

  &__left {
    width: 25%;
    max-width: 185px;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    border-right: 1px solid $wood-grey;

    & > img {
      max-width: 120px;
      object-fit: contain;
      height: fit-content;
    }
  }

  &__fields {
    &--branch {
      width: 80%;
    }
  }
}

.h-settings {
  &__card {
    border: 1px solid $wood-grey;
    border-radius: $radius-home-cards;
    padding: 15.5px 20px;
    display: flex;
    align-items: center;
    gap: 20px;

    > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__status {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    min-width: 16px;

    &--warning {
      background-color: $warning-yellow;
    }

    &--success {
      background-color: $success-green;
    }

    &--alert {
      background-color: $red-alert-main;
    }
  }

  &__info {
    font-size: $font-size-sm2;
    font-weight: 150%;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-top: 10px;
  }

  &__left {
    display: flex;
    gap: 13px;
    align-items: center;
  }

  &__bank-icon {
    width: 30px;
    min-width: 30px;
  }

  &__bank-name {
    font-size: $font-size-sm2;
  }
}

.plat-settings {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: $white;

  &__body {
    width: 100%;
  }

  &__card {
    width: 100%;
    display: flex;
    gap: 20px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__left {
    width: 23%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    padding-right: 20px;
    border-right: 1px solid $summary-border-gray;
  }

  &__title {
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-bold;
    line-height: 15.73px;
    text-align: left;
  }

  &__description {
    font-family: $lato;
    font-size: $font-size-xs;
    font-weight: $font-weight-default;
    line-height: 13.31px;
    text-align: left;
    color: $wood-smoke;
  }

  &__right {
    width: 77%;
    max-width: 480px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    flex-direction: column;
  }

  &__field {
    min-width: 180px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-content: flex-start;
    align-items: flex-start;

    &-title {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 15.73px;
      text-align: right;
      color: $wood-smoke;
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
    }

    &-value {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 16px;
      text-align: left;
    }
  }
}

.manage-settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  // height: 100%;
  width: 100%;
  border-radius: $radius-home-cards;
  background-color: $white;
  padding: 10px;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
    width: 100%;

    &--underline {
      border-bottom: 1px solid $border-modal;
    }

    &__tab {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 24px;
      text-align: left;
      cursor: pointer;

      &--active {
        color: $primary-blue;
        box-shadow: 0px 2px 0px 0px #046cb8;
        font-weight: $font-weight-bolder;
      }

      &--inactive {
        padding-bottom: 1.5px;
        border-bottom: 1px solid $white;
        &:hover {
          color: $primary-blue;
          border-bottom: 1px solid $primary-blue;
        }
      }
      &-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
      }
      &-selected-text {
        font-family: $lato;
        font-size: $font-size-xs;
        font-weight: 400;
        line-height: 19px;
        color: $natural-black;
        & > span {
          font-size: $font-size-sm2;
        }
      }
    }

    &-add {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      &-icon {
        width: 16px;
        height: 16px;
      }
      &-text {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-bolder;
        line-height: 19px;
        text-align: left;
        color: $primary-blue;
      }
    }
  }
  &__delete-header {
    background-color: $bg-white-gray;
    padding: 4px 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0px;
    width: 100%;

    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      padding: 6px 10px;
      border-radius: $radius-button;

      &:hover {
        color: $primary-blue;
        text-decoration: underline;
        background-color: $blue-bg-lighter;

        .manage-settings__delete-header-image {
          filter: $filter-primary-blue;
        }
      }
    }

    &-item-v2 {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      &--cursor {
        cursor: pointer;
      }
    }
    &-image {
      height: 20px;
      cursor: pointer;
      filter: $filter-primary-blue;

      &--cross {
        padding: 4px;
      }

      &--clear {
        width: 20px;
        height: 18px;
        object-fit: contain;
      }
    }

    &-partition {
      width: 1px;
      height: 100%;
      margin: 0px 10px;
      background-color: $primary-black;
    }

    &-text {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 19px;
      text-align: left;
      color: $primary-blue;
      &--blue {
        font-weight: $font-weight-bolder;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  &__body {
    border-radius: 10px;
    width: 100%;
    overflow: auto;

    &--access {
      border: 1px solid $bg-white-gray;
      padding: 10px 16px;
    }
  }
  &__options-modal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 10px;
    border: 1px solid #d1d1d1;
    background-color: $white;
    &-icon {
      width: 16px;
      height: 16px;
    }
    &-text-container {
      display: flex;
      flex-direction: column;
    }

    &-text-par {
      display: flex;
      align-items: center;
      gap: 2px;
    }

    &-subText {
      font-family: $lato;
      font-size: $font-size-xs;
      font-weight: $font-weight-default;
      line-height: 16px;
      text-align: left;
      color: $wood-smoke;
    }
    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      padding: 15.5px 20px 15.5px;
      border-radius: 10px;
      width: 100%;

      &:hover {
        background-color: $blue-bg-light;
      }

      &--delete {
        cursor: pointer;
      }
    }
    &-copy {
      width: 20px;
      visibility: hidden;

      &--visible {
        visibility: visible;
      }
    }
  }
  &__add-user-modal {
    border-radius: 15px;
    background-color: $white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px 30px 30px 30px;

    &-bg {
      z-index: 5;
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(29, 28, 29, 0.7);
      cursor: auto;
    }
    &-heading {
      font-family: $lato;
      font-size: $font-size-lg;
      font-weight: $font-weight-bolder;
      line-height: 24.2px;
      text-align: center;
      color: $black;
    }
    &-close {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 15px;
      right: 8px;
      cursor: pointer;
    }
    &__inputs {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}

.partners-settings {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: $radius-home-cards;
  overflow-y: auto;

  &__dgft {
    width: 100%;
    height: 100%;
  }
}

.p-settings {
  &__modal {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    max-width: 450px;
    background-color: $white;
    padding: 20px;
    border-radius: $radius-help;

    &-header {
      display: flex;
      gap: 7px;

      & > h3 {
        font-size: $font-size-lg;
        font-weight: $font-weight-bolder;
        line-height: 120%;
      }
    }

    &-icon {
      width: 100%;
      padding: 20px 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        width: 185px;
      }
    }

    &-point {
      width: 24px;
      height: 24px;
    }

    &-benefits {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &-benefit {
      display: flex;
      gap: 30px;

      > p {
        display: flex;
        flex-direction: column;
      }

      &-title {
        font-size: $font-size-ml;
        font-weight: $font-weight-bolder;
        line-height: 120%;
      }

      &-text {
        font-size: $font-size-md;
        line-height: 150%;
        color: $wood-smoke;
      }
    }

    &-text {
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 133%;
      text-align: center;
      max-width: 335px;
      margin: 0px auto;
      color: $primary-blue;
      padding: 20px 0px;
    }

    &-security {
      display: flex;
      gap: 25px;
    }
    &-sec {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      justify-content: center;
      > img {
        width: 27.5px;
      }

      > p {
        font-size: $font-size-sm2;
        font-weight: $font-weight-default;
        line-height: 150%;
      }
    }

    &-buttons {
      display: flex;
      gap: 20px;
      padding-top: 20px;
      align-items: center;
      justify-content: center;
    }
  }
}
