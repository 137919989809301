.request-feature {
  width: 444px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 20px 16px 20px 16px;
  // border: 1px solid $grey-200;
  border-radius: $radius-help;
  background-color: $white;

  &__title {
    font-family: $lato;
    font-size: $font-size-lg;
    font-weight: $font-weight-bolder;
    line-height: 20px;
    text-align: center;
  }

  &__icon {
    max-width: 40px;
    object-fit: contain;
  }

  &__top {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-header {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 20px;
      text-align: center;
      color: $primary-blue;
    }
  }

  &__bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-header {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 20px;
      text-align: center;
    }
  }

  &__close {
    position: absolute;
    max-width: 26px;
    object-fit: contain;
    top: 16px;
    right: 12px;
    cursor: pointer;
  }

  &__features {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 20px;
    border: 1px solid $bg-white-gray;
    background-color: $gray-white;
    border-radius: $radius-modal;

    &-emojis {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
    }

    &-list {
    }
  }
  &__emoji {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    & > img {
      max-width: 20px;
      object-fit: contain;
    }

    &-text {
      font-family: $lato;
      font-size: $font-size-xxs;
      font-weight: $font-weight-default;
      line-height: 14px;
      text-align: center;
    }
  }

  &__feature {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-options {
      width: 55%;
      padding-right: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-header {
      min-width: 125px;
      padding: 5px 0;
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 20px;
      text-align: left;
      border-right: 1px solid $primary-black;
    }
  }

  &__action {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}
