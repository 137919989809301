.new-recon {
  width: 100%;
  height: 100%;
  padding: 0px 0px 20px 0px;
  overflow-y: auto;

  &__sbsummary {
    z-index: 4 !important;
    top: 0px;
    z-index: 100;
    padding: 0px 20px 10px 20px;
    background-color: $white;
    border-radius: $radius-home-cards;
    border-bottom: 1px solid $border-light-gray;
    box-shadow: $shadow-card;
    position: sticky;
  }

  &__doc-stage {
    margin-top: 16px;
  }
}

.r-lodgement {
  margin-top: 16px;
  padding: 8px;
  border-radius: $radius-home-cards;
  background-color: $white;

  &:hover {
    box-shadow: 1px 2px 2px $border-light-gray;
  }

  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    cursor: pointer;
  }

  &__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    &-title {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bold;
      line-height: 30px;
      text-align: left;
      display: flex;
      gap: 12px;
      align-items: center;

      > p {
        background-color: $yellow-bg;
        padding: 0px 8px;
        line-height: 140%;
        border-radius: $radius-alert;
      }
    }

    &-stepdone {
      max-width: 22px;
      object-fit: contain;
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px 6px 0px;
    gap: 24px;
  }

  &__alert {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0px 5px;
    background-color: $red-alert;
    gap: 20px;
    margin: 10px 20px;
    > img {
      width: 16px;
      height: 16px;
    }
  }

  &__inputs {
    margin: 16px 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__input {
    display: flex;
    gap: 48px;
    align-items: center;
  }

  &__label {
    width: 400px;
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
  }

  &__declration {
    margin: 0px 14px;
  }

  &__progress {
    margin: 20px 20px 10px 20px;
    display: flex;
    gap: 40px;
    &-img {
      width: 137px;
    }

    &-text {
      max-width: 436px;
      margin-bottom: 40px;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 180%;
      > span {
        font-weight: $font-weight-bolder;
      }
    }

    &-date {
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 120%;

      > span {
        font-weight: $font-weight-default;
        color: $wood-smoke;
      }
    }
  }
}

.r-payment-awaited {
  display: flex;
  flex-direction: column;
  align-items: center;
  > img {
    width: 179px;
    margin: 6px 0px 16px 0px;
  }

  &__middle {
    width: 100%;
    padding-top: 16px;
    border-top: 1px solid $summary-border-gray;
  }

  &__overdue {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;

    &-label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 125%;
    }

    &-value {
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 125%;
      color: $primary-red;
      padding-right: 25px;
    }

    &  img {
      max-width: 16px;
      object-fit: contain;
    }
  }

  &__input {
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-label {
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 125%;
    }
  }

  &__remind {
    padding-top: 16px;
    border-top: 1px solid $summary-border-gray;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__error {
    display: flex;
    align-items: center;
    gap: 16px;
    > img {
      width: 16px;
      height: 16px;
    }

    > p {
      font-size: $font-size-sm;
      font-weight: $font-weight-default;
      line-height: 150%;
      color: $primary-red;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding-top: 16px;
  }
}
