.buyer-details {
  width: 575px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  pointer-events: all;
  cursor: default;

  &__header {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $primary-blue;

    &-left {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 24px;
      text-align: left;
    }

    &-right {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 24px;
      text-align: left;
      color: $primary-blue;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__address {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;

    &-left {
      font-family: $lato;
      font-size: $font-size-md;
      font-weight: $font-weight-default;
      line-height: 16.94px;
      text-align: left;
      min-width: 40%;
    }

    &-right {
      font-family: $lato;
      font-size: $font-size-md;
      font-weight: $font-weight-bolder;
      line-height: 21px;
      text-align: left;
      // padding-right: 40px;
    }
  }
  &__contact-name {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;

    &-left {
      font-family: $lato;
      font-size: $font-size-md;
      font-weight: $font-weight-default;
      line-height: 16.94px;
      text-align: left;
      min-width: 40%;
    }

    &-right {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 19.36px;
      text-align: left;
    }
  }

  &__email {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;

    &-left {
      font-family: $lato;
      font-size: $font-size-md;
      font-weight: $font-weight-default;
      line-height: 16.94px;
      text-align: left;
      min-width: 40%;
    }

    &-right {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 19.36px;
      text-align: left;
    }
  }

  &__contact-no {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;

    &-left {
      font-family: $lato;
      font-size: $font-size-md;
      font-weight: $font-weight-default;
      line-height: 16.94px;
      text-align: left;
      min-width: 40%;
    }

    &-right {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 19.36px;
      text-align: left;

      & > img {
        max-width: 25px;
        object-fit: contain;
      }
    }
  }

  &__business {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;

    &-left {
      font-family: $lato;
      font-size: $font-size-md;
      font-weight: $font-weight-default;
      line-height: 16.94px;
      text-align: left;
      min-width: 40%;
    }

    &-right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      padding: 8px 12px;
      border-radius: $radius-home-cards;
      border: 1px solid $border-blue-light;
      background-color: $light-blue;

      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-default;
      line-height: 19.36px;
      text-align: left;

      & > img {
        max-width: 16px;
        object-fit: contain;
      }
    }
  }

  &__action {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  &__cross {
    position: absolute;
    top: 15px;
    right: 15px;
    max-width: 10px;
    object-fit: contain;
    cursor: pointer;
  }
}
