.post-recon {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 10px;

  &__timeline {
    width: 100%;
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: $radius-home-cards;
    padding: 10px 20px;

    &:hover {
      box-shadow: 1px 2px 2px $border-light-gray;
    }
  }

  &__query {
    padding: 10px 20px;
    border-top: 1px solid #000000;
    margin-top: 10px;
  }

  &__summary {
    width: 100%;
    background-color: $white;
    display: flex;
    justify-content: center;
    border-radius: $radius-home-cards;
    padding: 10px 20px;

    &:hover {
      box-shadow: 1px 2px 2px $border-light-gray;
    }

    &--modal {
      width: 1130px;
      height: 95vh;
      padding: 20px;
    }
  }
}

.bank-review {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  // &__heading {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: flex-start;
  //     align-items: flex-start;
  //     gap: 10px;
  // }

  &__message {
    padding-top: 10px;
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-thin;
    line-height: 19px;
    text-align: left;
    margin-top: 10px;
    border-top: 1px solid $summary-border-gray;
  }

  &__content {
    display: flex;
    gap: 10px;
    padding-bottom: 10px;
    border-bottom: 1.5px solid $primary-blue;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__docinfo {
    width: 66.66%;
    padding: 0 0 10px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__card {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  &__help {
    width: 33.33%;
    // min-height: 417px;
    height: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: $blue-bg-light;
    border-radius: $radius-home-cards;

    &-title {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 30px;
      text-align: left;
    }
    &-body {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &-header {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 14px;

      & > img {
        max-width: 22.5px;
        object-fit: contain;
      }
      &-text {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-bolder;
        line-height: 20px;
        text-align: left;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-left: 35px;
    }
    &-item {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 20px;
      text-align: left;
    }

    &-extra-info {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-thin;
      line-height: 20px;
      text-align: left;
    }
  }

  &__submit {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  &__body {
    display: flex;
    gap: 16px;
  }

  &__support {
    width: 33.33%;
    height: 100%;
    background: $wood-grey;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $wood-smoke;
  }
}
