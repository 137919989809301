.g-modal {
  position: absolute;
  z-index: 20;

  &__body {
    background-color: $white;
    border-radius: $radius-home-cards;
  }

  &--standard {
    top: 0;
    left: 0;
    right: 0;
    margin-inline: auto;
    width: fit-content;
    bottom: 0;
    margin-block: auto;
    height: fit-content;
  }

  &--buyer_details {
    top: calc(100% + 30px);
    right: 0;
  }

  &--remind {
    top: -50px;
    right: calc(100% + 50px);
  }
}
