.o-completed {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &--background {
    justify-content: space-between;
    align-items: center;
    background-color: #fafbfb;
    border-radius: 0 10px 10px 0;
    width: 58%;
    padding: 0px 50px !important;
  }

  &__container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    margin: 0px auto;
    max-width: 684px;
    width: 100%;
    padding: 50px 16px 0px 56px;
    // padding-right: 75px;
  }

  &__progress {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    &__text {
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 24px;
    }

    &__bar {
      background-color: $blue-bg-light;
      height: 6px;
      width: 380px;
      &--100 {
        width: 100%;
        height: 6px;
        background-color: #00d28a;
      }
    }
  }
  &__main {
    // width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    &__heading {
      display: flex;
      flex-direction: column;
      font-size: $font-size-lllg;
      font-weight: $font-weight-boldest;
      line-height: 120%;
      text-align: center;
      gap: 12px;

      &--empty {
        max-width: 60%;
      }
    }
    &__body {
      color: $primary-blue;
      font-size: $font-size-lllg;
      font-weight: $font-weight-boldest;
    }
    &__tail {
      white-space: nowrap;
      font-size: $font-size-ml;
      font-weight: $font-weight-default;
      line-height: 120%;
      text-align: center;

      > span {
        color: $primary-blue;
      }
    }
    &__button {
      margin-top: 12px;
      padding: 12px 55px;
    }
  }

  &__list {
    padding: 0px;
    width: 512px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__document {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    padding: 3px 0px;

    &-title {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      font-size: $font-size-ml;
      font-weight: $font-weight-default;
      line-height: 120%;
      height: 27px;
      padding-top: 8px;

      & > span {
        padding-right: 10px;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      gap: 8px;
    }

    &-box {
      min-width: 275px !important;

      .s-upload-list-v2- {
        padding-top: 0px !important;
        background-color: $white;
      }

      .s-uploaded-file-v2__file-name {
        max-width: 100px;
      }
    }

    &-box-v2 {
      min-width: 275px !important;

      .s-upload-list-v2- {
        padding-top: 0px !important;
        background-color: $gray-white;
      }

      .s-uploaded-file-v2__file-name {
        max-width: 100px;
      }
    }
  }

  &__blueicon {
    filter: $filter-primary-blue;
    position: relative;

    &-par {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      border-radius: 50%;
      background-color: $primary-blue;
    }
  }

  &__hyperlink {
    color: $primary-blue;
    height: 20px !important;
    padding: 0px 0px 0px 15px !important;

    > span {
      font-weight: $font-weight-default !important;
    }

    & img {
      filter: $filter-primary-blue;
    }
    // &:hover {
    // }
  }
}
