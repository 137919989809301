.s-actionBar {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 20px;

  &__wrapper {
    width: 100%;
    height: 71px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    background-color: $bg-white-blue;
  }

  &__titletext {
    font-family: $lato;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }

  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
  }

  &__img {
    max-width: 19px;
    object-fit: contain;
    margin-top: -2px;
  }

  &__text {
    font-family: $lato;
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: $primary-blue;

    &--link {
      border-bottom: 1px solid $primary-blue;
      cursor: pointer;
    }
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
  }
}
