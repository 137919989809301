.a-buyers {
  width: 100%;

  &__cards {
    width: 100%;
    display: flex;
    gap: 10px;
  }

  &__card-1 {
    width: 60%;
  }

  &__card-2 {
    width: 40%;
  }

  &__summary-card {
    width: 100%;
  }

  &__single-card {
    width: 100%;
    background-color: $white;
    padding: 20px;
    border-radius: $radius-home-cards;
    box-shadow: $shadow-card;
  }

  &__card {
    width: 100%;
    background: $white;
    padding: 25px;
    border-radius: $radius-home-cards;
    box-shadow: $shadow-card;
    height: 254px;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;

    &-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;

      & > img {
        max-width: 16px;
        object-fit: contain;
        cursor: pointer;
      }
    }

    &-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
    }

    &-heading {
      font-family: $lato;
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
      line-height: 33.89px;
      text-align: left;
    }

    &-tab {
      position: relative;
      width: 145px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 8px 12px;
      border: 1px solid $border-light-gray;
      background-color: $white;
      border-radius: $radius-panel-card;
      cursor: pointer;

      & > img {
        max-width: 16px;
        object-fit: contain;
      }
    }

    &-tabtext {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-bold;
      line-height: 18.15px;
      text-align: left;
    }

    &--show {
      z-index: 20;
      pointer-events: none;
    }
  }
  &__summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &-info {
      position: relative;
      width: 275px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $grey-200;
      background-color: $white;
      border-radius: $radius-modal;
    }

    &-icon {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      max-width: 30px;
      object-fit: contain;
    }
    &-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
    }

    &-title {
      min-width: 50%;
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 24px;
      text-align: center;
      border-bottom: 1px solid $primary-blue;
    }

    &-text {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 7px;
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-default;
      line-height: 24px;
      text-align: center;

      & > p {
        font-family: $lato;
        font-size: $font-size-lg;
        font-weight: $font-weight-bolder;
        line-height: 24px;
        text-align: right;
        margin-bottom: 3px;
      }
    }

    &-currency {
      font-family: $lato;
      font-size: $font-size-xxs;
      font-weight: $font-weight-thin;
      line-height: 24px;
      text-align: right;
    }

    &-sub-text {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 18px;
      text-align: left;
      color: $wood-smoke;
    }

    &-buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      padding: 0px 10px;
    }
  }

  &__circle {
    position: absolute;
    top: 50%;
    left: 86px;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 164px;
    height: 164px;
    border-radius: $radius-circle;
    border: 3px solid $primary-blue;

    &-img {
      height: 61px;
      width: 61px;
    }

    &-line {
      width: 96px;
      height: 1px;
      background: $primary-black;
      border: none;
      outline: none;
    }

    &-text {
      font-size: $font-size-sm2;
      margin: 8px 0px;
      color: $inactive-grey;
    }

    &-total {
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
    }
  }

  &__stats {
    display: flex;
    width: 80%;
    background: $wood-grey;
    padding: 12px 24px 12px 52px;
    border-radius: $radius-home-cards;
    box-shadow: $shadow-card;
  }

  &__single-stat {
    width: 50%;
    position: relative;
    text-align: center;

    &--border {
      border-right: 1px solid $border-black;
    }
  }

  &__abs-text {
    position: absolute;
    top: -28px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    font-size: $font-size-sm2;
    font-weight: $font-weight-bolder;
  }

  &__stat-amt {
    border-bottom: 1px solid $primary-blue;
    font-size: $font-size-sllg;
    font-weight: $font-weight-bolder;
    line-height: 125%;
    padding: 0x 46px 10px 46px;

    & > span {
      font-size: $font-size-xxs;
      font-weight: $font-weight-thin;
      padding: 0px 0px 2px 2px;
    }
  }

  &__stat-text {
    font-size: $font-size-sm2;
    color: $inactive-grey;
    padding-top: 17px;
  }

  &__timeperiod {
    padding-left: 25%;
  }

  &__title {
    font-size: $font-size-ml;
    font-weight: $font-weight-bolder;
    line-height: 24px;
  }

  &__alert {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-items {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
    }

    &-remind {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100%;
      &-coming-soon {
        position: absolute;
        top: 1px;
        right: 1px;
        width: 70px;
        z-index: 2;
      }
    }

    &--show {
      z-index: 20;
      pointer-events: none;
    }
  }

  &__modal-bg {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba($color: $primary-black, $alpha: 0.7);
    z-index: 6;
  }

  &__table-cont {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: $white;
    box-shadow: $shadow-card;
    padding: 0px 20px 10px 20px;
    border-radius: $radius-home-cards;
    margin-top: 10px;
    height: calc(100vh - 315px);
    &--single {
      height: calc(100vh - 290px);
    }
  }

  &__table {
    flex-grow: 1;
    &-top {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0px 0px 10px 0px;
      margin-bottom: 10px;
      border-bottom: 1.5px solid $primary-blue;
    }

    &-action {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
    }

    &-topper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      border-bottom: 1.5px solid $primary-blue;
    }
  }

  &__ebrc-cont {
    background-color: $white;
    box-shadow: $shadow-card;
    padding: 5px 20px 20px 20px;
    border-radius: $radius-home-cards;
    margin-top: 24px;
  }
}
