.o-bankdetails {
    &__main {
        display: flex;
        flex-direction: column;
        gap: 32px;
        width: 380px;

        &__accountnumber {
            display: flex;
            flex-direction: column;
            gap: 20px;
            &__text {
                font-size: $font-size-ml;
                font-weight: $font-weight-bold;

                &--light {
                    font-size: $font-size-ml;
                    font-weight: $font-weight-default;
                }

                &--shiftdown {
                    margin-top: 20px;
                }
            }
        }

        &-inputs {
            position: relative;
            display: flex;
            flex-direction: column;
            max-width: 360px;

            &--grid {
                position: relative;
                display: flex;
                flex-direction: column;
                max-width: 400px;
            }

            &--gap {
                margin-top: -5px;
            }
        }

        &-checkbox {
            display: flex;
            gap: 6px;
            margin-bottom: -14px;

            > p {
                font-size: $font-size-sm2;
            }
        }

        &-branch {
            margin: 0px;
            padding: 5px 0px 0px 0px;
            max-width: 360px;
            font-size: $font-size-xs;
            font-weight: $font-weight-default;
            line-height: 120%;
            color: $wood-smoke;
        }

        &-input {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        &__button {
            margin-top: 12px;
            padding: 12px 55px;
        }
    }
}
