.s-help {
    background-color: $bg-white-blue;
    padding: 36px 28px;
    border-radius: $radius-help;

    &__title {
        font-size: $font-size-sllg;
        font-weight: $font-weight-bolder;
        line-height: 125%;
    }

    &__para {
        font-size: $font-size-ml;
        line-height: 137.5%;
        color: $wood-smoke;
        padding: 20px 0px 56px 0px;
    }

    &__img {
        width: 100%;
    }
}
