.s-irmHeader {
  &__top {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }

  &__texts {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__text1 {
    font-size: $font-size-ml;
    font-weight: $font-weight-default;
    line-height: 150%;
    text-align: left;
    color: $wood-smoke;
  }

  &__text2 {
    position: relative;
    display: flex;
    gap: 6px;

    > span {
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 150%;
      text-align: left;
    }
  }

  &__bottom {
    margin-top: 16px;
  }

  &__text3 {
    font-size: $font-size-sm2;
    line-height: 125%;
    font-weight: $font-weight-bolder;
    padding-left: 40px;
    text-align: left;
  }
}
