.home-help {
  width: 100%;
  height: 100%;
  background-color: $white;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  padding: 10px 24px;
  // overflow-y: auto;

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // gap: 6px;
    padding-bottom: 18px;
    border-bottom: 1px solid $primary-blue;
    // padding: 5px 0;

    &-left {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 4px;
    }

    &-right {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      cursor: pointer;

      &-help {
        padding-top: 5px;
        margin-top: 3px;
        border-top: 1px solid $primary-blue;
      }

      & > p {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-bold;
        line-height: 21px;
        text-align: right;
        color: $primary-blue;

        &:hover {
          text-decoration: 1px solid underline $primary-blue;
          text-underline-offset: 2px;
        }
      }

      & > img {
        max-width: 10px;
        object-fit: contain;
        transform: rotate(180deg);
      }
    }

    &-minimise {
      height: max-content;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background-color: transparent;
      border-radius: $radius-circle;
      padding: 2px;

      &:hover {
        background-color: $border-blue;
      }

      & > img {
        max-width: 22px;
        object-fit: contain;
      }
    }
  }

  &__title {
    font-family: $lato;
    font-size: $font-size-ml;
    font-weight: $font-weight-bolder;
    line-height: 24px;
    text-align: left;
  }

  &__refresh {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }

  &__refresh-date {
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
    line-height: 22px;
    text-align: left;
    opacity: 0.5;
  }

  &__middle {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px 0px 10px 20px;
    overflow-y: auto;
  }

  &__news {
    display: flex;
    gap: 10px;

    &-date {
      height: 100%;
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 24px;
      text-align: left;
      white-space: nowrap;
    }
  }

  &__news-text {
    flex-grow: 1;
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
    line-height: 24px;
    text-align: left;

    & > span {
      font-family: $lato;
      font-size: $font-size-xs;
      font-weight: $font-weight-default;
      line-height: 24px;
      text-align: left;
      color: $primary-blue;
      text-underline-offset: 2px;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        text-decoration: 1px underline solid $primary-blue;
      }
    }
  }

  &__read {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    &-text {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 20px;
      text-align: left;
      color: $primary-blue;
      text-decoration: underline 1px solid $primary-blue;
      text-underline-offset: 5px;
    }

    & > img {
      max-width: 24px;
      object-fit: contain;
    }
  }

  &__bottom {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &-icon {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      & > img {
        max-width: 160px;
        object-fit: contain;
      }
    }
  }

  &--expand {
    overflow-y: auto;
  }

  &--collapse {
    overflow-y: hidden;
  }
}
