.g-error-msg {
  display: flex;
  flex-direction: row;
  padding: 2px 5px 0px 5px;
  gap: 5px;
  width: 100%;
  &--center {
    justify-content: center;
    align-items: center;
  }
  &--icon {
    &--left {
      flex-direction: row-reverse;
    }
  }

  &--error {
    color: $primary-red;
  }

  &--subtext {
    color: $wood-smoke;
  }

  &__icon {
    height: 20px;
    width: 20px;
  }
  &__text {
    font-size: $font-size-xs;
    line-height: 120%;
  }
}
