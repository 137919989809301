.login-device {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 10px 10px;

  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }

  &__icon {
    max-width: 20px;
    object-fit: contain;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
  }
  &__name {
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
    line-height: 16px;
    text-align: left;
  }
  &__date {
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-bold;
    line-height: 16px;
    text-align: left;
    color: $wood-smoke;
  }
  &__address {
    font-family: $lato;
    font-size: $font-size-xxs;
    font-weight: $font-weight-default;
    line-height: 16px;
    text-align: left;
    color: $wood-smoke;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }

  &__report {
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-bold;
    line-height: 16px;
    text-align: center;
    color: $primary-blue;
    text-decoration: underline 1px solid $primary-blue;
    text-underline-offset: 5px;
    cursor: pointer;

    &--disabled {
      color: $wood-smoke;
      text-decoration: none;
      cursor: default;
    }
  }

  &__logout {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  &--current {
    border-bottom: 1px solid $primary-blue;
  }
}
