.reconciliation {
  width: 100%;
  height: 100vh;
  background-color: $white;

  &__container {
    width: 100%;
    display: flex;
    gap: 50px;
  }

  &__header {
    width: 100%;
  }

  &__content {
    padding: 30px 30px 0px 30px;
  }

  &__reasons {
    flex: 0.75;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    // gap: 20px;
  }

  &__assistance {
    flex: 0.25;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding: 30px 28px;
    border-radius: $radius-assistance;
    background-color: #fdf9e9;

    &-header {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 22px;
      text-align: left;
    }

    &-text {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-default;
      line-height: 22px;
      text-align: left;
      color: $wood-smoke;
    }

    & img {
      max-width: 180px;
      object-fit: contain;
    }
  }

  &__dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    border-bottom: 1px solid $border-light-gray;

    &-dropdown {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 20px 0;
    }

    &-header {
      flex: 1;
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-default;
      line-height: 19px;
      text-align: left;
    }
  }

  &__textbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid $border-light-gray;

    &-header {
      flex: 1;
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-default;
      line-height: 19px;
      text-align: left;
    }

    & > input {
      flex: 1;
      box-shadow: none;
      outline: none;
      border: 1px solid $border-light-gray;
      font-size: $font-size-ml;
      font-weight: $font-weight-bold;
      border-radius: $radius-search;
      padding: 14px 16px;
      margin-left: 30px;
    }
  }

  &__checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 10px 0;

    &-text {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-default;
      line-height: 19px;
      text-align: left;
    }
    & input {
      cursor: pointer;
    }
  }

  &__text {
    padding: 20px 0;
    &-text {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-default;
      line-height: 19px;
      text-align: left;
    }
  }

  &__radio {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    // border-bottom: 1px solid $border-light-gray;

    &-header {
      flex: 1;
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-default;
      line-height: 19px;
      text-align: left;
    }

    &-options {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;
    }

    &-option {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }

    & input {
      cursor: pointer;
    }
  }
}
