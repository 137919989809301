.doc-summary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  // gap: 20px;
  // margin-top: 16px;
  padding: 8px;
  // border: 1px solid $border-light-gray;
  border-radius: $radius-home-cards;
  background-color: $white;

  &__form-error {
    padding: 15px 0px 0px 0px;
    &-message {
      font-size: $font-size-md;
      font-weight: $font-weight-default;
      line-height: 150%;
      color: #e01e5a;
    }
  }

  &:hover {
    box-shadow: 1px 2px 2px $border-light-gray;
  }
  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
  }

  &__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    &-title {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bold;
      line-height: 30px;
      text-align: left;
    }

    &-stepdone {
      max-width: 22px;
      object-fit: contain;
    }
    &-edit {
      max-width: 17px;
      object-fit: contain;
      cursor: pointer;
    }
  }

  &__dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;

    &-text {
      font-family: $lato;
      font-size: $font-size-sm;
      font-weight: $font-weight-default;
      line-height: 30px;
      text-align: left;
      color: $primary-blue;

      &:hover {
        text-decoration: 1px solid underline $primary-blue;
        text-underline-offset: 2px;
      }
    }

    &-icon {
      max-width: 10px;
      object-fit: contain;
      transition: all 1 ease;
    }

    &--rotate {
      transform: rotate(180deg);
    }
  }

  &__content {
    width: 100%;
    padding: 0 20px 5px 20px;
    // border: 1px solid $border-light-gray;
    // border-radius: $radius-home-cards;
  }

  &__docinfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // width: max-content;
  }

  &__submit {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__pending {
    width: 100%;
    border-top: 1px solid $summary-border-gray;
    padding-top: 15px;

    &-title {
      padding: 3px;
      display: flex;
      align-items: center;
      gap: 20px;

      &--red {
        background-color: $toast-bg-red;
      }

      & > span {
        font-size: $font-size-ml;
        font-weight: $font-weight-bolder;
        line-height: 150%;
      }

      & > img {
        width: 20px;
        height: 20px;
      }
    }

    &-message {
      font-size: $font-size-md;
      line-height: 150%;
      color: $primary-red;
      border-top: 1px solid $summary-border-gray;
      margin-top: 15px;
      padding-top: 15px;
    }

    &-document {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 48px;
    }

    &-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 25px 0px 15px 0px;
      border-bottom: 1px solid $primary-blue;
    }

    &-content {
      position: relative;
      display: flex;
      width: 605px;
    }

    &-button {
      height: 32px;
      position: absolute;
      left: 380px;
      top: 0px;
    }

    &-text {
      display: flex;
      align-items: center;
      gap: 12px;

      & > span {
        font-size: $font-size-ml;
        line-height: 120%;
      }
    }

    &-box {
      min-width: 380px !important;
      width: 380px;
      max-width: 380px !important;
      background-color: $white !important;
      section {
        background-color: $bg-grey;
      }

      .s-upload-list-v2- {
        background-color: $white;
      }
    }
  }
}
