.s-ipotp {
  // flex-grow: 1;
  // padding: 10px 10px 0px 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  &__btn-cont {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 20px 0px 10px 0px;
  }

  &__btn {
    padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-top: 1px solid $grey-200;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    // gap: 24px;
  }

  &__input {
    display: flex;
    flex-direction: row;
    gap: 10px;
    position: relative;
    align-items: center;

    &-icon {
      width: 20px;
      height: 20px;
    }
  }

  &__forward {
    position: absolute;
    top: 7px;
    right: 10px;
  }

  &__info {
    font-size: $font-size-sm2;
    font-weight: $font-weight-bold;
    margin: 0px 0px 12px 0px;
  }

  &__info-text {
    font-family: $lato;
    font-size: $font-size-xs;
    font-weight: $font-weight-default;
    line-height: 15px;
    text-align: left;
  }

  &__resend {
    color: $primary-blue;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
    line-height: 19.36px;
    text-align: right;
    cursor: pointer;
    width: fit-content;

    &:hover {
      text-decoration: underline;
    }
  }

  &__otp {
    margin-top: 20px;
  }

  &__error {
    color: $primary-red;
    font-size: $font-size-sm2;
    margin-top: 5px;
  }
}
