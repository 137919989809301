.dgft-partner {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__header {
    display: flex;
    flex-direction: column;

    &-title {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 19.36px;
      text-align: left;
    }

    &-text {
      width: 50%;
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 15.73px;
      text-align: left;
    }
  }

  &__iec {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: $radius-home-cards;
    background-color: $white;
    gap: 16px;

    &-title {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 19.36px;
      text-align: left;
      display: flex;
      align-items: center;
      gap: 6px;

      & > p {
        padding: 2px 10px;
        background-color: $golden;
        border-radius: 5px;
        font-size: $font-size-sm2;
      }
    }

    &-inputs {
      display: flex;
      flex-direction: column;
      gap: 20px;

      &-header {
        flex: 1;
      }

      &-radio {
        flex: 1;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  &__guide {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: $radius-home-cards;
    background-color: $white;
    gap: 16px;

    &-title {
      display: flex;
      gap: 16px;
      > img {
        width: 22.5px;
      }
      > p {
        font-family: $lato;
        font-size: $font-size-ml;
        font-weight: $font-weight-bolder;
        line-height: 24px;
        text-align: left;
      }
    }

    &-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: -6px;
      gap: 10px;
    }

    &-text {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 24px;
      text-align: left;
    }

    &-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      & > p {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-default;
        line-height: 15.73px;
        text-align: left;
        padding: 10px;
        background-color: $blue-bg-lighter;

        & > span {
          font-weight: 600;
          padding-right: 4px;
        }
      }

      &--border {
        padding-bottom: 10px;
        border-bottom: 1px solid $wood-smoke;
      }
    }
  }

  &__email {
    display: flex;
    padding: 20px;
    border-radius: $radius-home-cards;
    background-color: $white;
    gap: 16px;

    &-header {
      width: 60%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-title {
      flex: 1;
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 15.73px;
      text-align: left;
    }

    &-email {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      padding-right: 10px;
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 19.36px;
      text-align: left;

      & > span {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 25px;
        min-height: 25px;
      }
    }
  }

  &__status {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: $radius-home-cards;
    background-color: $white;
    gap: 16px;

    &-header {
      width: 60%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-title {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      // line-height: 15.73px;
      text-align: left;
    }

    &-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
    }

    &-text {
      color: $wood-smoke;
      font-size: $font-size-sm;
    }

    &-status {
      font-family: $lato;
      font-size: $font-size-xs;
      font-weight: $font-weight-default;
      line-height: 13.31px;
      text-align: left;
      padding: 6px 12px 7px 12px;
      color: $success;
      border-radius: 8px;
      background-color: $light-green;
      width: max-content;

      &--in_progress {
        color: $primary-yellow;
        background-color: $yellow-alert;
      }

      &--inactive {
        color: $primary-red;
        background-color: $red-alert;
      }
    }
  }

  &__confirmation {
    display: flex;
    padding: 20px;
    border-radius: $radius-home-cards;
    background-color: $white;
    gap: 40px;

    &-content {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &-title {
      font-size: $font-size-sm2;
      font-weight: $font-weight-bold;
    }

    &-inputs {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &-text {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 15.73px;
      text-align: left;
      color: $wood-smoke;
      max-width: 370px;
    }
  }

  &__assistance {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    > p {
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 125%;
      color: $green;
    }

    > img {
      width: 18px;
      height: 18px;
    }
  }
}
