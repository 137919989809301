.s-bluno-icon {
  &__highlight {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $radius-circle;
  }

  &__icon {
    &--primary-blue {
      filter: $filter-primary-blue;
    }
  }
}
