.s-add-shipping-bill {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px 20px 20px;
  border-radius: 15px;
  background-color: $white;

  &__container {
    position: absolute;
    top: 0px;
    right: 0px;
    background: $focus-out;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &-title {
      font-family: $lato;
      font-size: $font-size-lg;
      font-weight: $font-weight-bolder;
      line-height: 24.2px;
      text-align: left;
    }
  }
  &-break {
    height: 2px;
    background-color: $wood-smoke;
    margin-right: 16px;
  }
  &__body {
    display: flex;
    flex-direction: row;
    gap: 27px;
    padding-right: 16px;
    width: 100%;

    &-upload {
      width: 100%;
      padding: 10px 20px;
      box-shadow: 0px 4px 4px 0px #00000040;
      height: max-content;
      border-radius: 10px;
      border: 1px solid $border-modal;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      background-color: $bg-white-blue;

      &:hover {
        border: 1px solid $primary-blue;
      }
    }
    &-help {
      background-color: $bg-white-gray;
      padding: 10px 16px 28.5px 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      border-radius: 10px;
      max-width: 380px;

      &-title {
        font-family: $lato;
        font-size: $font-size-ml;
        font-weight: $font-weight-default;
        line-height: 30px;
        text-align: left;
        color: $natural-black;
      }

      &-section {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &-heading {
          display: flex;
          flex-direction: row;
          gap: 12px;
          align-items: center;
          &-title {
            font-family: $lato;
            font-size: $font-size-sm2;
            font-weight: $font-weight-default;
            line-height: 20px;
            text-align: left;
            color: $natural-black;
          }
          &-img {
            height: 28px;
          }
        }

        &-body {
          list-style-position: outside;
          padding-left: 38px;
          display: flex;
          flex-direction: column;
          &-list {
            padding-top: 10px;
            list-style-position: inside;
          }
          &-text {
            font-family: $lato;
            font-size: $font-size-sm2;
            font-weight: $font-weight-default;
            line-height: 20px;
            text-align: left;
            color: $border-black;
          }
        }
      }

      &-footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-top: 60px;

        & > img {
          width: 220px;
        }
      }
    }
  }
}
