.o-verify-iec {
  height: 100%;
  padding: 0 30px 10px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__flow {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 60px;
    padding: 0 30px 30px 30px;
  }

  &__error-msg {
    font-size: $font-size-xs;
    line-height: 120%;
    color: $primary-red;
    margin: -5px 0px -10px 0px;
    text-align: center;
  }
  &__enter {
    display: flex;
    flex-direction: column;
    gap: 26px;
    justify-content: flex-start;

    &--otp {
      gap: 8px;
    }
  }

  &__title {
    font-family: $lato;
    font-size: $font-size-sllg;
    font-weight: $font-weight-boldest;
    line-height: 33.89px;
    text-align: center;
    padding-bottom: 14px;

    &--underline {
      border-bottom: 1.5px solid $primary-blue;
    }
  }

  &__inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 26px;

    &--grid {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 26px 12px;
    }
  }
  &__failuremessage {
    text-align: center;
    font-size: $font-size-xxs;
    font-weight: $font-weight-default;
    color: $wood-smoke;
  }
  &__input {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-label {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bold;
      line-height: 16.94px;
      text-align: left;
      // color: $wood-smoke;
    }

    &-input {
      padding: 12px 16px !important;
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-default;
      line-height: 19.36px;
      text-align: left;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }

  &__textfield {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-text {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-default;
      line-height: 19px;
      text-align: left;
    }

    & > img {
      max-width: 18px;
      object-fit: contain;
    }
  }

  &__continue {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 16px;
    &-button {
      width: 100%;
    }

    &-previous {
      width: 100%;
      max-width: 142px;
    }
  }

  &__timeline {
    display: flex;
    justify-content: center;
    align-items: center;

    &-content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }

    &-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;

      & > img {
        max-width: 20px;
        object-fit: contain;
      }
    }

    &-text {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 15.73px;
      text-align: left;
    }

    &--completed {
      color: $primary-blue;
    }
    &--current {
      font-weight: $font-weight-bolder;
      color: $primary-blue;
    }
    &--pending {
      color: $wood-smoke;
    }
  }

  &__help {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-bottom: 10px;

    &-content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }

    &-text {
      font-family: $lato;
      font-size: $font-size-md;
      font-weight: $font-weight-default;
      line-height: 16.94px;
      text-align: center;
    }
    &-colored {
      font-weight: $font-weight-bolder;
      color: $primary-blue;
    }
  }

  &__company {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 5px;
    &-label {
      font-size: $font-size-sm2;
      color: $wood-smoke;
    }

    &-value {
      font-size: $font-size-sm2;
    }
  }

  &__msg {
    color: $green;
    font-size: $font-size-md;
    line-height: 150%;
    font-weight: $font-weight-bold;
    max-width: 360px;
  }

  &__complete {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-bottom: 14px;
    width: 360px;

    &-cont {
      width: 100%;
      padding: 0px 16px;
    }

    &-subtext {
      font-size: $font-size-md;
      line-height: 120%;
      color: $border-black;
      margin-bottom: 5px;
    }

    &--underline {
      border-bottom: 1px solid $black;
      padding-bottom: 8px;
    }

    &-bottom {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > p {
        font-size: $font-size-md;
        font-weight: $font-weight-bolder;
        line-height: 120%;
      }

      > img {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__info {
    text-align: center;
    max-width: 300px;
    font-size: $font-size-sm2;
    line-height: 150%;
    color: $border-black;
    margin: 30px auto 0px auto;
  }
}
