.s-input {
  &__input {
    width: 100%;
    margin: 0px;
    padding: 0px;
    border: 1px solid $light-grey;
    border-radius: $radius-alert;
    padding: 0px 16px;
    font-size: $font-size-sm2;
    &::placeholder {
      color: $placeholder-grey;
      font-size: $font-size-sm2;
    }
  }

  &--required {
    &::after {
      content: "*";
      position: relative;
      left: 2px;
      color: $red-alert-main;
    }
  }

  &--date:invalid::-webkit-datetime-edit {
    color: #999;
  }
  &--date {
    font-family: $lato;
    color: $primary-black;
  }
  &--date::-webkit-calendar-picker-indicator {
    margin: 2px 0 0 10px;
    filter: invert(0.5);
    cursor: pointer;
  }

  &--number::-webkit-outer-spin-button,
  &--number::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
