.d-zero {
  width: 100%;
  padding-top: 0px;
  color: $black-shade;
  flex: 1;
  overflow-y: auto;
  &__card {
    width: 100%;
    // max-width: 936px;
    background-color: $white;
    padding: 36px;
    margin: 0px auto;
    border-radius: $radius-home-cards;
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: $font-size-lg;
    font-weight: $font-weight-bolder;
  }

  &__text {
    font-size: $font-size-ml;
    font-weight: $font-weight-bolder;
  }

  &__progress {
    width: 100%;
    height: 5px;
    background-color: $blue-bg-light;
    margin: 16px auto 12px auto;

    &--filled {
      background-color: $sucess-green;
      height: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 40px;
    margin-bottom: -15px;
  }
}

.d0 {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.d0-acc {
  width: 95%;
  // max-width: 812px;
  padding-top: 16px;
  padding-right: 5px;

  &--border {
    border-bottom: 0.5px solid $light-gray;
    padding-bottom: 16px;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  &__left {
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 16px;
    &:hover {
      text-decoration: underline;
      color: $primary-blue;
    }
  }

  &__icon {
    position: absolute;
    left: -48px;
    width: 20px;
    height: 20px;
  }

  &__title {
    font-size: $font-size-ml;
    font-weight: $font-weight-bolder;
  }

  &__arrow {
    width: 10px;
    height: 5px;
    transition: transform 0.3s ease;

    &--rotate {
      transform: rotate(-180deg);
    }
  }
  &__dropdown {
    &-text {
      font-family: $lato;
      font-size: $font-size-md;
      font-weight: $font-weight-bold;
      line-height: 21px;
      text-align: right;
      color: $primary-blue;
    }
  }
}

.d0-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 28px;
  margin: 8px auto;
  padding-right: 234px;

  &__list {
    max-width: 460px;
  }

  &__sample {
    width: 250px;
    max-height: 150px;
    // clip-path: inset(0px 0px 75px 0px);
    margin-top: -30px;
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 24px;
    &__sample {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      color: $primary-blue;
      &:hover {
        text-decoration: underline;
      }

      &-icon {
        width: 16px;
        height: 16px;
        filter: brightness(0) saturate(100%) invert(22%) sepia(97%)
          saturate(2324%) hue-rotate(190deg) brightness(94%) contrast(97%);
      }
      &-text {
        font-family: $lato;
        font-size: $font-size-sm;
        font-weight: $font-weight-bold;
        line-height: 16px;
        text-align: center;
        color: $primary-blue;
      }
    }
  }

  &__description {
    font-family: $lato;
    font-size: $font-size-md;
    font-weight: $font-weight-default;
    line-height: 24px;
    text-align: left;
  }

  &__left {
    max-width: 430px;
  }
}

.d0-irm {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__title {
    font-size: $font-size-md;
    padding-bottom: 12px;
    color: $wood-smoke;
  }

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__col {
    width: 100%;
  }
}

.d0-upload {
  display: flex;
  height: calc(100vh - 100px);

  &__top {
    width: 100%;
    padding-bottom: 32px;
  }

  &__bottom {
    width: 100%;
    padding-top: 32px;
    border-top: 0.5px solid $light-grey;
  }

  &__text {
    font-size: $font-size-ml;
    font-weight: $font-weight-bold;
    line-height: 150%;
    text-align: left;
    padding-bottom: 28px;
  }

  &__left {
    width: 60%;
    max-width: 560px;
    padding: 20px 40px;
  }

  &__right {
    width: 40%;
    max-width: 376px;
    padding: 44px 40px;
    border-left: 0.5px solid $light-grey;
  }
}

.d0-message {
  font-family: $lato;
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  line-height: 30px;
  text-align: left;
  margin-bottom: 12px;

  & > span {
    font-family: $lato;
    font-size: $font-size-lg;
    font-weight: $font-weight-default;
    line-height: 30px;
    text-align: left;
  }
}
