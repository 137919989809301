.s-tooltip {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover &__text {
    visibility: visible;
  }

  &__icon {
    cursor: pointer;
    position: relative;
    z-index: 1;
  }

  &__text {
    visibility: hidden;
    position: absolute;
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: max-content;
    background-color: $focus-out;
    color: $white;
    font-size: $font-size-xs;
    font-weight: $font-weight-default;
    padding: 4px 6px;
    border-radius: 6px;
    text-align: center;
  }
}
