.o-company-data {
    display: flex;
    // padding: 30px 70px 70px 70px;
    padding: 10px;
    height: 645px;

    &__container {
        width: 58%;
        max-width: 700px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: $gray-white;
        border-radius: 0 $radius-home-cards $radius-home-cards 0;
    }

    &__body {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 70px 20px 0px 90px;
    }

    &__top {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 16px 24px 0px 24px;

        &-buttons {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &--single {
            justify-content: flex-end;
        }
    }

    &__back {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__back-text {
        font-size: $font-size-lg;
    }
}
