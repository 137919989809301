// FONT SIZES
$font-size-xxxs: 8px;
$font-size-xxs: 10px;
$font-size-xs: 11px;
$font-size-sm: 12px;
$font-size-sm2: 13px;
$font-size-md: 14px;
$font-size-ml: 16px;
$font-size-mxl: 18px;
$font-size-lg: 20px;
$font-size-sllg: 24px;
$font-size-llg: 26px;
$font-size-lllg: 28px;
$font-size-xlg: 32px;
$font-size-xxlg: 36px;
$font-size-mxxlg: 40px;
$font-size-xxxlg: 45px;
$font-size-5xlg: 96px;

// FONT WEIGHTS
$font-weight-thinner: 200;
$font-weight-thin: 300;
$font-weight-default: 400;
$font-weight-bold: 500;
$font-weight-bolder: 600;
$font-weight-boldest: 700;
$font-weight-thick: 800;

// FONT FAMILIES
$product-sans: "Product Sans";
// $lato: "Inter", sans-serif;
$aller: "Aller";
$lato: "Lato";

// COLORS
$pending-orange: #c8732d;
$green: #00895f;
$light-green: #eafff5;
$white: #ffffff;
$black: #000000;
$black-shade: #1f1f1f;
$wood-grey: #e7e7e7;
$secondary-blue: #7c8db5;
$blue-bg-light: #d6ecff;
$light-grey: #e0e0e0;
$blue-bg-lighter: #ebf4ff;
$inactive-grey: #6d6d6d;
$border-blue-light: #d6ecff;
$light-blue: #edf7ff;
$gray-white: #fafbfb;
$sucess-green: #00d28a;
$summary-border-gray: #d1d1d1;
$golden: #ffc000;
$bg-settings-blue: #eff3f9;

$ribbon-blue: #d6ecff;
$bg-navigation: #224d72;

// border radius
$radius-round: 50px;
$radius-help: 16px;
$radius-modal: 12px;
$radius-sbDetail: 12px;
$radius-home-cards: 10px;
$radius-alert: 8px;
$radius-search: 8px;
$radius-tag: 4px;
$radius-button: 10px;
$radius-circle: 50%;

// colors
$header-blue: #eff3f9;
$white: #ffffff;
$wood-grey: #e7e7e7;
$wood-smoke: #888888;
$light-gray: #f2f2f2;
$grey-200: #d1d1d1;
$dark-blue: #05004e;
$placeholder-blue: #7c8db5;
$placeholder-grey: #b0b0b0;
$green: #00963d;
$light-green-2: #a7dac8;
// $primary-black: #1d1c1d;
$primary-black: #000000;
$primary-blue: #046cb8;
$primary-red: #e01e5a;
$primary-yellow: #ffa100;
$primary-brand-blue: #4aa3f8;
$blue-alert: #e5eeff;
$black-shade: #1f1f1f;
$wood-grey: #e7e7e7;
$subtext-grey: #797979;
$bg-grey: #f8f8f8;
$text-highlight: #354cff;
$success: #00ab72;
$info: #cc9600;
$red-negative: #e01e5a;
$bg-buyer-detail: #f6f6f6;
$natural-black: #161616;
$bg-notif-red: #ff3c3c;
$ribbon-red: rgba(
  $color: $red-negative,
  $alpha: 0.1,
);
$yellow-negative: #cc9600;
$ribbon-yellow: rgba(
  $color: $yellow-negative,
  $alpha: 0.1,
);

$ribbon-black: rgba(
  $color: $primary-black,
  $alpha: 0.1,
);

$summary-amt-info: rgba(
  $color: $primary-black,
  $alpha: 0.6,
);

$sb-heading-color: rgba(
  $color: $primary-black,
  $alpha: 0.5,
);

$warning-yellow: #ffc600;
$success-green: #01ab72;
$alert-red: #ff7373;

// BACKGROUND COLORS
$bg-light-red: #ffd7d7;
$bg-light-blue: #f6f9ff;
$bg-white-blue: #edf7ff;
$bg-neutral: #f6f6f6;
$bg-sidebar: rgba(34, 77, 114, 1);
$bg-white-gray: #efeeee;
$bg-peach: #ffaaaa;
$bg-hover-light: #1976d20a;
$bg-text-grey: rgba(
  $color: #161616,
  $alpha: 0.7,
);
$yellow-bg: #ffebb6;
$green-bg: #cbffe5;

$yellow-alert-main: #ffa100;
$red-alert-main: #e01e5a;
$green-alert-main: #00963d;

$toast-bg-red: #e01e5a1a;
$green-alert: rgba(
  $color: #00963d,
  $alpha: 0.1,
);
$yellow-alert: rgba(
  $color: #ffa100,
  $alpha: 0.1,
);
$red-alert: rgba(
  $color: #e01e5a,
  $alpha: 0.1,
);

$grey-alert: rgba(
  $color: #161616,
  $alpha: 0.5,
);

$focus-out: rgba(
  $color: $primary-black,
  $alpha: 0.75,
);

$toast-alert-success: #d2e7ff;
// $toast-alert-success:  #D5FFE7;
$toast-alert-warning: #fdf9e9;
$toast-alert-error: #fff1f3;

// BORDER COLORS
$border-blue: #d2e7ff;
$dotted-grey: #64748b;
$solid-grey: #e5e5e5;
$blue-bg-light: #d6ecff;
$border-buyer-detail: #e7e7e7;
// $blue-bg-light: rgb(238,242,251);
$light-grey: #e0e0e0;
$border-modal: #d1d1d1;
$border-off-white: rgba(
  $color: $white,
  $alpha: 0.25,
);
$light_black: rgba(0, 0, 0, 0.2);

$border-light-gray: rgba(
  $color: $primary-black,
  $alpha: 0.2,
);
$border-black: #4f4f4f;

// BORDER RADIUS
$radius-panel-card: 8px;
$radius-round: 50px;
$radius-assistance: 16px;
$radius-modal: 12px;
$radius-home-cards: 10px;
$radius-alert: 8px;
$radius-search: 8px;
$radius-tag: 4px;
$radius-task: 6px;
$radius-alert-popup: 15px;

// BOX SHADOWS
$shadow-light-black: 0px 4px 20px 0px
  rgba(
    $color: $primary-black,
    $alpha: 0.1,
  );

$shadow-card: 0px 4px 4px 0px #00000040;
$shadow-panel: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
$green-glow: 0px 0px 10px 7px #26eba4;
$red-glow: 0px 0px 10px 7px #ffb3ca;
//OTHERS

$filter-primary-blue: brightness(0) saturate(100%) invert(23%) sepia(31%)
  saturate(6717%) hue-rotate(190deg) brightness(100%) contrast(97%);
$filter-dark-blue: brightness(0) saturate(100%) invert(18%) sepia(95%)
  saturate(715%) hue-rotate(175deg) brightness(101%) contrast(81%);
$filter-black: brightness(0) saturate(100%) invert(10%) sepia(8%) saturate(550%)
  hue-rotate(350deg) brightness(90%) contrast(100%);
$filter-white: brightness(0) saturate(100%) invert(98%) sepia(5%) saturate(61%)
  hue-rotate(138deg) brightness(116%) contrast(100%);

//MEDIA QUERY
$mobile_s: 320px;
$mobile: 600px;
$mobile-m: 730px;
$tablet-s: 834px;
$tablet: 992px;
$tablet-l: 1024px;
$laptop: 1439px;
$desktop-l: 1600px;
