.s-header {
  height: 100%;
  width: 100%;
  background-color: $white;
  border-bottom: 1px solid #0000001a;
  border-radius: $radius-home-cards;
  box-shadow: $shadow-card;

  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  align-items: center;

  &__group {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  &__left {
    position: relative;
  }

  &__middle {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__banner {
    position: absolute;
    top: 1px;
    left: 62px;
    width: 91px;
    height: calc(100% - 2px);
    display: flex;
    justify-content: center;
    align-items: center;

    &-coming-soon {
      cursor: not-allowed;
      width: 206px;
    }
    &-text {
      font-family: $lato;
      font-size: $font-size-xs;
      font-weight: $font-weight-default;
      line-height: 13.31px;
      text-align: left;
      color: $wood-smoke;
    }
  }
  &__search {
    cursor: not-allowed;
  }

  input.search {
    // width: 360px;
    width: 730px;
    height: 32px;
    border: 1px solid $light-grey;
    border-radius: 8px;
    display: block;
    padding: 8px 4px 8px 40px;
    background: transparent url("../../../public/search.png") no-repeat;
    background-size: 20px;
    background-position-x: 6px;
    background-position-y: 6px;
    cursor: not-allowed;
  }

  input {
    outline: none;
    ::placeholder {
      color: $placeholder-blue;
    }
  }

  &__items {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    &-help {
      font-size: $font-size-sm2;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      gap: 8px;
      color: $primary-blue;
      padding: 4px 6px;
      border: 1px solid $primary-blue;
      border-radius: $radius-task;

      &-icon {
        width: 16px;
        height: 16px;
      }
    }
  }
}
