.s-reject {
  padding: 10px 15px 20px 15px;
  border: 1px solid $grey-200;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: $white;
  margin-top: 20px;

  &__title {
    font-family: $lato;
    font-size: $font-size-ml;
    font-weight: $font-weight-bolder;
    line-height: 24px;
    text-align: left;
    & > span {
      color: $primary-red;
    }
  }

  &__break {
    height: 1.5px;
    width: 100%;
    background-color: $summary-border-gray;
  }
  &__body {
    display: grid;
    row-gap: 20px;
    padding: 0px 120px;
    grid-template-columns: repeat(2, max-content) 1fr repeat(2, max-content);
  }

  &__item {
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-bolder;
    line-height: 15.73px;
    text-align: left;
    color: $natural-black;
    &--label {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 15.73px;
      text-align: left;
      color: $border-black;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }
  }
}
