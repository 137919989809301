.b-ebrc {
    &__table {
        height: calc(100vh - 320px);

        &--short{
            height: 293px;
        }
    }

    &__declaration {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 20px;
        border-top: 1px solid $primary-blue;

        & > p {
            font-size: $font-size-sm2;

            & > label {
                cursor: pointer;
            }

            & > a {
                text-decoration: underline;
                color: $primary-blue;
                font-weight: $font-weight-bold;
            }
        }
    }

    &__buttons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
    }
}
