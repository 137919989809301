.demo-form-frame {
  height: 100vh;
  top: 0px;
  left: 0px;
  width: 100vw;
  position: fixed;
  z-index: 101;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(31, 31, 31, 0.75);
}
