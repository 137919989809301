@font-face {
  font-family: "Product Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"),
    url(https://fonts.gstatic.com/s/productsans/v5/HYvgU2fE2nRJvZ5JFAumwegdm0LZdjqr5-oayXSOefg.woff2)
      format("woff2");
}

@font-face {
  font-family: "Aller";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Aller/Aller_Rg.ttf") format("truetype"),
    url("./fonts/Aller/Aller_Bd.ttf") format("truetype"),
    url("./fonts/Aller/Aller_BdIt.ttf") format("truetype"),
    url("./fonts/Aller/Aller_It.ttf") format("truetype"),
    url("./fonts/Aller/Aller_Lt.ttf") format("truetype"),
    url("./fonts/Aller/Aller_LtIt.ttf") format("truetype"),
    url("./fonts/Aller/AllerDisplay.ttf") format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Namdhinggo:wght@400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
