.post-recon-completed-docs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: $radius-button;
  background: $white;
  padding: 20px 28.5px;
  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 13px;

    &-icon {
      width: 16px;
      cursor: pointer;
      margin-top: 4px;
      &:hover {
        filter: $filter-primary-blue;
      }
    }
    &-text {
      font-family: $lato;
      font-size: $font-size-lg;
      font-weight: $font-weight-bolder;
      line-height: 30px;
      color: $natural-black;
    }
  }
  &-partition {
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: $grey-200;
  }
  &-body {
    font-family: $lato;
    font-size: $font-size-ml;
    font-weight: $font-weight-default;
    line-height: 24px;
  }
  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    padding-left: 65.5px;
    &-icon {
      width: 24px;
      height: 24px;
    }
    &-text {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 22px;
      color: $natural-black;
    }
  }
  &-footer {
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
    line-height: 19px;
    color: $natural-black;
  }
  &__buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-self: center;
  }
}

.post-recon-pending-docs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: $radius-button;
  background: $white;
  padding: 20px 28.5px;

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 13px;
    &-icon {
      width: 16px;
      cursor: pointer;
      &:hover {
        filter: $filter-primary-blue;
      }
    }
    &-text {
      font-family: $lato;
      font-size: $font-size-lg;
      font-weight: $font-weight-bolder;
      line-height: 30px;
      color: $natural-black;
    }
  }
  &-partition {
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: $grey-200;
  }

  &__transport {
    &-mode {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-default;
      line-height: 19px;
      text-align: left;
      color: $wood-smoke;

      & > span {
        color: $primary-black;
      }
    }
  }
}
