.bills {
  width: 100%;
  height: 100%;
  background-color: $white;
  padding: 20px 21.5px;
  border-radius: $radius-home-cards;
  // box-shadow: $shadow-card;

  &__categories {
    display: flex;
    align-items: center;
    gap: 20px;
    gap: 20px;
    margin: 24px 0px;
  }

  &__type {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 35px;
    align-items: center;
    gap: 40px;
    margin: 10px 0px;

    &-type {
      display: flex;
      align-items: center;
      gap: 40px;
    }
  }

  &__type-add {
    display: flex;
    gap: 24px;
    position: relative;
    &-text {
      width: max-content;
      position: absolute;
      font-size: 11px;
      right: 0px;
      bottom: -20px;
      color: $wood-smoke;
    }
  }

  &__single {
    text-decoration: none;
    color: $primary-black;
    padding-bottom: 8px;
    cursor: pointer;

    &:hover {
      color: $primary-blue;
      font-weight: $font-weight-bolder;
    }

    &-container {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 5px;
      border-bottom: 2px solid transparent;

      &:hover .bills__typetag-alert {
        background: $blue-bg-light;
      }

      &--selected {
        border-bottom: 2px solid $primary-blue;
      }
    }
  }

  &__typetag {
    font-size: $font-size-ml;
    line-height: 150%;
    position: absolute;

    &-total {
      font-size: $font-size-xxs;
    }
    &-alert {
      color: $black;
      background: $bg-white-gray;
      border-radius: $radius-circle;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      padding: 4px 4px;
      margin-bottom: 6px;
      min-width: 25px;
      min-height: 25px;
      text-align: center;
      &--selected {
        background: $blue-bg-light;
      }
    }
    &--bolder {
      font-size: $font-size-ml;
      line-height: 150%;
      font-weight: $font-weight-bolder;
      visibility: hidden;
    }
  }

  &__focused {
    font-weight: $font-weight-bolder;
    color: $primary-blue;
  }

  &__table {
    width: 100%;
    display: flex;
    flex-direction: column;
    &--border {
      border-bottom: 1px solid $primary-blue;
    }
  }

  &__pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
  }
  &__request {
    &-select {
      width: calc(100% + 40px);
      padding: 10px 10px 12px 32px;
      height: 50px;
      background: $bg-buyer-detail;
      display: flex;
      align-items: center;
      gap: 20px;
      border-radius: 10px 10px 0 0;
      border-bottom: 1px solid #046cb8;
      margin-left: -20px;

      &__option {
        display: flex;
        position: relative;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        cursor: pointer;
        padding: 6px 10px;
        border-radius: $radius-button;

        &:hover:not(.bills__request-select__option--select) {
          .bills__request-select__option-text {
            text-decoration: underline;
          }
          background: $blue-bg-lighter;
        }

        &-img {
          width: 16px;
          height: 16px;
          filter: $filter-primary-blue;

          &--clear {
            margin-top: 4px;
            width: 18px;
            height: 18px;
            object-fit: contain;

            &:hover {
              filter: $filter-primary-blue;
            }
          }
        }

        &-text {
          font-family: $lato;
          font-size: $font-size-sm2;
          font-weight: $font-weight-default;
          line-height: 19px;
          text-align: left;
          color: $primary-blue;
          &--blue {
            color: $primary-blue;
            font-weight: $font-weight-bolder;
          }
        }

        &--select {
          gap: 10px;
          border-right: 1px solid $primary-black;
          margin-right: -10px;
          border-radius: 10px 0px 0px 10px;
        }
      }

      &__reject-modal {
        position: absolute;
        z-index: 12;
        top: 20px;
        left: 0px;
        border: 1px solid $black;
        border-radius: $radius-home-cards;
      }
    }
  }
  &__options {
    &__reject {
      padding: 16px 20px 16px 20px;
      box-shadow: 0px 4px 4px 0px #00000040;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      background-color: $white;
      border-radius: $radius-home-cards;
      &-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;
      }
    }
    &-modal {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 10px;
      box-shadow: 0px 4px 4px 0px #00000040;
      padding: 10px;
      border: 1px solid #d1d1d1;
      background-color: $white;
      &-icon {
        width: 16px;
        height: 16px;
      }
      &-text {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-default;
        &-container {
          display: flex;
          flex-direction: column;
        }
      }
      &-subText {
        font-family: $lato;
        font-size: $font-size-xs;
        font-weight: $font-weight-default;
        line-height: 16px;
        text-align: left;
        color: $wood-smoke;
      }
      &-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        padding: 15.5px 20px 15.5px;
        border-radius: 10px;
        width: 100%;
        cursor: pointer;
        &:hover {
          background-color: $blue-bg-light;
          color: $primary-blue;
          & > .bills__options-modal-icon {
            filter: brightness(0) saturate(100%) invert(29%) sepia(52%)
              saturate(2166%) hue-rotate(183deg) brightness(96%) contrast(97%);
          }
        }
      }
    }
  }
}
