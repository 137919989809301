.bulk-actions {
  width: 100%;
  padding: 10px 35px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  background-color: $white;
  border-radius: $radius-home-cards;
  min-height: 120px;

  &__left {
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      max-width: 178px;
      object-fit: contain;
    }
  }

  &__right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
  }

  &__content {
    width: 230px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__title {
    font-family: $lato;
    font-size: $font-size-lg;
    font-weight: $font-weight-default;
    line-height: 20px;
    text-align: left;
  }

  &__text {
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
    line-height: 20px;
    text-align: left;
    color: $wood-smoke;
  }

  &__buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
  }
}
