.df-group {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 48px;
  justify-content: flex-start;
  padding: 13px 0px;
  border-bottom: 0.5px solid $light-grey;

  & > p {
    width: 40%;
    font-size: $font-size-sm2;
  }
  &__radio {
    display: flex;
    align-items: center;
    gap: 24px;

    & input,
    & label {
      cursor: pointer;
    }
  }

  &__input {
    width: 380px;
    cursor: pointer;
  }

  &__label {
    padding-left: 8px;
    font-size: $font-size-sm2;
    line-height: 150%;

    & > a {
      color: $primary-blue;
      font-weight: $font-weight-bold;
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;

    &-div {
      padding: 8px;
    }

    & input {
      cursor: pointer;
    }
  }
}
