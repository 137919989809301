.s-button {
  &--primary {
    font-family: $lato;
    border: 1.5px solid $primary-blue;
    color: $white;
    background-color: $primary-blue;
    outline: none;
    &:not(.s-button--disabled):hover {
      box-shadow: 0px 4px 4px 0px #00000040;
    }
  }

  &--secondary {
    font-family: $lato;
    color: $primary-black;
    background-color: $white;
    border: 1px solid $primary-black;

    .s-button__icon {
      filter: $filter-black;
    }

    &:not(.s-button--disabled):hover {
      border: 1px solid $primary-blue;
      color: $primary-blue;

      .s-button__icon {
        filter: $filter-primary-blue;
      }
    }
  }

  &--tertiary {
    font-family: $lato;
    border: none;
    font-weight: $font-weight-bolder;
    color: $primary-blue;
    outline: none;
    margin: 0px !important;
    padding: 0px !important;
    background-color: transparent;

    > span {
      font-weight: $font-weight-bolder;
    }

    &:not(.s-button--disabled):hover {
      text-decoration: underline;
      .s-button__icon {
        filter: $filter-primary-blue;
      }
    }
  }

  //variant
  &--expanding {
    &:hover {
      transform: scale(1.02);
      transform-origin: left;
    }
  }

  //variant
  &--tertiary_black {
    color: $primary-black;

    &:hover {
      color: $primary-blue;
    }
  }

  &--hyperlink {
    font-family: $lato;
    font-weight: $font-weight-bolder;
    border: none;
    color: $primary-black;
    background-color: transparent;
    outline: none;

    > span {
      font-weight: $font-weight-bolder;
    }

    &:not(.s-button--disabled):hover {
      text-decoration: underline;
    }
  }

  &--basic {
    border: 1px solid $border-light-gray;
    border-radius: $radius-alert;
    padding: 8px 20px 8px 20px;
    background-color: $white;
  }

  &--white {
    font-family: $lato;
    color: $primary-black;
    background: $white;
    border: 1px solid $border-light-gray;
  }

  &--tag {
    border: none;
    background-color: $white;
    border: 1px solid $border-light-gray;
    color: rgb(91, 91, 91);
    border-radius: $radius-round;

    &:hover {
      background-color: $bg-light-blue;
    }
  }

  &--alert-success {
    border: 1px solid $border-light-gray;
    background-color: $white;
    padding: 8px 20px;
  }

  &--xs {
    padding: 6px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  &--sm2 {
    // padding: 8px 15px;
    padding: 6px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  &--md {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 8px;
  }
  &--lg {
    padding: 10px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 8px;
  }
  &--long {
    padding: 8px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  &__text--xs {
    font-size: $font-size-xs;
    font-weight: $font-weight-default;
  }

  &__text--sm {
    font-size: $font-size-sm;
    font-weight: $font-weight-default;
  }

  &__text--sm2 {
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
  }

  &__text--md {
    font-size: $font-size-md;
  }

  &__text--lg {
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
  }

  &--disabled {
    opacity: 0.4;
    cursor: auto;
  }

  &--round {
    border-radius: $radius-round;
  }

  &--button {
    // border-radius: $radius-button;
    border-radius: $radius-search;
  }

  &--cube {
    border-radius: $radius-alert;
  }

  &--card {
    border-radius: $radius-home-cards;
  }

  &--task {
    border-radius: $radius-task;
    padding: 12px;
  }

  &__icon--md {
    height: 14px;
  }

  &__icon--sm2 {
    height: 14px;
  }

  &__icon--xs {
    height: 14px;
    object-fit: contain;
  }

  &--green {
    max-width: 112px;
    padding: 6px 12px 7px 12px;
    color: $green;
    background-color: $light-green;
    border: none;
    border-radius: $radius-alert;
    font-family: Inter;

    &:not(.s-button--disabled):hover {
      box-shadow: 0px 4px 4px 0px #00000040;
    }
  }

  &--positive {
    max-width: 112px;
    padding: 6px 12px 7px 12px;
    color: $primary-blue;
    // background-color: $ribbon-blue;
    background-color: transparent;
    border: 1px solid $primary-blue;
    // border: none;
    border-radius: $radius-alert;
    &:not(.s-button--disabled):hover {
      cursor: pointer;
      box-shadow: 0px 4px 4px 0px #00000040;
    }
  }

  &--negative {
    max-width: 112px;
    padding: 6px 12px 7px 12px;
    color: $red-negative;
    background-color: $ribbon-red;
    border: none;
    border-radius: $radius-alert;
  }

  &--negative:hover {
    cursor: pointer;
    box-shadow: 0px 4px 4px 0px #00000040;
  }

  &--neutral {
    max-width: 112px;
    padding: 6px 12px 7px 12px;
    color: $primary-black;
    background-color: $ribbon-black;
    // background-color: transparent;
    // border: 1px solid $ribbon-black;
    border: none;
    border-radius: $radius-alert;
  }

  &--download {
    max-width: 112px;
    border: none;
    outline: none;
    background-color: transparent;
    color: $primary-black;
  }

  &--neutral:hover {
    cursor: pointer;
    box-shadow: 0px 4px 4px 0px #00000040;
  }

  &--info {
    max-width: 112px;
    padding: 6px 12px 7px 12px;
    color: $primary-yellow;
    background-color: $ribbon-yellow;
    border: none;
    border-radius: $radius-alert;
  }

  &--info:hover {
    cursor: pointer;
  }

  &--icon-left {
    flex-direction: row-reverse;
  }

  &--gray-white {
    background-color: $gray-white;
    color: $primary-blue;
    border: 0.5px solid $summary-border-gray;

    &.s-button--disabled {
      opacity: 1;
      color: $wood-smoke;
    }
  }

  &__error {
    display: contents;
    &--top {
      flex-direction: column;
      gap: 5px;
    }
    &--bottom {
      flex-direction: column-reverse;
      gap: 5px;
    }
    &--left {
      flex-direction: row;
      gap: 5px;
    }
    &--right {
      flex-direction: row-reverse;
      gap: 5px;
    }
  }
}
