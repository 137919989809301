.s-breadcrums {
  width: 100%;
  padding: 5px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;

  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 14px;

    & > img {
      max-width: 7px;
      object-fit: contain;
    }
  }

  &__content {
    font-family: $lato;
    font-size: $font-size-md;
    font-weight: $font-weight-default;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    border-bottom: 1px solid transparent;

    cursor: pointer;

    &:hover {
      color: $primary-blue;
      // border-bottom: 1px solid $primary-blue;
    }
  }
}
