.s-select-filter {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  border-radius: $radius-button;
  background-color: $bg-white-gray;
  width: 100%;
  padding: 4px 20px;
  margin-bottom: 15px;
  margin-top: 10px;
  &__selected {
    &-text {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 19px;
      padding: 4.5px 10px;
      color: $natural-black;

      & > span {
        font-size: $font-size-xs;
      }
    }
  }
  &__partition {
    width: 1px;
    height: 100%;
    border-radius: $radius-circle;
    background: $wood-smoke;
    margin: 0px 10px;
  }
  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 6px 10px;
    border-radius: $radius-button;
    &:hover {
      background: $blue-bg-lighter;
      .s-select-filter__item-text {
        text-decoration: underline;
      }
    }
    &-icon {
      height: 16px;
      filter: $filter-primary-blue;
    }
    &-text {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 19px;
      color: $primary-blue;
    }
  }
}
