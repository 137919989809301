.s-img-uploader {
  width: 237px;
  border-radius: $radius-alert-popup;
  padding: 24px;

  &__avatar {
    width: 100%;
    height: 100%;
    border-radius: $radius-circle;
    object-fit: cover;
    object-position: center;
    border: 1px solid $wood-smoke;
    &-cont {
      position: relative;
      width: 180px;
      height: 180px;
      border-radius: $radius-circle;
    }
  }

  &__upload {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 24px;
    cursor: pointer;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $primary-black;
      opacity: 0.5;
      border-radius: $radius-circle;
    }
  }

  &__input {
    display: none;
  }

  &__action {
    font-size: $font-size-xs;
    font-weight: $font-weight-default;
    line-height: 150%;
    text-align: center;
    color: $grey-200;
    position: relative;
    z-index: 2;
    opacity: 1;

    &--hover {
      &:hover {
        text-decoration: underline;
        color: $white;
      }
    }
  }

  &__size {
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    line-height: 150%;
    text-align: center;
    color: $white;
    position: relative;
    z-index: 2;
    opacity: 1;
  }

  &__text {
    font-size: $font-size-sm2;
    line-height: 150%;
    text-align: center;
    margin: 15px auto;
    &-error {
      font-size: $font-size-xs;
      font-weight: $font-weight-default;
      color: $primary-red;
      white-space: nowrap;
      margin: 15px auto;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 16px;
  }

  &__rotate {
    width: max-content;
    margin: 24px auto;
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    font-weight: $font-weight-bold;

    > img {
      width: 16px;
      height: 16px;
    }

    > span {
      font-size: $font-size-sm;
      text-decoration: underline;
    }
  }

  &__close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
