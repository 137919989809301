.s-accordian {
    width: 100%;
    background-color: $white;
    padding:20px 0px;

    &__head {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        cursor: pointer;
    }

    &__left {
        display: flex;
        align-items: center;
        gap:8px;
    }

    &__icon {
        width:20px;
        height:20px;
    }

    &__title {
        margin: 0px;
    }

    &__status {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size:$font-size-md;
        font-weight: $font-weight-bold;

        &-img {
            width: 12.5px;
            height: 12.5px;
        }

        &--uploaded {
            color: $success;
        }

        &--pending {
            color: $info;
        }

        &--not_uploaded {
            color: $black-shade;
        }

    }

    &__child {
        margin-top: 20px;
    }
    
}