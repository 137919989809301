.root {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 20px;

    &__left {
        width: 238px;
        height: 100%;
    }

    &__right {
        width: calc(100% - 238px);
        height: 100%;
    }

    &__header {
        height: 56px;
        width: 100%;
        margin-bottom: 10px;
    }

    &__body {
        height: calc(100% - 72px);
        width: 100%;
    }
}
