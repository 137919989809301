.chart-donut__cont {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.recharts-layer {
    outline: none;
}

.recharts-wrapper {
    & > svg {
        overflow: visible;
    }
}
