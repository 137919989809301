.m-bulk-action {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: $radius-help;
  background-color: $white;
  padding: 20px;

  &__header {
    position: relative;
    padding: 10px;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: $radius-search $radius-search 0 0;
    border-bottom: 0.5px solid $grey-200;
    background-color: $bg-settings-blue;

    & > img {
      position: absolute;
      top: 3px;
      right: 3px;
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }

  &__title {
    font-family: $lato;
    font-size: $font-size-ml;
    font-weight: $font-weight-bolder;
    line-height: 16px;
    text-align: left;
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid $summary-border-gray;
    border-radius: $radius-home-cards;
    gap: 5px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-top: 1px solid $border-modal;

    &-head {
      padding: 10px 2px 10px 36px;
      border: none;
    }

    &:hover:not(.m-bulk-action__item-head) {
      border-radius: $radius-alert;
      background-color: $blue-bg-light;
      border-top: 1px solid transparent;
      cursor: pointer;

      & + .m-bulk-action__item {
        border-top: 1px solid transparent;
      }

      .m-bulk-action__item-count {
        background-color: $white;
      }

      .m-bulk-action__item-hover-icon {
        visibility: visible;
      }
    }

    &-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      & > img {
        max-width: 16px;
        object-fit: contain;
      }
    }

    &-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
    }

    &-header {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 15.6px;
      text-align: left;

      > span {
        color: $wood-smoke;
        font-weight: $font-weight-default;
        line-height: 125%;
      }
    }

    &-bank {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 15.6px;
      text-align: left;
    }

    &-count {
      padding: 2px 8px;
      font-family: $lato;
      font-size: $font-size-md;
      font-weight: $font-weight-default;
      line-height: 19px;
      text-align: center;
      border-radius: $radius-tag;
      background-color: $bg-white-gray;
    }

    &-hover-text {
      font-family: $lato;
      font-size: $font-size-xs;
      font-weight: $font-weight-default;
      line-height: 13.2px;
      text-align: left;
      color: $wood-smoke;
    }

    &-hover-icon {
      max-width: 7px;
      object-fit: contain;
      visibility: hidden;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  &__default-view {
    position: relative;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    &-logo {
      max-width: 58px;
      object-fit: contain;
    }

    &-title {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 19px;
      text-align: center;
    }

    &-text {
      width: 240px;
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 20px;
      text-align: center;
    }

    &-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    &-close {
      position: absolute;
      top: 0px;
      right: 0px;
      max-width: 24px;
      object-fit: contain;
      cursor: pointer;
    }
  }
}

.m-bulk-table-view {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 10px 10px 20px 10px;
    border-bottom: 1px solid $border-modal;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;

    &-label {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 19px;
      text-align: left;
    }

    &-value {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 19px;
      text-align: left;
    }

    &-subtext {
      font-family: $lato;
      font-size: $font-size-xs;
      font-weight: $font-weight-default;
      line-height: 19px;
      text-align: left;
      color: $wood-smoke;
    }
  }

  &__reason {
    &-label {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 19px;
      text-align: left;
    }

    &-value {
      overflow: hidden;
      word-wrap: break-word;
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 15.6px;
      text-align: left;
    }

    &-buttons {
      display: inline-block;
    }
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  &__declaration {
    &-label {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 19px;
      text-align: left;
      padding-left: 10px;

      > a {
        color: $primary-blue;
      }
    }
  }
}

.m-bulk-bank-action {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__selected {
    display: flex;
    align-items: center;
    padding: 6px 20px 16px 20px;
    gap: 10px;
    border-bottom: 1px solid $summary-border-gray;

    &-label {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 15.6px;
      text-align: left;
    }

    &-value {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;

      & > img {
        max-width: 16px;
        object-fit: contain;
      }
    }

    &-count {
      display: block;
      padding: 2px 8px;
      font-family: $lato;
      font-size: $font-size-md;
      font-weight: $font-weight-default;
      line-height: 19px;
      text-align: left;
      background-color: $bg-white-gray;
      border-radius: $radius-tag;
    }

    &-name {
      display: block;
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 15.6px;
      text-align: left;
    }
  }

  &__change {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding-left: 4px;
    &--default {
      > span {
        font-weight: $font-weight-default !important;
      }
    }
  }

  &__list {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: center;
  }

  &__item {
    width: 200px;
    height: 275px;
    padding: 20px;
    border-radius: $radius-alert;
    border: 1px solid $border-modal;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      border-bottom: 1px solid $border-modal;
      height: 50px;
      padding-bottom: 16px;
    }

    &-title {
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 125%;
      text-align: right;
    }

    &-count {
      padding: 2px 12px;
      font-family: $lato;
      font-size: $font-size-md;
      font-weight: $font-weight-default;
      line-height: 19px;
      text-align: left;
      background-color: $bg-white-gray;
      border-radius: $radius-tag;
    }

    &-icon {
      width: 80px;
      height: 80px;
      margin: 0px auto;
    }

    &-text {
      font-size: $font-size-xs;
      font-weight: $font-weight-default;
      line-height: 16px;
      text-align: center;
      color: $wood-smoke;
      padding-bottom: 16px;
      border-bottom: 1px solid $border-modal;
      height: 80px;
    }

    &-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
    }
  }

  &__total-summary {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 6px 20px;

    > span {
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 125%;
    }
  }
}

.m-bulk-success {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  &__close {
    position: absolute;
    top: 0px;
    right: 0px;
    max-width: 24px;
    object-fit: contain;
    cursor: pointer;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
  }

  &__text {
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
    line-height: 20px;
    text-align: center;
    & > span {
      font-weight: $font-weight-bolder;
    }
  }

  &__circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 2px solid $sucess-green;
  }

  &__logo {
    max-width: 28px;
    object-fit: contain;
  }

  &__summary {
    display: flex;
    flex-direction: column;
    border: 1px solid $summary-border-gray;
    border-radius: $radius-alert;
    overflow: hidden;

    &-item {
      display: flex;
      &:first-child {
        border-bottom: 1px solid $summary-border-gray;
      }
    }

    &-label {
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $lato;
      font-size: $font-size-xs;
      font-weight: $font-weight-default;
      line-height: 19px;
      text-align: right;
      padding: 10px 20px;
      border-right: 1px solid $summary-border-gray;
    }

    &-value {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 19px;
      text-align: center;
      padding: 10px 20px;
    }
  }

  &__bulkid {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    &-label {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 19px;
      text-align: center;
    }

    &-value {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      font-family: $lato;
      font-size: $font-size-md;
      font-weight: $font-weight-bolder;
      line-height: 19px;
      text-align: center;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

.m-bulk-remind {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
  }

  &__text {
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
    line-height: 19px;
    text-align: left;

    & > span {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 19px;
      text-align: left;
    }
  }

  &__table {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    &-cell {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 5px;

      &-text {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-default;
        line-height: 15.6px;
        text-align: center;
      }

      &-subtext {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-default;
        line-height: 15.6px;
        text-align: center;
        color: $wood-smoke;
      }

      &--alignment-left {
        align-items: flex-start;
      }

      &--alignment-center {
        align-items: center;
      }

      &--alignment-right {
        align-items: flex-end;
      }
    }

    &-default-view {
      width: 100%;
      padding: 0 0 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      border-bottom: 1px solid $summary-border-gray;

      & > img {
        max-width: 120px;
        object-fit: contain;
      }
    }
  }
}

.bulk-option-popup {
  width: 360px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  padding: 16px 20px;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: $white;
  border-radius: $radius-home-cards;

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__text {
    width: 100%;
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
    line-height: 20px;
    text-align: center;
    white-space: normal;

    & > span {
      font-weight: $font-weight-bolder;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}
