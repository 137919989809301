.support-modal {
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: $radius-assistance;
  gap: 16px;
  padding: 10px 10px 16px 10px;
  z-index: 100;
  border: 1px solid $bg-white-gray;

  &__heading {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: $bg-white-gray;
    padding: 6px;
    border-radius: $radius-alert;

    &-logo {
      width: 102.63px;
      height: 84.02px;
    }

    &-top {
      display: flex;
      flex-direction: row;
      gap: 40px;
      align-items: center;

      &-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding-right: 85px;

        &-title {
          font-family: $lato;
          font-size: $font-size-lg;
          font-weight: $font-weight-default;
          line-height: 16px;
          white-space: nowrap;
        }

        &-subtitle {
          font-family: $lato;
          font-size: $font-size-sm2;
          font-weight: $font-weight-default;
          line-height: 15.6px;
          white-space: nowrap;
        }
      }
    }
    &-contact {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      padding: 8px 20px;
      // cursor: pointer;

      &:hover {
        // color: $primary-blue;
        // text-decoration: underline;
        // .support-modal__heading-contact-icon {
        //   filter: $filter-primary-blue;
        // }
      }

      &-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
      }

      &-icon {
        width: 16px;
        height: 16px;
        filter: $filter-black;
      }
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0px 45px;
    &-text {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 15.6px;
    }
    &-buttons {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
  }
  &-partition {
    height: 1px;
    background: $grey-200;
    border-radius: 2px;
    margin: 0px 15px;
  }
  &__footer {
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
    line-height: 15.6px;
    text-align: center;
    width: 100%;
    color: $wood-smoke;
  }
  &__close {
    position: absolute;
    top: 18px;
    right: 18px;
    height: 18px;
    cursor: pointer;
  }
}
