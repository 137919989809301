.document-management {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  &__heading {
    font-family: $lato;
    font-size: $font-size-ml;
    font-weight: $font-weight-default;
    line-height: 19px;
    padding: 10px 0px;
    & > span {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 19px;
      color: $primary-blue;
    }
  }
  &__card {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 13px 18px 25.5px;
    gap: 14.5px;
    border-radius: $radius-modal;
    background-color: $white;
    width: 200px;
    cursor: pointer;

    &-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
    }

    &--active {
      border: 2px solid $primary-blue;
    }

    &-title {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 19.2px;
      color: $natural-black;
    }

    &-icon {
      height: 32px;
    }

    &-text {
      font-family: $lato;
      font-size: $font-size-sllg;
      font-weight: $font-weight-bolder;
      line-height: 24px;
      color: $black-shade;
      padding-left: 18px;

      &-container {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 100%;
      }
    }

    &-partition {
      width: 100%;
      height: 2px;
      border-radius: 2px;
      background: $primary-blue;
    }

    &-subText {
      font-family: $lato;
      font-size: $font-size-xs;
      font-weight: $font-weight-default;
      line-height: 19px;
      color: $black-shade;
    }
  }

  &__data {
    display: flex;
    flex-direction: column;
    border-radius: $radius-button;
    background: $white;
    padding: 10px 20px;
    height: 100%;
    // box-shadow: $shadow-card;
  }

  &__table {
    height: calc(-425px + 100vh);
    border-bottom: 1px solid $primary-blue;
  }
  &__column {
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: center;
    align-items: center;
    &-icon {
      width: 32px;
      height: 32px;
      padding: 8px;
      overflow: visible;
      border-radius: $radius-circle;
      margin-right: -8px;
      cursor: pointer;
      &:hover {
        background: $border-blue;
      }
      &--disabled {
        cursor: not-allowed;
        opacity: 0.5;
        &:hover {
          background: unset;
        }
      }
    }
    &--buttons {
      gap: 21px;
      justify-content: flex-end;
      width: 228px;
    }
  }

  &__more {
    display: flex;
    flex-direction: column;
    background: $white;
    border-radius: $radius-button;
    padding: 6px;
    align-items: flex-start;
    border: 1px solid #d1d1d1;
    box-shadow: 0px 4px 4px 0px #00000040;
    width: 260px;

    &-item {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      padding: 6px 16px;
      gap: 8px;
      border-radius: $radius-task;
      cursor: pointer;

      &:hover {
        background: $blue-bg-lighter;
        .document-management__more-item-icon {
          filter: $filter-primary-blue;
        }
        .document-management__more-item-text {
          color: $primary-blue;
        }
      }

      &-icon {
        width: 16px;
        height: 16px;
      }

      &-text {
        font-family: $lato;
        font-size: $font-size-xs;
        font-weight: $font-weight-default;
        line-height: 19px;
        color: $natural-black;
      }
    }
  }
  &__details-popup {
    &-container {
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0px;
      left: 0px;
      background-color: rgba($color: $primary-black, $alpha: 0.7);
      z-index: 20;
    }
  }
}
