.bluno-banner {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 30px;
  border-radius: $radius-home-cards;
  background-color: $white;

  &__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }

  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & > img {
      max-width: 185px;
      object-fit: contain;
    }
  }

  &__right {
    flex-grow: 1;
    // width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 50%;
  }

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;

    &-buttons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }
  }

  &__title {
    font-family: $lato;
    font-size: $font-size-lg;
    font-weight: $font-weight-bolder;
    line-height: 24px;
    text-align: left;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__text {
    font-family: $lato;
    font-size: $font-size-ml;
    font-weight: $font-weight-default;
    line-height: 24px;
    text-align: left;
    color: $wood-smoke;
  }

  &__list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 38px;

    &-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      & > img {
        max-width: 16px;
      }

      & > p {
        font-family: $lato;
        font-size: $font-size-ml;
        font-weight: $font-weight-default;
        line-height: 24px;
        text-align: left;
      }
    }
  }

  &__footer {
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    &-icon {
      padding: 4px 3px;
      margin-top: 2px;
    }
  }
}

.kyc-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  gap: 20px;
  border-radius: $radius-home-cards;
  background-color: $white;

  &__left {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 45px;
  }

  &__card {
    width: 71px;
  }

  &__content {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__balloon {
    width: 176px;
  }

  &__title {
    font-size: $font-size-sllg;
    font-weight: $font-weight-bolder;
    line-height: 100%;

    > span {
      color: $primary-blue;
    }
  }

  &__text {
    font-size: $font-size-ml;
    line-height: 150%;
    color: $wood-smoke;
  }
}

.doc-banner {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px 30px;
  border-radius: $radius-home-cards;
  background-color: $white;

  &__left {
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      max-width: 71px;
      object-fit: contain;
    }
  }

  &__middle {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-left: 100px;
  }

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;

    &-buttons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }
  }

  &__title {
    font-family: $lato;
    font-size: $font-size-lg;
    font-weight: $font-weight-bolder;
    line-height: 24px;
    text-align: left;
  }

  &__message {
    font-family: $lato;
    font-size: $font-size-ml;
    font-weight: $font-weight-default;
    line-height: 24px;
    text-align: left;
    color: $wood-smoke;
    width: max-content;
    position: relative;
  }

  &__card-icon {
    position: absolute;
    width: 71px;
    left: -100px;
    top: -8px;
  }

  &__text {
    font-family: $lato;
    font-size: $font-size-ml;
    font-weight: $font-weight-default;
    line-height: 24px;
    text-align: left;
  }

  &__docs {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  &__footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 50px;
  }

  &__right {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 30px;

    & > img {
      max-width: 176px;
      object-fit: contain;
    }
  }
}
