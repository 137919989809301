.s-notifications {
  &__notification-icon {
    height: 18px;
    cursor: pointer;

    &:hover {
      filter: $filter-primary-blue;
    }

    &-available {
      width: 13px;
      height: 13px;
      background-color: $bg-notif-red;
      border-radius: 50%;
      position: absolute;
      top: 0px;
      right: 0px;
      display: flex;
      justify-content: center;
      align-items: center;

      font-family: $lato;
      font-size: $font-size-xxs;
      font-weight: $font-weight-default;
      line-height: 12px;
      text-align: center;
      color: $white;

      &--selected {
        border: 1px solid $primary-black;
        background-color: $white;
        color: $primary-black;
      }
    }

    &--selected:hover {
      filter: none;
    }
  }

  &__notification {
    border-radius: $radius-home-cards;

    &--new {
      background-color: $light-blue;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 8px;
    overflow-y: auto;
  }

  &__popup {
    padding: 10px 20px;
    background-color: $white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    max-height: 340px;
    width: 330px;
    // overflow: auto;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 101;
    box-shadow: 0px 4px 4px 0px #00000040;
    border: 1px solid #efeeee;
    // overflow-x: hidden;

    &-heading {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
    &-title {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 24px;
      text-align: left;
      color: $natural-black;
    }
    &-clear {
      font-family: $lato;
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;
      line-height: 16px;
      text-align: center;
      color: $primary-blue;
      text-decoration: underline;
      cursor: pointer;
    }

    &-card {
      padding: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      border: 1px solid #efeeee;
      border-radius: 10px;
      gap: 10px;
      &--link {
        cursor: pointer;
      }
      &--new {
        background-color: $light-blue;
      }
      &-icon {
        height: 26px;
        width: 26px;
      }
      &-body {
        display: flex;
        flex-direction: column;
        gap: 4px;

        &-name {
          font-family: $lato;
          font-size: $font-size-xs;
          line-height: 18px;
          text-align: left;
          color: $wood-smoke;
        }
        &-description {
          font-family: $lato;
          font-size: $font-size-sm2;
          line-height: 18px;
          text-align: left;
          color: $natural-black;
        }
      }
    }
    &-loader {
      width: 75px;
    }
  }
}

.s-notification-banner {
  width: max-content;
  &__basic {
    position: fixed;
    top: 0;
    background-color: $bg-light-red;
    z-index: 6;
    box-shadow: $shadow-card;
    padding: 12px 20px;
    border-radius: $radius-home-cards;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1172px;
    height: 56px;

    &--animate {
      animation: slideDown 0.7s ease-in-out 10ms forwards;
    }

    @keyframes slideDown {
      0% {
        top: 0;
      }
      100% {
        top: 80px;
      }
    }

    &--title {
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 150%;
    }

    &--buttons {
      display: flex;
      gap: 16px;
    }

    &--transparent {
      background-color: $white;
      width: 144px;
    }
  }
}
