.test-cc-avenue {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &__body {
        padding: 50px;
        background-color: $white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-radius: $radius-home-cards;
        gap: 20px;
    }
}
