.s-docinfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 10px 0;

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }

  &__title {
    font-family: $lato;
    font-size: $font-size-ml;
    font-weight: $font-weight-default;
    line-height: 19px;
    text-align: left;
  }

  &__subtext {
    width: 20px;
    height: 20px;
    background-color: $wood-grey;
    border-radius: $radius-circle;
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
    line-height: 19px;
    text-align: center;
  }

  &__message {
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-thin;
    line-height: 19px;
    text-align: left;
    margin-top: -10px;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &--partition {
    border-top: 1px solid $border-light-gray;
  }
}
