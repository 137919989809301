.r-panel {
  &__global {
    position: absolute;
    top: 0px;
    right: 0px;
    background: $focus-out;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    z-index: 10;
  }

  &__cont {
    width: 936px;
    background: $white;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 40px 26px 40px;
    border-bottom: 0.5px solid $light-grey;
  }

  &__title {
    font-size: $font-size-sllg;
    font-weight: $font-weight-bolder;
  }

  &__icon {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  &__body {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
  }
}
