.o-static {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-right: 1px solid $primary-black;
  padding: 67px 40px 58px 40px;
  gap: 35px;
  width: 42%;
  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 35px;
  }

  &-logo {
    max-width: 215px;
    object-fit: contain;
  }

  &__security {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: -20px;

    &-ilu {
      max-width: 240px;
      object-fit: contain;
    }

    &-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-top: 20px;
      gap: 10px;

      & > img {
        max-width: 19px;
        object-fit: contain;
      }
    }

    &-text {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 150%;
      text-align: center;
    }
  }

  &__powered {
    width: 100%;
    display: flex;
    flex-direction: column;

    gap: 14px;
    align-items: center;
    opacity: 0.6;

    &-title {
      font-family: $lato;
      font-size: $font-size-sm;
      font-weight: $font-weight-bolder;
      line-height: 14.52px;
      text-align: center;
    }

    &-content {
      display: flex;
      gap: 18px;
    }

    &-icon {
      max-height: 28px;
      object-fit: contain;
    }
  }
}

.o-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  &__child {
    width: 100px;
    height: 7px;
    background-color: $bg-white-gray;
    border-radius: 2px;

    &--completed {
      background-color: $success;
      border: none;
    }
  }
}

.o-gethelp {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  max-width: 340px;
  &-title {
    width: 100%;
    font-size: $font-size-lg;
    font-weight: $font-weight-bolder;
    text-align: center;
    line-height: 34px;
    padding-top: 15px;
  }
  &-close {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    :hover {
      background-color: rgb(120, 32, 32);
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 26px;
    padding: 0px 30px 26px 30px;
    width: 100%;
  }

  &__input {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-label {
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
    }
    &-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &-text {
      font-size: $font-size-ml;
      font-weight: $font-weight-default;
    }

    &-phone {
      padding: 12px 16px 12px 45px !important;
    }
  }

  &-phone {
    position: absolute;
    z-index: 2;
    padding-left: 10px;
  }

  &-submitted {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    width: 350px;
    &-title {
      font-size: $font-size-lllg;
      font-weight: $font-weight-boldest;
    }

    &__logo {
      width: 44px;
      height: 44px;
    }

    &-text {
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      text-align: center;
      line-height: 24px;

      &--bold {
        font-weight: $font-weight-bolder;
      }

      > span {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.o-expired-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 50px;
  max-width: 412px;
  gap: 16px;
  z-index: 6;
  position: relative;
  &-title {
    font-family: $lato;
    font-size: $font-size-lg;
    font-weight: $font-weight-bolder;
    line-height: 24.2px;
    text-align: center;
  }
  &-image {
    width: 152px;
    height: 110.68px;
  }
  &-body {
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
    line-height: 15.73px;
    text-align: center;
  }
}

.o-pending {
  width: 572px;
  height: 388x;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__list {
    margin: 20px auto 28px auto;
    border-top: 1px solid $primary-blue !important;
    padding-top: 0px !important;
  }

  &__title {
    font-size: $font-size-lg;
    font-weight: $font-weight-bolder;
    line-height: 120%;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
  }
}
