.s-toast {
  width: 300px;
  max-height: 90px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 12px 10px;
  border-radius: $radius-alert;
  white-space: nowrap;
  backdrop-filter: blur(60px);

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
  }

  &__message {
    font-family: $lato;
    font-size: $font-size-sm;
    font-weight: $font-weight-default;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
  }

  &__title {
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-bold;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
  }

  & img {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }

  &--success {
    background-color: $green-alert;
    border-left: 2px solid $green-alert-main;
  }

  &--warning {
    background-color: $yellow-alert;
    border-left: 2px solid $yellow-alert-main;
  }

  &--error {
    background-color: $toast-bg-red;
    border-left: 2px solid $red-alert-main;
  }
}
