html,
body,
#root {
  width: 100% !important;
  height: 100% !important;
  margin: 0;
  background: $wood-grey;
  overflow-y: hidden;
  overflow-x: hidden;
  font-family: $lato;
}

// #root{
//     max-width: 1440px;
//     margin: 0px auto;
// }

.root {
  max-width: 1440px;
  margin: 0px auto;
}

div {
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

// scrollbar design

// Scrollbar styling for WebKit browsers (Chrome, Edge, Safari)
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: transparent;
}

*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: $wood-smoke;
  border-radius: 6px;
}

*::-webkit-scrollbar-button {
  display: none;
}

// // For Firefox
// * {
//     scrollbar-width: thin;
//     scrollbar-color: $wood-smoke transparent;
// }
