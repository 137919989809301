.post-bulk {
  width: 496px;
  height: 215px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  padding: 20px;

  &__info {
    display: flex;
    flex-direction: column;
    // gap: 10px;
    padding: 20px 20px 0 20px;
    background-color: $bg-neutral;
    border-radius: $radius-home-cards;
  }

  &__ebrc {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-family: $lato;
    font-size: $font-size-lg;
    font-weight: $font-weight-default;
    line-height: 34px;
    text-align: left;
    padding: 20px 0;
    border-bottom: 1.5px solid $primary-blue;

    & > span {
      font-family: $lato;
      font-size: $font-size-mxxlg;
      font-weight: $font-weight-bold;
      line-height: 34px;
      text-align: left;
      margin-top: -10px;
    }
  }
  &__bulkid {
    width: 120px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: $lato;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    line-height: 34px;
    text-align: center;

    & > span {
      color: $primary-blue;
    }

    &-par {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  &__action {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}
