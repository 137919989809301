.s-filter {
  // width: calc(100% + 40px);
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  // background: $bg-neutral;
  padding: 10px;
  padding-bottom: 12px;
  border-radius: $radius-home-cards $radius-home-cards 0 0;
  // border-bottom: 1px solid $primary-blue;
  // margin-left: -10px;

  &__selected {
    border-radius: 8px;
    // background-color: $bg-white-gray;
    padding: 7px 0px 7px 0px;
    &-text {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 16px;
      text-align: left;
      & > span {
        font-family: $lato;
        font-size: $font-size-xs;
        line-height: 16px;
        text-align: left;
        font-weight: $font-weight-default;
      }
    }
  }

  &__component {
    min-height: 40px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  &__filters {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    overflow-x: visible;
    scroll-behavior: smooth;
    padding-bottom: 500px;
    margin-bottom: -500px;

    @media screen and (max-width: 1440px) {
      gap: 10px;
    }
  }

  &__action {
    display: flex;
    justify-content: flex-end;
  }

  &__action {
    display: flex;
    justify-content: flex-end;

    &-btn {
      width: max-content;
    }
  }

  &__title {
    font-size: $font-size-sm2;
    font-weight: 400;
    line-height: 125%;
    width: max-content;
  }

  &__scroll-icon {
    max-width: 16px;
    object-fit: contain;
    margin-top: 5px;
    cursor: pointer;
  }

  &__first {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }

  &__clear {
    cursor: pointer;
    margin-right: 2px;

    &-container {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }

    &-icon {
      max-width: 23px;
      object-fit: contain;
      &--blue {
        filter: $filter-primary-blue;
      }
    }
    &-text {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 19px;
      text-align: left;
      text-decoration-line: underline;
      color: $primary-blue;
    }

    &--disabled {
      opacity: 0.5;
      cursor: auto;
      margin-right: 8px;
      margin-top: 4px;
    }
  }

  &__partition {
    height: 30px;
    width: 2px;
    background-color: $wood-smoke;
  }

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  &__more-button {
    &--active {
      color: $primary-blue;
      // filter: $filter-primary-blue;
      border-color: $primary-blue;
      & > .s-button__icon {
        filter: unset;
      }
    }
  }
}
