.s-demo {
  background: #ffffff;
  border-radius: $radius-modal;
  display: flex;
  font-family: $lato;
  color: $primary-black;

  &__button {
    font-size: $font-size-sm2 !important;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  &__left {
    height: 100%;
    padding: 40px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 78px;
    &-top {
      display: flex;
      gap: 6px;
      // padding-bottom: 42px;
      align-items: center;
      & > img {
        height: 30px;
      }

      & > p {
        font-size: $font-size-ml;
        font-weight: $font-weight-bolder;
      }
    }

    &-bottom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 14px;
      opacity: 0.5;

      & > p {
        font-size: $font-size-sm;
        font-weight: $font-weight-bolder;
        color: $bg-text-grey;
      }
    }
  }

  &__img-list {
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      height: 28px;
    }
  }

  &__banner {
    width: 180px;
    max-width: 440px;
  }

  &__right {
    position: relative;
    background: $blue-bg-lighter;
    padding: 23px 43px 23px 50px;
    border-radius: 0px $radius-modal $radius-modal 0px;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    background: $bg-buyer-detail;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $radius-circle;
    cursor: pointer;

    & > img {
      width: 11px;
      height: 11px;
    }
  }

  &__title {
    font-size: $font-size-lg;
    font-weight: $font-weight-boldest;
    text-align: center;
    line-height: 24px;
    & > span {
      color: $primary_blue;
    }
  }

  &__form-cont {
    max-width: 284px;
  }

  &__form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 16px;
    padding-top: 16px;
  }

  &__group {
    &-text {
      align-items: center;
      justify-content: center;
      white-space: nowrap;
    }
    &-checkbox {
      display: grid;
      grid-template-columns: subgrid;
    }
    &-text-input {
      padding-bottom: 5px;
    }
  }

  &__code {
    position: absolute;
    bottom: 12px;
    left: 16px;
  }

  &__input {
    height: 43px;
    width: 100%;
    border: 1px solid $light_black;
    border-radius: $radius-alert;
    padding: 12px 16px;
    font-size: $font-size-ml;
    margin-top: 12px;
    outline: none;

    &:focus {
      border-color: $primary_blue;
    }
  }

  &__label {
    font-size: $font-size-md;
    grid-column: 1/-1;
    & span {
      font-style: italic;
      color: $wood-smoke;
    }
  }

  &__radio-list {
    display: grid;
    align-items: center;
    gap: 12px;
    margin-top: 12px;
    grid-column: 1/-1;
    grid-template-columns: subgrid;
    padding-bottom: 16px;
    border-bottom: 1px solid black;
  }

  &__radio {
    flex: 1 1;
    padding: 12px 14px;
    border-radius: $radius-alert;
    border: 1px solid $light_black;
    background: #ffffff;
    display: flex;
    gap: 8px;

    & > label {
      font-size: $font-size-sm2;
      white-space: nowrap;
      width: auto;
    }
  }

  &__dropdown {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    gap: 12px;
    margin-top: 12px;
    margin-bottom: 20px;
    height: 44px;

    &-title {
      margin-top: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
    }

    &-cont {
      width: 75px;
      height: 31px;
    }

    &-grow {
      flex-grow: 2;
    }
  }

  &__slider {
    border-bottom: 1px solid $primary_black;
    padding: 0px 0px 20px 7px;
  }
  &__error {
    color: $primary-red;
    margin-top: 4px;
    font-size: 12px;

    &-dropdown {
      margin-top: -10px;
      font-size: 12px;
      color: $primary-red;
    }
  }

  &__complete {
    position: relative;
    background: $white;
    width: 350px;
    border-radius: $radius-modal;
    padding: 15px;
    font-family: $lato;
    color: $primary-black;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;

    &-close > img {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 24px;
      cursor: pointer;
    }
    &-logo {
      max-width: 62px;
      border-radius: 35px;
      overflow: visible;
      border: 2px solid #00d28a;
      padding: 17px 17px 13px 13px;
    }

    &-title {
      font-family: $lato;
      font-size: $font-size-lg;
      font-weight: $font-weight-bolder;
      line-height: 34px;
      text-align: center;
    }

    &-text {
      font-size: $font-size-md;
      line-height: 150%;
      text-align: center;
    }
  }
  &__agreement {
    font-family: $lato;
    font-size: $font-size-xxs;
    font-weight: $font-weight-default;
    line-height: 12.1px;
    text-align: left;
  }
}

@media screen and (max-width: $mobile-m) {
  .s-modal {
    padding: 0px 16px;
  }
  .s-demo {
    &__button {
      & > .s-button {
        width: 320px;
      }
    }
    &__form {
      row-gap: 13px;
      &-cont {
        padding-top: 16px;
        border-top: 1px solid $summary-border-gray;
        max-width: 300px;
      }
    }
    &__close {
      position: static;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      background: none;
      border-radius: 0px;
      width: max-content;
      padding: 5px 0px;
      gap: 10px;
      & > img {
        width: 16px;
        height: 16px;
        padding: 4px;
      }
    }
    &__left {
      display: none;
      &-top {
        align-items: center;
        justify-content: center;
        padding-top: 16px;
      }
    }
    &__right {
      background: $white;
      border-radius: 10px;
      padding: 10px 16px 20px 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    &__radio {
      & > label {
        font-size: $font-size-xs;
      }
    }
    &__slider {
      margin-top: 10px;
    }
    &__dropdown {
      &-title {
        margin: 0px;
        & > .s-demo__label {
          font-size: $font-size-xs;
        }
      }
    }
    &__group {
      &-text {
        margin-top: 7px;
        margin-bottom: 7px;
      }
      &-dropdown {
        margin-top: 6px;
      }
      &-text-input {
        padding: 0px 10px;
        margin-bottom: 13px;
      }
      &-checkbox {
        margin-top: 0px;
      }
    }
    &__agreement {
      white-space: wrap;
      text-align: center;
    }
  }
}
