.s-alert {
  width: 100%;
  max-height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 12px;
  // border-radius: $radius-alert;
  // white-space: nowrap;
  // border: 1px solid $wood-grey;
  cursor: pointer;

  &:hover {
    // border: 1px solid $primary-blue;
    background-color: $ribbon-blue;
  }

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    & > img {
      width: 20px;
      height: 20px;
    }
  }

  &__text {
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
  }

  & > img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
