.s-upload-card {
  width: 100%;
  background-color: $white;

  &__input {
    width: 100%;
    border: none;
    border-radius: 8px;
    background-color: $light-blue;
    overflow: hidden;
    transition: max-height 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 8px 15px 8px 15px;
    // height: 105px;
    max-height: 105px;
    border: 1px dashed $dotted-grey;
    cursor: not-allowed;

    &--drag {
      border: 1px solid $text-highlight;
    }

    &--hover {
      cursor: pointer;
      &:hover {
        border: 2px dashed $primary-blue;
        padding: 7px 14px 7px 14px;
      }
    }
  }

  &__error {
    padding-top: 10px;
    color: $primary-red;
    font-size: $font-size-sm2;
  }

  &__upload {
    display: none;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    cursor: pointer;

    &-img {
      height: 20px;
      object-fit: contain;
    }

    &-text {
      font-size: $font-size-sm2;
      color: $text-highlight;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__secondary-text {
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
    line-height: 19px;
    text-align: left;
    color: $inactive-grey;
  }
  &__text {
    text-align: center;
    font-size: $font-size-sm2;
    display: flex;
    gap: 4px;
    &-cont {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  &__list {
    width: 100%;
  }

  &__file {
    &-content {
      width: 350px;
      display: flex;
      align-items: center;
      border: 1px dashed $dotted-grey;
      border-radius: 8px;
      background-color: $bg-grey;
      padding: 8px 20px 8px 10px;
    }
    &-card {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 10px 0px;

      &--border {
        border-bottom: 1px solid $solid-grey;
      }
    }
    &-actions {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;

      // & img {
      //   max-width: 24px;
      //   object-fit: contain;
      //   cursor: pointer;
      // }

      :hover {
        color: $primary-yellow;
      }
    }

    &-icon {
      height: 20px;
      margin-right: 10px;
    }

    &-cont {
      width: 100%;
    }

    &-info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
    }

    &-span {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &-text {
      font-size: $font-size-sm2;
      margin: 0px;
      max-width: 130px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-hr {
      width: 1px;
      height: 14px;
      background-color: $subtext-grey;
      border: none;
    }

    &-remove {
      max-width: 16px;
      cursor: pointer;
    }

    &-download {
      max-width: 28px;
      object-fit: contain;
      cursor: pointer;
    }

    &-detach {
      width: 16px;
      cursor: pointer;
      margin-left: 28px;
    }
  }

  &__bar {
    width: 100%;
    height: 6px;
    background-color: $solid-grey;
    border-radius: 12px;
    margin-top: 8px;
  }

  &__progress {
    height: 100%;
    transition: width 0.3s ease-in-out;
    background-color: $success;
    border-radius: 12px;
  }

  &--disabled {
    opacity: 0.5;
    &:hover {
      text-decoration: none;
    }
  }
}
