.s-progressBar {
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  background-color: $white;
  box-shadow: $shadow-light-black;

  &__state {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }

  &__img {
    max-width: 24px;
    object-fit: contain;
  }

  &__content {
    font-family: $lato;
    font-size: $font-size-lg;
    font-weight: $font-weight-default;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  &--in_progress {
    color: $primary-blue;
  }

  &--completed {
    color: $primary-black;
  }

  &--pending {
    color: $wood-smoke;
  }
}
