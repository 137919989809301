.rdrInputRanges {
  display: none;
}

.rdrDayNumber {
  & span {
    color: $primary-black !important;

    &::after {
      background: $primary-black !important;
    }
  }
}

.rdrDayToday {
  text-decoration: none;
}

.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  background-color: $blue-bg-light;
}

.rdrDateDisplayWrapper {
  background-color: transparent;
  border-bottom: 1px solid $primary-black;
}

.rdrDateInput {
  box-shadow: none;
  border: 1px solid $wood-smoke;
}

.rdrStaticRanges {
  padding-top: 6px;
}

.rdrStaticRange {
  display: flex;
  justify-content: center;
  border: none;
}

.rdrStaticRangeLabel {
  width: 95%;
  border-radius: $radius-home-cards;
  color: $wood-smoke;
  font-size: $font-size-sm;
}

.rdrStaticRangeSelected {
  & span {
    background-color: $wood-grey;
    color: $primary-black;
    font-weight: $font-weight-bold;
  }
}

//react-day-picker styles

.b-calender {
  position: relative;

  &-container {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__placeholder {
    font-size: $font-size-sm;
    z-index: 2;
    transition: 0.2s ease all;
    pointer-events: none;

    &--active {
      bottom: 21px;
      color: $primary-blue;
    }
  }

  &__fieldset {
    border-radius: $radius-alert;
    border: 1px solid $wood-grey;
    padding: 0px 8px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;

    &:hover {
      border: 1px solid $wood-smoke;
    }

    &:focus-within {
      border: 1px solid $primary-blue;
    }
  }
  &__input-cont {
    position: relative;
  }

  &__icon {
    position: absolute;
    width: 16px;
    top: 20%;
    right: 0px;
    transform: translateX(-50%);
    cursor: pointer;
    &--blue {
      filter: $filter-primary-blue;
    }
  }

  &__input {
    border-radius: $radius-alert;
    width: 100%;
    height: 100%;
    padding: 8px 32px 8px 12px;
    border: 0px;
    outline: none;
    cursor: pointer;

    &--error {
      border-color: $primary-red;
    }

    &--highlighted {
      border: 1px solid $primary-blue;
      color: $primary-blue;
    }
  }

  &__modal {
    position: absolute;
    top: 40px;
    left: 60%;
    transform: translateX(-50%);
    background-color: $white;
    border: 1px solid $border-modal;
    box-shadow: $shadow-card;
    border-radius: $radius-home-cards;
    display: flex;
    z-index: 10;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 175px;
    padding: 0px 10px;
    gap: 10px;
    border-right: 1px solid $border-modal;
  }

  &__button {
    width: 100%;
    border: none;
    background: transparent;
    font-size: $font-size-sm2;
    color: $wood-smoke;
    line-height: 120%;
    text-align: left;
    padding-left: 24px;
    height: 32px;
    cursor: pointer;
    font-family: $lato;

    &--active {
      background-color: $wood-grey;
      font-weight: $font-weight-bolder;
      color: $primary-black;
      border-radius: $radius-home-cards;
      height: 40px;
    }
  }

  &__right {
    padding: 16px 0px;
  }

  &__dates {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid $border-modal;
  }

  &__date {
    width: 200px;
    height: 35px;
    padding: 8px 16px;
    border: 1px solid $border-modal;
    color: $wood-smoke;
    font-size: $font-size-md;
    border-radius: $radius-alert;
  }

  &__actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    padding: 0px 20px;
  }

  &__start-of-row.rdp-day_selected {
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
  }

  &__end-of-row.rdp-day_selected {
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
  }

  .rdp {
    --rdp-caption-font-family: "Inter", sans-serif;
    --rdp-caption-font-size: 14px;
    --rdp-accent-color: none;
    --rdp-background-color: none;
    --rdp-accent-color-dark: none;
    --rdp-background-color-dark: none;
    --rdp-outline: none;
    --rdp-outline-selected: none;
    --rdp-cell-size: 34px;
  }
  .rdp-row {
    border: 2.5px solid $white;
    border-radius: 100%;
  }
  .rdp-cell .rdp-button_reset {
    &:hover {
      background-color: $blue-bg-light;
    }
  }

  .rdp-head_cell {
    font-size: $font-size-sm;
  }

  .rdp-button {
    font-family: $lato;
    color: $primary-black;
    font-size: $font-size-sm;

    &:hover {
      background-color: $blue-bg-light;
    }
  }

  .rdp-day {
    &:hover {
      background-color: $blue-bg-light;
      color: $natural-black;
    }
  }

  .rdp-day_selected {
    background-color: $blue-bg-light;
  }

  .rdp-day_today {
    border: 1px solid $natural-black;
    border-radius: 100%;
    font-weight: $font-weight-default;
    // color: $primary-blue;
  }

  .rdp-caption {
    padding: 4px 0px 12px 0px;
  }

  .rdp-multiple_months {
    margin: 16px 16px 8px 16px;
  }

  .rdp-nav_icon {
    height: 12px;
    width: 12px;
    margin-bottom: 8px;
  }
  .rdp-cell:has(
      .rdp-day_range_start:not(.rdp-day_range_end, .b-calender__end-of-row)
    ) {
    background: linear-gradient(270deg, $blue-bg-light, 50%, $white);
  }
  .rdp-cell:has(
      .rdp-day_range_end:not(.rdp-day_range_start, .b-calender__start-of-row)
    ) {
    background: linear-gradient(90deg, $blue-bg-light, 50%, $white);
  }
  .rdp-day_range_start {
    background-color: $primary-blue;
    color: $white;
    border-top-right-radius: 100% !important;
    border-bottom-right-radius: 100% !important;
  }
  .rdp-day_range_end {
    background-color: $primary-blue;
    color: $white;
    border-top-left-radius: 100% !important;
    border-bottom-left-radius: 100% !important;
  }
}

.b-single-calender {
  position: relative;

  &__modal {
    position: absolute;
    background-color: $white;
    border: 1px solid $border-modal;
    box-shadow: $shadow-card;
    border-radius: $radius-home-cards;
    display: flex;
    z-index: 2;
  }

  &__icon {
    position: absolute;
    width: 16px;
    top: 20%;
    right: 12px;
    transform: translateX(-50%);
    cursor: pointer;
  }

  &__input {
    border-radius: $radius-alert;
    width: 100%;
    height: 100%;
    padding: 8px 32px 8px 12px;
    border: 0px;
    outline: none;
    cursor: pointer;
    // border: 1px solid $border-buyer-detail;

    &--error {
      border-color: $primary-red;
    }

    &--highlighted {
      border: 1px solid $primary-blue;
      color: $primary-blue;
    }

    &-cont {
      position: relative;
      border-radius: 8px;
      border: 1px solid $border-buyer-detail;
      &:hover {
        border: 1px solid $primary-blue;
      }
    }
  }

  .rdp {
    --rdp-caption-font-family: "Inter", sans-serif;
    --rdp-caption-font-size: 14px;
    --rdp-accent-color: none;
    --rdp-background-color: none;
    --rdp-accent-color-dark: none;
    --rdp-background-color-dark: none;
    --rdp-outline: none;
    --rdp-outline-selected: none;
    --rdp-cell-size: 34px;
  }

  .rdp-cell .rdp-button_reset {
    &:hover {
      background-color: $blue-bg-light;
    }
  }

  .rdp-head_cell {
    font-size: $font-size-sm;
  }

  .rdp-button {
    font-family: $lato;
    color: $primary-black;
    font-size: $font-size-sm;

    &:hover {
      background-color: $blue-bg-light;
    }
  }

  .rdp-day {
    &:hover {
      background-color: $blue-bg-light;
    }
  }

  .rdp-day_selected {
    background-color: $blue-bg-light;
  }

  .rdp-day_today {
    color: $primary-blue;
  }

  .rdp-caption {
    padding: 4px 0px 12px 0px;
  }

  .rdp-multiple_months {
    margin: 16px 16px 8px 16px;
  }

  .rdp-nav_icon {
    height: 12px;
    width: 12px;
    margin-bottom: 8px;
  }
}
