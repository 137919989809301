.bank-charges {
  width: 640px;
  //   max-height: 640px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: $radius-help;
  background-color: $white;
  padding: 20px;

  &__header {
    position: relative;
    padding: 10px;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: $radius-search $radius-search 0 0;
    border-bottom: 0.5px solid $grey-200;
    background-color: $bg-settings-blue;

    & > img {
      position: absolute;
      top: 3px;
      right: 3px;
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }

  &__title {
    font-family: $lato;
    font-size: $font-size-ml;
    font-weight: $font-weight-bolder;
    line-height: 16px;
    text-align: left;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;

    &-text {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 19px;
      text-align: left;
    }

    & > img {
      max-width: 16px;
      object-fit: contain;
      cursor: pointer;
    }
  }

  &__view {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    border-top: 1px solid $primary-black;
    border-bottom: 1px solid $primary-black;

    &-headers {
      display: flex;
      padding: 10px;
      background-color: $header-blue;
    }

    &-header {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 15.6px;

      &-1 {
        min-width: 30%;
        text-align: left;
      }

      &-2 {
        min-width: 30%;
        text-align: left;
      }

      &-3 {
        flex-grow: 1;
        text-align: left;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &-item {
      display: flex;
      padding: 10px;
    }

    &-label {
      max-width: 30%;
      min-width: 30%;
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 15.6px;
      text-align: left;
    }

    &-charge {
      max-width: 30%;
      min-width: 30%;
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 15.6px;
      text-align: left;

      & > span {
        font-size: $font-size-xs;
      }
    }

    &-reason {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;

      &-text {
        font-family: $lato;
        font-size: $font-size-xs;
        font-weight: $font-weight-default;
        line-height: 20px;
        text-align: left;
      }

      &-doc {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;

        & > img {
          max-width: 16px;
          object-fit: contain;
        }
      }

      &-docname {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-default;
        line-height: 19px;
        text-align: left;
        color: $primary-blue;
        cursor: pointer;
      }
    }
  }

  &__edit {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0;
    border-top: 1px solid $primary-black;
    border-bottom: 1px solid $primary-black;

    &-list {
      max-height: 400px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow-y: auto;
      //   overflow-x: visible;
    }

    &-item {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 16px 10px 10px 10px;
      background-color: $bg-white-gray;
      border-radius: $radius-modal;
    }

    &-name {
      max-width: 24%;
      min-width: 24%;
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 15.6px;
      text-align: left;
      overflow: hidden;
      word-break: break-all;
    }

    &-charge {
      max-width: 22%;
      min-width: 22%;
      gap: 5px;
      position: relative;

      > div {
        display: flex;
        gap: 7px;

        > p {
          font-size: $font-size-xs;
          font-weight: 400;
          line-height: 120%;
          padding-top: 8px;
        }
      }

      & > span {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-default;
        line-height: 15.6px;
        text-align: left;
        font-size: $font-size-xs;
      }
    }

    &-input {
      display: flex;
      align-items: center;
      gap: 5px;
      &-attach {
        position: absolute;
        right: 22px;
        bottom: 6px;
        max-width: 12px;
        object-fit: contain;
        cursor: pointer;
        transform: rotate(20deg);
      }

      &-area {
        position: relative;
      }

      &-delete {
        max-width: 16px;
        object-fit: contain;
        cursor: pointer;
      }

      &-error {
        width: 150px;
        position: absolute;
        top: 27px;
        left: -20px;
      }

      &-global {
        display: flex;
        flex-direction: column;

        > p {
          padding: 4px 0px 0px 24px;
          color: $inactive-grey;
          font-size: $font-size-xs;
          font-weight: $font-weight-default;
          line-height: 150%;
        }
      }
    }

    &-reason {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &-doc {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      &-info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        padding-right: 10px;
        border-right: 1px solid $grey-200;

        & > img {
          max-width: 20px;
          object-fit: contain;
        }
      }

      &-name {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-default;
        line-height: 19px;
        text-align: left;
        color: $primary-blue;
        cursor: pointer;
      }

      &-actions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;

        & img {
          max-width: 16px;
          object-fit: contain;
        }
      }
    }

    &-select {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px;
      gap: 24px;

      &-text {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-default;
        line-height: 15.6px;
        text-align: center;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}
