.s-sbHeader {
  width: 100%;
  min-height: 42px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  padding-bottom: 8px;
  cursor: pointer;
  position: sticky;
  top: 0px;
  background-color: #ffffff;
  z-index: 5;
  border-radius: $radius-modal;
  padding-top: 20px;
  // transition: background-color 0.5s;

  // &:hover {
  //   background-color: $light-grey;
  // }
  &--doc {
    padding-top: 34px;
  }

  &-container {
    background-color: #ffffff;
    position: sticky;
    top: 0px;
    z-index: 5;
  }

  &__cont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    &--doc {
      border-top: 1px solid $grey-200;
      padding-top: 6px;
    }
  }

  &__back {
    width: 16px;
    height: 16px;
    cursor: pointer;
    &-img {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }

    &-background {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      background-color: transparent;
      border-radius: $radius-circle;
    }
    &-background:hover {
      background-color: $border-blue;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    position: relative;
    height: 42px;
  }

  &__copy {
    width: 12px;

    &--display {
      display: none;
    }
  }

  &__text1 {
    font-family: $lato;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color: $sb-heading-color;
    position: relative;

    &--type2 {
      font-size: 20px;
      font-weight: $font-weight-bolder;
      line-height: 125%;
      color: $natural-black;
    }
  }

  &__text2 {
    font-family: $lato;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    margin-left: -8px;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;

    &--type2 {
      font-size: $font-size-ml;
      font-weight: $font-weight-default;
      line-height: 150%;
      margin-left: -13px;
    }

    &--tag {
      position: absolute;
      top: -20px;
      right: -64px;

      > div {
        background: transparent !important;
      }
    }
  }

  &__text3 {
    font-size: $font-size-sm2;
    font-weight: $font-weight-bolder;
    padding-left: 46px;

    span {
      color: $wood-smoke;
    }

    &--doc {
      padding-left: 0px;
    }
  }

  &__awaited {
    padding-left: 46px;
    font-family: $lato;
    font-size: $font-size-ml;
    font-weight: $font-weight-bold;
    line-height: 30px;
    text-align: left;
    display: flex;
    gap: 8px;

    &-title {
      font-family: Lato;
      font-size: $font-size-md;
      font-weight: $font-weight-default;
      line-height: 150%;
      background-color: $yellow-bg;
      padding: 4px 8px;
      border-radius: $radius-task;
    }

    &-text {
      color: $wood-smoke;
      font-size: $font-size-sm2;
    }
  }

  &__sb-download {
    max-width: 16px;
    object-fit: contain;
    cursor: pointer;

    &-background {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      background-color: transparent;
      border-radius: $radius-circle;
    }
    &-background:hover {
      background-color: $border-blue;
    }
  }

  &__dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    padding-right: 10px;

    &:hover {
      text-decoration: underline;
      color: $primary-blue;
    }

    & > p {
      font-size: $font-size-sm;
      color: $primary-blue;
    }

    & > img {
      width: 10px;
      object-fit: contain;
    }
  }

  &__backarrow {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $radius-round;
    background-color: $light-gray;
    cursor: pointer;

    & > img {
      max-width: 7px;
      object-fit: contain;
    }
  }

  &__tag {
    background-color: $white !important;
  }

  &__summary {
    display: flex;
    // background-color: $toast-alert-success;
    background-color: $wood-grey;
    padding: 11.5px 22px;
    border-radius: $radius-home-cards;

    &--left {
      display: flex;
      gap: 25px;
      padding-right: 25px;
      border-right: 1px solid $border-light-gray;
    }

    &--right {
      display: flex;
      gap: 25px;
      padding-left: 25px;
    }
  }

  &__box-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  &__box-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
  }

  &__box-text {
    font-size: $font-size-sm2;
  }

  &__box-number {
    font-size: $font-size-md;
    font-weight: $font-weight-bolder;
    & > span {
      font-size: $font-size-xs;
    }
  }
  &__close {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 24px;
    height: 24px;
    z-index: 10;
    cursor: pointer;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__lodgement {
    display: flex;
    padding-left: 46px;
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;

    &--negative {
      padding-left: 0px;
    }

    &-value {
      display: flex;
      > p {
        line-height: 150%;
        margin: 0px 5px;
        padding: 0px 5px;
        background: $green-bg;
        border-radius: $radius-tag;
      }
    }
  }
}
