.s-buyer {
  &__summary {
    display: flex;
    gap: 20px;
    justify-content: space-between;

    &-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px;
    }

    &-right {
      flex-grow: 1;
      display: flex;
      padding: 10px 50px;
      justify-content: flex-end;
      border-radius: $radius-home-cards;
      border: 1px solid $border-buyer-detail;
      background-color: $bg-buyer-detail;
    }
  }

  &__section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    gap: 10px;
    cursor: pointer;

    &-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;

      & > img {
        max-width: 26px;
        object-fit: contain;
      }
    }

    & > img {
      max-width: 16px;
      object-fit: contain;
      transform: rotate(-90deg);
    }

    &-text {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 30px;
      text-align: left;
    }

    &--active {
      background-color: $bg-navigation;
      border-radius: $radius-home-cards;
      color: $white;
      font-weight: $font-weight-bolder;
    }
  }

  &__details {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 60px;
    padding-right: 70px;
  }

  &__detail {
    background-color: $white;
    border: 1px solid $summary-border-gray;
    display: flex;
    justify-content: flex-start;
    border-radius: 8px;
    padding: 10px 40px 10px 30px;
    align-items: flex-start;
    gap: 15px;

    & > img {
      max-width: 20px;
      object-fit: contain;
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &-amount {
      display: flex;
      align-items: baseline;
      gap: 5px;
      font-family: $lato;
      font-size: $font-size-lg;
      font-weight: $font-weight-bolder;
      line-height: 24px;
      text-align: right;

      & > span {
        font-family: $lato;
        font-size: $font-size-xxs;
        font-weight: $font-weight-thin;
        line-height: 24px;
        text-align: right;
      }
    }

    &-count {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 18px;
      text-align: left;
    }
    &-buttons {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  &__total {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 70px;
    border-left: 1px solid $wood-smoke;
  }

  &__table {
    flex-grow: 1;
  }

  &__stats {
    &-bar {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-left {
      display: flex;
      gap: 40px;
      align-items: center;
    }

    &-tab {
      width: max-content;
      font-size: $font-size-sm2;
      line-height: 150%;
      padding-bottom: 8px;
      border-bottom: 2px solid transparent;
      cursor: pointer;

      &--active {
        font-weight: $font-weight-bolder;
        color: $primary-blue;
        border-bottom: 2px solid $primary-blue;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
    }

    &-details {
      display: flex;
      align-items: center;
      gap: 20px;
    }
    &-total {
      border-left: 1px solid $wood-smoke;
      padding-left: 20px;
    }
  }
}
