.s-blunoradio {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  // gap: 12px;
  &--vertical {
    flex-direction: column;
  }

  &--horizontal {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  &--title {
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
  }

  &__radio {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
    }

    &__label {
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
    }

    &__label-2 {
      margin-bottom: 16px;
    }
  }

  &__input {
    width: 15px;
    height: 15px;
    cursor: pointer;
      }
}
input.s-blunoradio__input[type="radio"]:disabled:checked {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  border-radius: 50%;
  box-sizing: border-box;
}

input[type="radio"].s-blunoradio__input:disabled:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: rgb(0, 117, 286);
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

input[type="radio"].s-blunoradio__input:disabled {
  border-color: #aaa;
  background-color: #e0e0e0;
  cursor: not-allowed;
}
