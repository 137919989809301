.upload-document{
    width: 100%;
    height: 100vh;
    background-color: $white;

    &__header {
        width: 100%;
    }

    &__content {
        padding: 30px 30px 0px 30px;
    }

}