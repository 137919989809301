.s-pagination {
  &__bar {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &__button {
    height: 32px;
    border: none;
    outline: none;
    padding: 8px 12px;
    border-radius: $radius-alert;
    font-family: $lato;
    font-size: $font-size-xxs;
    font-weight: $font-weight-bolder;
    line-height: 160%;
    text-align: center;
    cursor: pointer;
  }

  &__number {
    width: 32px;
    background-color: transparent;
  }

  &__direction {
    width: 48px;
  }

  &--hover {
    &:hover {
      color: $primary-blue;
    }
  }
}
