.s-tag {
  max-width: 70px;
  font-size: $font-size-sm2;
  padding: 2px 4px;
  background-color: $light-gray;
  border-radius: 4px;
  text-align: center;

  &--standard {
    background-color: $light-gray;
  }

  &--positive {
    color: $primary-blue;
    background: $blue-alert;
  }

  &--negative {
    color: $primary-red;
    background: $red-alert;
  }

  &--neutral {
    background: $light-gray;
  }

  &--info {
    color: $primary-yellow;
    background: $yellow-alert;
  }

  &--smalltag {
    font-family: $lato;
    font-size: $font-size-xxs;
    font-weight: $font-weight-default;
    line-height: 9.68px;
    text-align: left;
    background-color: transparent;
  }
}
