.demo {
    // width: 100%;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;

    &__userid {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;

        &-textinput {
            width: 400px;
        }
    }
}
