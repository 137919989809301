.s-toastV2 {
  width: 400px;
  height: 90px;
  display: flex;
  border: 1px solid $primary-black;
  border-radius: $radius-alert-popup;
  background-color: $white;
  box-shadow: 0px 4px 4px 0px rgba($color: $primary-black, $alpha: 0.25);

  &__left {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 52px;
    max-width: 52px;
    border-radius: $radius-alert-popup 0 0 $radius-alert-popup;
  }

  &__center {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 25px;
  }

  &__right {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 52px;
    max-width: 52px;
  }

  &__status-icon {
    max-width: 17px;
    object-fit: contain;
  }

  &__text {
    font-family: $lato;
    font-size: $font-size-md;
    font-weight: $font-weight-default;
    line-height: 20px;
    text-align: left;
  }

  &__close {
    max-width: 17px;
    object-fit: contain;
    cursor: pointer;
  }

  &--success {
    background-color: $toast-alert-success;
  }

  &--warning {
    background-color: $toast-alert-warning;
  }

  &--error {
    background-color: $toast-alert-error;
  }
}
