.mapirm {
  width: 100%;
  height: 100vh;
  background-color: $white;

  &__header {
    width: 100%;
  }

  &__content {
    padding: 30px 30px 0px 30px;
  }

  &__emptylist {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
    gap: 20px;

    &-icon {
      max-width: 156px;
      object-fit: contain;
      margin-left: -50px;
    }

    &-text {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 19.36px;
      text-align: center;
    }
  }

  &__modal {
    width: 100%;
    // overflow: hidden;
    // top: 67px;
    // left: 0px;
    // height: 70px;
    padding: 10px 0px 20px 0px;
    display: flex;
    justify-content: flex-start;
    gap: 20px;

    &-content {
      flex: 1;
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: $bg-white-gray;
      border-radius: $radius-home-cards;
    }

    &-help {
      width: 355px;
      display: flex;
      gap: 22px;
      padding: 10px 16px 16px 16px;
      background-color: $bg-white-gray;
      border-radius: $radius-home-cards;

      &-left {
        display: flex;
        align-items: flex-start;

        & > img {
          max-width: 22px;
          object-fit: contain;
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
      }

      &-title {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-default;
        line-height: 20px;
        text-align: left;
      }

      &-text {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-default;
        line-height: 20px;
        text-align: left;
        color: $border-black;
        white-space: wrap;
      }

      &-buttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
      }

      &--extra {
        & img {
          filter: $filter-primary-blue;
        }
      }
    }

    &-inputs {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 10px;
      margin-top: 6px;
    }
    &-dropdown {
      width: 243px;
      z-index: 10;
    }

    &-textbox {
      width: 250px;
    }

    &-uploadbox {
      width: 380px;
      background-color: transparent;

      &-error {
        font-size: $font-size-xs;
        color: $primary-red;
        margin-top: 5px;
        line-height: 120%;
      }

      &--extra {
        .s-upload-card__file-card {
          padding: 0px;
        }
      }
    }

    &-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      &--extra {
        width: 100px;
      }
    }
  }

  &__tablerow {
    border-bottom: 1px solid $wood-smoke;

    &-parent {
      border-bottom: 1px solid $white;
    }

    &-button {
      &--display {
        display: none;
      }
    }
  }

  &__tabledata {
    padding: 0px 15px !important;
  }
}
