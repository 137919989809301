.time-period {
  margin: 0px auto;
  display: flex;
  align-items: center;
  gap: 18px;
  // justify-content: space-around;
  width: max-content;
  // max-width: 360px;
  height: 40px;
  background: $white;
  box-shadow: $shadow-panel;
  border-radius: $radius-panel-card;
  padding: 6px 10px;

  div:first-child {
    p {
      padding-left: 12px;
    }
  }

  &__custom {
    margin: 0px auto;
    display: flex;
    align-items: center;
    gap: 18px;
    width: max-content;
    justify-content: center;
    // max-width: 360px;
    height: 40px;
    background: $white;
    box-shadow: $shadow-panel;
    border-radius: $radius-panel-card;
    padding: 6px 18px 6px 10px;
  }
  &__period {
    width: auto;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $inactive-grey;
    position: relative;
  }

  &__text {
    min-width: 38px;
    font-size: $font-size-sm2;
    padding: 0px 18px 0px 0px;

    &--inactive {
      &:hover {
        font-weight: $font-weight-bolder;
        color: $primary-blue;
        border-bottom: 1px solid $primary-blue;
      }
    }
  }

  &__period-img {
    width: auto;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $inactive-grey;
    padding: 0px 18px 0px 0px;

    img {
      &:hover {
        filter: $filter-primary-blue;
      }
    }
  }

  &__active {
    width: 124px;
    height: 32px;
    border: 1px solid $primary-blue;
    background-color: $white;
    border-radius: $radius-panel-card;
    color: $primary-blue;

    p {
      padding: 0px !important;
    }
  }

  &__vertical {
    width: 1px;
    height: 20px;
    background: $inactive-grey;
  }
}
