.o-login {
  display: flex;
  padding: 70px;
  height: 645px;

  &__static {
    padding: 30px 50px 0px 30px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 70px;
    justify-content: space-between;
    align-items: flex-start;
    border-right: 1px solid $primary-black;

    &-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      // align-items: flex-start;
      align-items: center;
      gap: 40px;
    }

    &-logo {
      max-width: 215px;
      object-fit: contain;
    }
  }

  &__highlights {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 45px;
    padding-left: 50px;

    &-item {
      display: flex;
      align-items: flex-start;
      gap: 8px;

      & > img {
        max-width: 20px;
        object-fit: contain;
        margin-right: 5px;
        padding-top: 5px;
      }
    }

    &-point {
      display: flex;
      gap: 6px;
      flex-wrap: wrap;
    }

    &-text {
      font-family: $lato;
      font-size: $font-size-lg;
      font-weight: $font-weight-default;
      line-height: 150%;
      text-align: left;
    }
    &--colored {
      font-weight: $font-weight-boldest;
      color: $primary-blue;
    }
  }

  &__powered {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: center;
    opacity: 0.6;

    &-title {
      font-family: $lato;
      font-size: $font-size-sm;
      font-weight: $font-weight-bolder;
      line-height: 14.52px;
      text-align: center;
    }

    &-content {
      display: flex;
      gap: 18px;
    }

    &-icon {
      max-height: 28px;
      object-fit: contain;
    }
  }

  &__flow {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    padding: 30px;
    background-color: $gray-white;
    border-radius: 0 $radius-home-cards $radius-home-cards 0;
  }

  &__enter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 33px;

    &--otp {
      gap: 8px;
    }
  }

  &__title {
    font-family: $lato;
    font-size: $font-size-lllg;
    font-weight: $font-weight-boldest;
    line-height: 33.89px;
    text-align: center;
    margin-bottom: 20px;
  }

  &__inputs {
    position: relative;
    width: 100%;
    max-width: 380px;
    display: flex;
    flex-direction: column;
    gap: 26px;
    margin: 0px 0px 26px 0px;
  }

  &__mobile {
    margin-top: 28px;
  }

  &__input {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;

    &__container {
      position: relative;
      width: 100%;
    }

    &-phone {
      position: absolute;
      z-index: 2;
      top: 10px;
      padding-left: 10px;
    }
    &-label {
      font-family: $lato;
      font-size: $font-size-md;
      font-weight: $font-weight-default;
      line-height: 16.94px;
      text-align: left;
      color: $wood-smoke;
    }

    &-input {
      padding: 12px 16px 12px 45px;
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-default;
      line-height: 19.36px;
      text-align: left;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }

  &__strong {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -28px;
    width: 16px;
    height: 16px;
    &--error {
      margin-top: -10px;
    }
  }

  &__continue {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 380px;
    &-button {
      width: 100%;
    }

    &--space {
      margin-top: 12px;
    }
  }

  &__subaction {
    font-size: $font-size-sm2;
    color: $primary-blue;
    cursor: pointer;
    margin-top: -25px;
    text-align: end;
    width: fit-content;
    align-self: end;
    line-height: 150%;
    &:hover {
      text-decoration: underline;
    }
  }

  &__terms {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 38px;

    &-content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }

    &-text {
      font-family: $lato;
      font-size: $font-size-sm;
      font-weight: $font-weight-default;
      line-height: 14.52px;
      text-align: center;
    }
  }

  &__notification {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    justify-content: flex-start;

    &-content {
      display: flex;

      align-items: center;
      gap: 5px;
      margin-bottom: 10px;
    }

    &-text {
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 24px;
      text-align: left;
      max-width: 380px;
    }
    &-edit {
      &-text {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-default;
        line-height: 24px;
        text-align: left;
        color: $primary-blue;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    &-phone {
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
    }
  }
  &__forgot {
    &-text {
      margin-top: -10px;
      max-width: 380px;
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 20px;
      text-align: left;
      color: $border-black;
    }
  }
  &__edit {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: $primary-blue;
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-bold;
    line-height: 19.36px;
    text-align: left;
    width: max-content;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    & > p {
      cursor: pointer;
    }
  }

  &__otp {
    &-item {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    &-error {
      &-container {
        display: flex;
        padding-bottom: 10px;
        gap: 8px;
        flex-direction: column;
        // align-items: center;
        justify-content: center;
      }
    }
    &-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      &-closer {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
      }
    }
  }

  &__resend {
    color: $primary-blue;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
    line-height: 19.36px;
    text-align: right;
    cursor: pointer;
    width: fit-content;

    &:hover {
      text-decoration: underline;
    }
  }

  &__error {
    padding-top: 5px;
    padding-bottom: 10px;
  }
  &__help {
    display: flex;
    justify-content: center;
    align-items: center;

    &-content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }

    &-text {
      font-family: $lato;
      font-size: $font-size-md;
      font-weight: $font-weight-default;
      line-height: 16.94px;
      text-align: center;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__countdown {
    font-size: $font-size-sm2;
    color: $wood-smoke;
    width: fit-content;
    &--small {
      font-size: $font-size-sm;
      color: $wood-smoke;
    }

    &--red {
      color: $primary-red;
    }
  }

  &__resource {
    width: 175px;
    padding: 60px 0px 0px 25px;
    > img {
      width: 100%;
      width: 175px;
    }
  }
}
