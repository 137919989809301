.mapirm-summary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 16px;
  padding: 8px;
  border-radius: $radius-home-cards;
  background-color: $white;

  &:hover {
    box-shadow: 1px 2px 2px $border-light-gray;
  }

  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
  }

  &__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    &-title {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bold;
      line-height: 30px;
      text-align: left;
      display: flex;
      gap: 8px;

      > p {
        margin-left: 10px;
        font-family: Lato;
        font-size: $font-size-md;
        font-weight: $font-weight-default;
        line-height: 150%;
        background-color: $yellow-bg;
        padding: 4px 8px;
        border-radius: $radius-task;
      }

      > span {
        color: $wood-smoke;
        font-size: $font-size-sm2;
      }
    }

    &-stepdone {
      max-width: 22px;
      object-fit: contain;
    }

    &-edit {
      max-width: 17px;
      object-fit: contain;
      cursor: pointer;
      &--hover {
        filter: $filter-dark-blue;
      }
    }
  }

  &__dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;

    &-text {
      font-family: $lato;
      font-size: $font-size-sm;
      font-weight: $font-weight-default;
      line-height: 30px;
      text-align: left;
      color: $primary-blue;

      &:hover {
        text-decoration: 1px solid underline $primary-blue;
        text-underline-offset: 2px;
      }
    }

    &-icon {
      max-width: 10px;
      object-fit: contain;
      transition: all 1 ease;
    }

    &--rotate {
      transform: rotate(180deg);
    }
  }

  &__wrapper {
    width: 100%;
  }

  &__content {
    width: 100%;
    padding: 0 20px 5px 20px;
    // border: 1px solid $border-light-gray;
    // border-radius: $radius-home-cards;
  }

  &__emptylist {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0px 30px 0;
    gap: 20px;

    &-content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    &-icon {
      max-width: 221px;
      object-fit: contain;
    }

    &-text {
      font-family: $lato;
      font-size: $font-size-md;
      font-weight: $font-weight-default;
      line-height: 22px;
    }

    &-header {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      padding-right: 20px;
    }
  }

  &__info {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;

    &-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
      }
    }

    &-title {
      font-family: $lato;
      font-size: $font-size-md;
      font-weight: $font-weight-default;
      line-height: 30px;
      text-align: left;
    }

    &-partition {
      font-family: $lato;
      font-size: $font-size-md;
      font-weight: $font-weight-default;
      line-height: 30px;
      text-align: left;
    }

    &-text {
      display: flex;
      align-items: center;
      gap: 5px;
      font-family: $lato;
      font-size: $font-size-md;
      font-weight: $font-weight-default;
      line-height: 30px;
      text-align: left;
      margin-top: -2px;

      &--link {
        color: $primary-blue;
        cursor: pointer;
        border-bottom: 1px solid transparent;

        &:hover {
          border-bottom: 1px solid $primary-blue;
        }
      }
      & > img {
        max-width: 17px;
        object-fit: contain;
        margin-top: -2px;
      }
    }

    &-tabs {
      display: flex;
      align-items: center;
      gap: 20px;
      margin: 0px;
    }

    &-tab {
      font-size: $font-size-sm2;
      line-height: 125%;
      color: $primary-blue;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &--active {
        text-decoration: underline;
        font-weight: $font-weight-bolder;
      }
    }
  }

  &__submit {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &__popup {
    width: 360px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: $radius-help;
    background-color: $white;
    padding: 20px;

    &-header {
      position: relative;
      padding: 10px;
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: $radius-search $radius-search 0 0;
      border-bottom: 0.5px solid $grey-200;
      background-color: $bg-settings-blue;

      & > img {
        position: absolute;
        top: 3px;
        right: 3px;
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }

    &-title {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 16px;
      text-align: left;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &-text {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 20px;
      letter-spacing: 0%;
      text-align: center;

      & > span {
        font-weight: $font-weight-boldest;
      }
    }

    &-subtext {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 20px;
      letter-spacing: 0%;
      text-align: center;
    }

    &-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
}
