.single-bulk {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: $white;
  border-radius: $radius-home-cards;
  padding: 10px;
  height: 100%;

  &__summary {
    width: 100%;
  }

  &__table {
    flex: 1 1 100%;
    width: 100%;
    border-radius: $radius-home-cards;
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-top: 15px;
    border-top: 1px solid $primary-blue;
  }
}
