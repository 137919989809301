.s-table {
  width: 100%;
  height: 100%;
  // padding: 24px;
  overflow: hidden;

  background-color: $white;

  &__textinput {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    position: relative;
    &-label {
      font-family: $lato;
      font-size: $font-size-xxs;
      font-weight: $font-weight-default;
      line-height: 12.1px;
      text-align: center;
      margin-bottom: -2px;
    }
    &-icon {
      right: -20px;
      position: absolute;
      width: 16px;
      height: 16px;
    }
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    width: 100%;
    // border-bottom: 1px solid $primary-blue;
  }

  &__title {
    font-size: $font-size-ml;
    font-weight: $font-weight-bolder;
  }

  &__link {
    width: 24px;
    height: 24px;
    cursor: pointer;
    transform: rotate(45deg);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    background-color: transparent;
    border-radius: $radius-circle;

    &:hover {
      background-color: $border-blue;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  &__default-view {
    flex-grow: 1;
    padding: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;

    &-illustration {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-icon {
      max-width: 200px;
      object-fit: contain;
    }

    &-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 10px;
    }
    &-title {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 22px;
      text-align: left;
    }
    &-text {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 22px;
      text-align: left;
    }
    &-action {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }
  }

  &__data {
    width: 100%;
    height: 95%;
    overflow: auto;
    display: flex;
    flex-direction: column;

    &--full {
      height: 100%;
    }
    &-expand {
      position: absolute;
      right: -36px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      flex-direction: row;
      gap: 9px;
      &-icon {
        max-width: 16px !important;
        margin-top: unset;
        width: 16px;
        &--plus {
          filter: $filter-black;
        }
      }
      &-divider {
        border: 1px solid $primary-blue;
        border-radius: 5px;
      }
    }
  }

  &__table {
    width: 100%;
    white-space: nowrap;
    border-collapse: collapse;
    overflow-x: scroll;

    tr {
      // border-bottom: 1px solid $primary-blue;
      height: 40px;
      vertical-align: middle;
    }

    th {
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      padding: 8px 15px;
      max-width: 150px;
      vertical-align: middle;

      & > div {
        display: flex;
        align-items: flex-start;
        gap: 8px;
      }

      & img {
        margin-top: 2px;
        max-width: 7px;
        object-fit: contain;
        cursor: pointer;
      }
    }

    td {
      text-align: left;
      font-size: $font-size-sm2;
      // padding: 15px;
      padding: 8px 15px;
      max-width: 180px;
    }

    &-flexcell {
      display: flex;
      justify-content: flex-start;
      align-items: center !important;
      gap: 8px;

      & input[type="checkbox"] {
        accent-color: $primary-black;
        cursor: pointer;
      }

      & img {
        max-width: 16px;
        object-fit: contain;
      }
    }

    &-header {
      &--compact {
        position: relative;
        &:before {
          content: "";
          display: block;
          border-top: 1px solid $primary-blue;
          position: absolute;
          width: 100%;
          top: 6px;
          height: 0px;
          left: 0px;
        }
        &-first {
          &:before {
            left: unset;
            right: 0px;
            width: 90%;
          }
          &:after {
            content: "";
            display: block;
            border-left: 1px solid $primary-blue;
            position: absolute;
            top: 6px;
            height: 5px;
            left: 10%;
          }
        }
        &-last {
          &:before {
            width: 90%;
          }
          &:after {
            content: "";
            display: block;
            border-left: 1px solid $primary-blue;
            position: absolute;
            top: 6px;
            height: 5px;
            right: 10%;
          }
        }
      }
    }

    &-cell-subtext {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 8px;
    }

    &-subtext {
      font-family: $lato;
      font-size: $font-size-xxs;
      font-weight: $font-weight-thin;
      line-height: 12.1px;
      text-align: left;
    }

    &-cell-smalltag {
      display: flex;
      gap: 5px;
      position: relative;
    }

    &-smalltag {
      margin-top: -2px;
    }

    &-cell-hztext {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      position: relative;

      &-decimal {
        font-family: $lato;
        font-size: $font-size-xxs;
        font-weight: $font-weight-default;
        line-height: 12.1px;
        text-align: left;
        margin-bottom: -2px;
        margin-left: -4px;
      }
      &-icon {
        width: 16px;
        height: 16px;

        &--edit {
          cursor: pointer;

          &:hover {
            filter: $filter-primary-blue;
          }
        }
      }
    }

    &-cell-copy {
      width: 20px;
      height: 15.5px;
      opacity: 0;
      position: absolute;
      right: -20px;

      &--visible {
        opacity: 1;
      }
    }

    &-hztext {
      font-family: $lato;
      font-size: $font-size-xxs;
      font-weight: $font-weight-default;
      line-height: 12.1px;
      text-align: center;
      margin-bottom: -2px;
    }

    &-action {
      width: 100%;
      display: flex;
      justify-content: center;

      & button {
        flex-grow: 1;
      }
    }

    &-imglink {
      max-width: 24px;
      object-fit: contain;
    }

    &-imgfunction {
      max-width: 20px;
      object-fit: contain;
      opacity: 0;

      &--hovered {
        opacity: 1;
      }

      &-background {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        background-color: transparent;
        border-radius: $radius-circle;
      }

      &-background:hover {
        background-color: $border-blue;
      }

      &--hidden {
        visibility: hidden;
      }
    }

    &--leftPadding {
      padding-left: 34px;
    }
    &--align-left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &--align-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &--align-center {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--footer {
      font-weight: $font-weight-bolder;
    }
    &-text-icon {
      width: 20px;
      height: 20px;
    }

    &-progress {
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 160px;

      &-title {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-bolder;
        line-height: 15.6px;
        text-align: left;

        & > span {
          font-size: $font-size-xs;
          font-weight: $font-weight-default;
          line-height: 13.2px;
        }
      }

      &-text {
        font-family: $lato;
        font-size: $font-size-xs;
        font-weight: $font-weight-default;
        line-height: 13.2px;
        text-align: left;
        color: $wood-smoke;
      }

      &-bar {
        width: 160px;
        height: 8px;

        & > span {
          display: block;
          height: 100%;
        }
      }
    }
  }

  &__data-row {
    border-bottom: 1px solid $wood-grey;
    &:not(.s-table__data-row--noHover):hover {
      cursor: pointer;
      // background-color: $toast-alert-success;
      // background-color: #edf7ff;
      box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
        0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
      // border-left: 0.5px solid rgba($color: $primary-black, $alpha: 0.1);
      // border-right: 0.5px solid rgba($color: $primary-black, $alpha: 0.1);
      // border-top: 1.5px solid rgba($color: $primary-black, $alpha: 0.1);
      // border: 1px solid rgba($color: $primary-black, $alpha: 0.5) 1px solid rgba($color: $primary-black, $alpha: 0.5) 0 1px solid rgba($color: $primary-black, $alpha: 0.5);
      // background-color: #d6ecff;
      // background-color: #f6f6f6;
      // background-color: #e7e7e7;
    }

    &--noBorder {
      border-bottom: none;
    }

    &--noHover {
      cursor: default;
    }

    &--border {
      border: 1px solid $wood-grey;
      border-radius: $radius-home-cards;
      padding: 10px 24px;
    }

    &--footer {
      border-top: 1.5px solid $primary-black;
      border-bottom: none;
    }

    &--hover {
      background-color: $toast-alert-success;
    }

    &--options-hover {
      box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
        0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
      background-color: $bg-white-gray;
    }

    &--disabled {
      cursor: not-allowed !important;
    }
    &--grey {
      opacity: 0.5;
    }

    &:not(.s-table__data-row--noHover):hover td:first-child {
      border-radius: 10px 0px 0px 10px;
    }

    &:not(.s-table__data-row--noHover):hover td:last-child {
      border-radius: 0px 10px 10px 0px;
    }

    &:not(.s-table__data-row--noHover):hover .s-button--positive {
      cursor: pointer;
    }
    &:not(.s-table__data-row--noHover):hover .s-button--negative {
      cursor: pointer;
    }
    &:not(.s-table__data-row--noHover):hover .s-button--neutral {
      cursor: pointer;
    }
    &:not(.s-table__data-row--noHover):hover .s-button--info {
      cursor: pointer;
    }
  }

  &__data-head {
    border-bottom: 1px solid $wood-grey;
    position: sticky;
    top: 0px;
    background-color: #eff3f9;
    z-index: 2;

    & > th:first-child {
      border-radius: $radius-home-cards 0 0 0;
    }
    & > th:last-child {
      border-radius: 0 $radius-home-cards 0 0;
    }

    &-icon {
      width: 24px;
      height: 24px;
      max-width: none !important;
    }
  }

  &--border {
    border: 1px solid $wood-grey;
    border-radius: $radius-home-cards;
    padding: 10px 13px;
  }

  &__ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--pending {
      font-size: $font-size-xs;
    }
    &--disabled {
      white-space: wrap;
    }
  }

  &__textbox {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > span {
      font-size: $font-size-xxs;
      line-height: 200%;
      font-weight: $font-weight-default;
    }
  }

  &__th {
    &--bank_flow {
      width: 27%;
    }
  }

  &__header {
    &-title {
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      padding-bottom: 2px;
      border-bottom: 1px solid $wood-smoke;
    }
    &-bottom {
      margin-top: 6px;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &-text {
      font-size: $font-size-xs;
      color: $border-black;
      font-weight: $font-weight-default;
    }

    &--invoice_docs {
      width: 68px;
    }

    &--transport_docs {
      width: 68px;
    }

    &--action {
      width: 112px;
    }
  }

  &__column {
    display: flex;
    align-items: center;
    gap: 10px;

    &-modal-bg {
      z-index: 200;
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba($color: $primary-black, $alpha: 0.7);
      cursor: auto;
    }
  }

  &__upload {
    display: flex;
    justify-content: center;
    text-align: center;
    position: relative;

    &-icon {
      max-width: 17px;
      object-fit: contain;

      &--hover {
        max-width: 20px;
      }
    }

    &-text {
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      font-size: $font-size-xs;
      color: $primary-blue;
      text-decoration: underline;
    }

    &-background {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 9px;
      background-color: transparent;
      border-radius: $radius-circle;
    }
    &-background:hover {
      background-color: $border-blue;
    }
    &-action {
      &-cont {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
      }
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      &:hover {
        .s-table__upload-action-icon {
          background-color: $blue-bg-lighter;
        }
      }
      &-icon {
        width: 22px;
        height: 22px;
        border-radius: $radius-circle;
        background: $bg-white-gray;
        padding: 3px;
        overflow: visible;
      }
    }
  }

  &__modal {
    padding: 20px;
    width: 420px;
    position: relative;

    &-title {
      width: 100%;
      border-bottom: 1.5px solid $primary-blue;
      font-size: $font-size-lg;
      font-weight: $font-weight-bolder;
      padding-bottom: 16px;
    }

    &-files {
      padding-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      &--invoice {
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-height: 300px;
        overflow: auto;
      }
    }

    &-doc {
      margin-top: 12px;
      max-height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      & .s-upload-card__file-card {
        padding: 2px 0px;
      }
    }

    &-second {
      margin-top: 16px;
      padding: 16px 0px;
      border-top: 1px solid $wood-grey;
      border-bottom: 1px solid $wood-grey;
    }

    &-radio {
      margin-top: 16px;
    }

    &-label {
      font-size: $font-size-sm2;
      color: $wood-smoke;
    }

    &-label-2 {
      margin: 16px 0px 10px 0px;
    }

    &-buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 16px;
    }

    &-tprt {
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
    }

    &-icon {
      // width: 20px;
      // height: 20px;
      filter: invert(22%) sepia(84%) saturate(2622%) hue-rotate(190deg)
        brightness(92%) contrast(97%);

      &-navigate {
        max-width: 20px;
        object-fit: contain;

        &:hover {
          filter: $filter-primary-blue;
        }
      }
      &-close {
        max-width: 25px;
        object-fit: contain;
      }
    }

    &-dropdown {
      max-width: 190px;

      &--other {
        margin-top: -10px;
      }
    }

    &-success {
      padding: 20px;
      width: 350px;
      position: relative;
    }

    &-pending {
      padding: 20px;
      width: 400px;
      position: relative;
    }
  }

  &__success {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 350px;
    gap: 24px;
    margin: 0px auto;

    &-logo {
      width: 58px;
      height: 58px;
      border: 1.5px solid $success-green;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        width: 28px;
        height: 28px;
      }
    }
    &-action {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-top: 10px;
    }

    &-text {
      text-align: center;
      white-space: normal;
      font-size: $font-size-sm2;
      line-height: 150%;

      > span {
        font-weight: $font-weight-bolder;
      }
    }

    &-tprt {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
    }

    &-icon {
      width: 28px;
      height: 28px;
    }
  }

  &__pending {
    &-header {
      padding-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-bottom: 2px solid $primary-blue;

      > img {
        width: 20px;
        height: 20px;
      }
    }

    &-content {
      padding: 16px 40px 16px 40px;
      text-align: center;
    }
  }
  &__options {
    position: relative;
    &-dots {
      display: flex;
      width: max-content;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      padding: 8px;
      &--hover-effect {
        background: transparent;

        &--blue {
          &:not(.s-table__options-dots--disabled):hover {
            background: $border-blue;
          }
        }
        &:not(.s-table__options-dots--disabled):hover {
          background: $border-blue;
        }
      }
      &--disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
    &-modal {
      position: fixed;
      z-index: 10;
    }
  }
  &__request-column {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
    justify-content: center;
    &-button {
      width: 100%;
      &--blue {
        background-color: $ribbon-blue !important;
      }
      &--hover {
        background-color: $white !important;
      }
    }
  }

  &__transport {
    display: flex;
    gap: 4px;
    margin: 16px 0px 18px 0px;

    &-header {
      font-size: $font-size-sm2;
      color: $wood-smoke;
    }

    &-text {
      font-size: $font-size-sm2;
      color: $natural-black;
      font-weight: $font-weight-bolder;
    }

    &-mode {
      display: flex;
      gap: 12px;
    }
  }

  &__accrodion-action {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    > img {
      height: 8px;
      width: 16px;
    }

    &--open {
      color: $primary-blue;
    }

    &--resolved {
      color: $primary-red;
    }
  }

  &__accordian-type {
    &--highlight {
      background-color: $light-blue;
      border-bottom: 1px solid $summary-border-gray !important;
    }
  }
  &__infinite {
    &-loader {
      width: 50px;
      margin: 0px auto;
    }
  }

  &__sb-status {
    padding: 5px;
    font-size: 11px;
    font-weight: $font-weight-default;
    border-radius: 4px;
    // white-space: normal;
    text-align: center;
    margin-left: 30px;
  }
}

@media only screen and (min-height: 950px) {
  .s-table {
    max-height: 643px;

    &__data {
      height: 100%;
    }
  }
}
