.basic-single {
  flex: 1;

  &__container {
    position: relative;
  }

  &__label {
    position: absolute;
    z-index: 100;
    top: -30px;
    background-color: white;
  }

  &--highlighted {
    border-color: $primary-blue;
    color: $primary-blue;
  }

  &__error {
    font-size: $font-size-xs;
    color: $primary-red;
    margin: 7px 0px 0px 5px;
  }
}

.dropdown {
  &__conatiner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__label {
    position: absolute;
    z-index: 2;
    top: 0px;
  }
  &__icon {
    height: 5px;
    &--error {
      // filter: brightness(0) saturate(100%) invert(20%) sepia(66%) saturate(6980%) hue-rotate(333deg) brightness(94%)
      //   contrast(87%);
    }
    &--rotate {
      transform-origin: center center;
      transform: rotateZ(180deg) translate(50%, 50%) !important;
    }
  }
}
