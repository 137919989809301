.s-copy-clipboard {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &__text {
    font-size: $font-size-sm2;
    font-weight: $font-weight-bold;
    position: absolute;
    top: 0px;
    left: 26px;
    width: max-content;

    &--below {
      top: 20px;
      left: -17px;
    }

    &--above {
      top: -20px;
      left: -17px;
    }

    &--right {
      top: 0px;
      right: 0px;
    }

    &--success {
      color: $green;
    }

    &--failure {
      color: $red-alert-main;
    }
  }

  &__tooltip-text {
    position: absolute;
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: max-content;
    background-color: $focus-out;
    color: $white;
    font-size: $font-size-sm;
    font-weight: $font-weight-default;
    padding: 4px 6px;
    border-radius: 6px;
    text-align: center;
  }
}
