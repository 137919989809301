.bulk-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  &__action {
    width: 100%;
  }

  &__table {
    height: calc(-402px + 100vh);
    border-bottom: 1px solid $primary-blue;
    &-container {
      width: 100%;
      background-color: $white;
      border-radius: $radius-home-cards;
      padding: 10px;
      flex: 1 1 100%;
    }
    &-button {
      &-container {
        display: flex;
        flex-direction: row;
        gap: 14px;
        align-items: center;
        margin-right: -2px;
      }
      width: 70px;
    }
  }
}

.bulk-bl {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: $white;
  border-radius: $radius-home-cards;
  padding: 10px;
  height: 100%;

  &__summary {
    width: 100%;
  }

  &__table {
    height: calc(-320px + 100vh);
    border-bottom: 1px solid $primary-blue;
    width: 100%;
    border-radius: $radius-home-cards;
  }
  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-top: 15px;
  }
}

.bulk-bl-docs {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: $white;
  border-radius: $radius-home-cards;
  padding: 10px;
  height: 100%;

  &__summary {
    width: 100%;
  }

  &__table {
    height: calc(-320px + 100vh);
    border-bottom: 1px solid $primary-blue;
    width: 100%;
    border-radius: $radius-home-cards;
    // padding: 10px;
  }
  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
  }
}

.bulk-ebrc {
}

.bulk-regularisation {
}
