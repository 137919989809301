.action-cta {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:48px;

    &__title {
        font-size: $font-size-lllg;
        line-height: 150%;
        font-weight: $font-weight-bold;
        margin: 0px;

    }

    &__buttons {
        display: flex;
        align-items: center;
        gap: 16px;
        cursor: pointer;
    }
}