.b-tickets {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px 0px 16px;
  }

  &__table-cont {
    width: 100%;
    height: calc(100vh - 250px);
    background-color: $white;
    padding: 5px 21.5px 20px 21.5px;
    border-radius: $radius-home-cards;
    box-shadow: $shadow-card;
  }

  &__table {
    max-height: 643px;
    height: calc(100vh - 330px);

    &--pagination {
      height: calc(100vh - 380px) !important;
    }
  }

  &__summary {
    display: flex;
    align-items: center;
    gap: 20px;

    &-card {
      width: 200px;
      height: 90px;
      background: $white;
      border: 1px solid $border-modal;
      border-radius: $radius-modal;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }

    &-icon {
      width: 29px;
    }

    &-right {
      width: 123px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-count {
      font-size: $font-size-sllg;
      font-weight: $font-weight-bolder;
      line-height: 100%;
      padding-bottom: 7px;
    }

    &-hr {
      background: $primary-blue;
      height: 1px;
      width: 100%;
      border: none;
      outline: none;
    }

    &-text {
      padding-top: 5px;
      font-size: $font-size-sm2;
    }
  }

  &__timeline-row {
    border-bottom: 1px solid $summary-border-gray;
    background-color: $light-blue;
  }

  &__modal {
    max-width: 425px;
    width: 425px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    padding: 15px 20px;

    &-body {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
    }

    &-title {
      width: 100%;
      font-size: $font-size-lg;
      font-weight: $font-weight-bolder;
      text-align: left;
      line-height: 34px;
      border-bottom: 1px solid $primary-blue;
      padding-bottom: 20px;
    }

    &-label {
      font-size: $font-size-sm2;
      line-height: 150%;
      padding-bottom: 12px;
      text-align: center;
    }
  }
}

.b-tickets-timeline {
  padding: 12px 12px 24px 12px;
  display: flex;
  width: 100%;
  
  &__left {
    width: 75%;
    border-right: 1px solid $summary-border-gray;
    padding-right: 32px;
  }

  &__right {
    padding-left: 34px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__right {
    width: 25%;
  }

  &__single {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
  }

  &__line {
    height: 75px;
    width: 0px;
    border-left: 1.5px solid $primary-black;
    outline: none;

    &--pending {
      border-left: 1.5px dotted $wood-smoke;
    }
  }

  &__point {
    width: 8px;
    height: 8px;
    border-radius: $radius-circle;
    background-color: $primary-black;

    &--pending {
      background-color: $wood-smoke;
    }
  }

  &__content {
    position: relative;
  }

  &__details {
    position: absolute;
    left: 10px;
    top: -4px;
    margin-left: 20px;
    display: flex;
    gap: 16px;

    &-title {
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 120%;

      > span {
        color: $primary-blue;
      }
    }

    &-left {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &--pending {
        color: $wood-smoke;
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &-text {
      font-size: $font-size-sm2;
      line-height: 120%;
      color: $wood-smoke;
    }

    &-description {
      font-size: $font-size-sm2;
      display: flex;
      flex-direction: column;
      gap: 2px;
      > span {
        font-weight: $font-weight-bolder;
      }

      > p {
        max-width: 580px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__review {
    &-buttons {
      padding: 12px 0px 6px 0px;
    }
  }
}
