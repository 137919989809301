.s-card {
  padding: 24px;
  border: 1px solid $border-light-gray;
  border-radius: $radius-home-cards;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  background-color: $white;

  &--alertCard {
    width: 360px;
    height: 274px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__title {
    font-family: $lato;
    font-size: $font-size-ml;
    font-weight: $font-weight-bolder;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }

  &__body {
    display: flex;
    flex-direction: column;
  }
}
