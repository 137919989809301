.s-timeline {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  background-color: $gray-white;
  border-radius: $radius-home-cards;

  &__card {
    min-width: 300px;
    height: 94px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: $radius-home-cards;
    border: 1px solid $wood-grey;
    background-color: $white;

    &--small {
      min-width: 250px;
      max-width: 250px;
    }
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-bolder;
    line-height: 15.73px;
    text-align: center;
  }

  &__partition {
    width: 100%;
    height: 1px;
    background-color: $primary-black;
  }

  &__review {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    &-text {
      font-family: $lato;
      font-size: $font-size-xs;
      font-weight: $font-weight-default;
      line-height: 12.1px;
      text-align: center;
      color: $primary-blue;
    }
  }

  &__submit {
    font-family: $lato;
    font-size: $font-size-xs;
    font-weight: $font-weight-default;
    line-height: 12.1px;
    text-align: center;
    color: $summary-amt-info;
  }

  &__query {
    font-family: $lato;
    font-size: $font-size-xs;
    font-weight: $font-weight-default;
    line-height: 12.1px;
    text-align: center;
    color: $summary-amt-info;
  }

  &__icon {
    display: flex;
    align-items: center;
    & > img {
      max-width: 20px;
      object-fit: contain;
    }
  }
  &__right-arrow {
    max-width: 50px;
    object-fit: contain;

    &--long {
      max-width: 100px;
    }

    &--small {
      max-width: 20px;
    }
  }

  &--disabled {
    opacity: 0.5;
  }

  &--small {
    gap: 10px;
  }
}

.v2-timeline {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 170px;
  width: 1010px;
  margin: 0px auto;
  padding-bottom: 0px;
  padding-top: 60px;

  &--completed {
    padding-bottom: 42px;
    height: 180px;
  }

  &__timeline {
    display: flex;
    align-items: center;
  }

  &__card {
    position: relative;
  }

  &__line {
    // width: 400px;
    width: 300px;
    // min-width: 400px;
    background: $summary-border-gray;
    height: 8px;
    outline: none;
    border: none;

    &--green {
      background: $light-green-2;
    }
  }

  &__title {
    position: absolute;
    top: -42px;
    left: 50%;
    transform: translateX(-50%);
    font-size: $font-size-ml;
    font-weight: $font-weight-bolder;
    line-height: 125%;
    text-align: center;
    width: max-content;

    &--init {
      color: $wood-smoke;
    }
  }

  &__icon {
    width: 32px;
    height: 32px;
    position: relative;
    z-index: 1;
    border-radius: $radius-circle;

    &--left {
      margin-right: -5px;
    }

    &--center {
      margin: 0px -5px;
    }

    &--right {
      margin-left: -5px;
    }

    &--pending {
      animation: glow-pending 2s infinite;
      @keyframes glow-pending {
        0%,
        100% {
          box-shadow: none;
        }
        50% {
          box-shadow: $green-glow;
        }
      }
    }

    &--failed {
      animation: glow-failed 3s infinite;
      @keyframes glow-failed {
        0%,
        100% {
          box-shadow: none;
        }
        50% {
          box-shadow: $red-glow;
        }
      }
    }

    &-bg {
      &--pending {
        background: $success;
      }

      &--completed {
        background: $success;
      }

      &--init {
        background: $summary-border-gray;
      }

      &--failed {
        background: $primary-red;
      }
    }
  }

  &__bottom {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    // height: 50px;
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  &__time {
    // max-width: 150px;
    font-size: $font-size-sm2;
    line-height: 125%;
    text-align: center;
    color: $wood-smoke;
    text-align: center;
  }

  &__actions-cont {
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    height: 32px;
  }
}
