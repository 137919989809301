.m-sb-summary {
  width: 100%;
  position: relative;

  &--doc-management {
    padding: 10px 20px;
    min-width: 1100px;
    max-height: 600px;
    overflow: scroll;
  }

  &__skeleton {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
