.s-upload-card-v2 {
  width: 100%;
  background-color: $bg-white-blue;
  min-width: 480px;
  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    cursor: pointer;

    &-img {
      height: 20px;
      object-fit: contain;
    }

    &-text {
      font-size: $font-size-sm2;
      color: $text-highlight;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__input {
    width: 100%;
    border: none;
    border-radius: 8px;
    overflow: hidden;
    transition: max-height 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 8px 10px;
    // height: 105px;
    max-height: 105px;
    border: 1px dashed $dotted-grey;

    &--drag {
      border: 1px solid $text-highlight;
    }

    &:hover {
      border: 2px dashed $primary-blue;
      padding: 7px 9px;
    }
  }

  &__upload {
    display: none;
  }
  &__secondary-text {
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
    line-height: 19px;
    text-align: left;
    color: $inactive-grey;
  }
  &__text {
    text-align: center;
    font-size: $font-size-sm2;
    &-cont {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
    }
  }
  &--disabled {
    opacity: 0.5;
    &:hover {
      text-decoration: none;
    }
  }
  &__list {
    max-height: 260px;
    overflow-y: scroll;
    overflow-x: visible;
    &--one {
      overflow: visible;
    }
  }
}

.s-upload-list-v2 {
  &-zip {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &-line {
    &-vertical {
      width: 1.2px;
      height: 17px;
      background-color: black;
      align-self: flex-start;
    }
    &-horizontal {
      width: 10px;
      height: 1px;
      background-color: black;
    }
  }
  &-left {
    border-left: 1px solid black;
  }
}

.s-uploaded-file-v2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 7px 0px;
  width: 100%;
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    flex-grow: 2;
  }
  &-break {
    width: 1px;
    height: 19px;
    background-color: $border-modal;
  }
  &__file {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
    }
    &-icon {
      height: 20px;
    }
    &-name {
      font-family: $lato;
      font-size: $font-size-sm2;
      line-height: 19px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-x: hidden;
      max-width: 180px;
      color: $primary-blue;
      &--failure {
        color: $border-black;
      }

      &--deeplink {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &-extension {
      font-family: $lato;
      font-size: $font-size-sm2;
      line-height: 19px;
      text-align: left;
    }
    &-size {
      font-family: $lato;
      font-size: $font-size-sm2;
      line-height: 19px;
      text-align: right;
    }
  }
  &__progress {
    &-info {
      margin-left: 10px;
      font-family: $lato;
      font-size: $font-size-xs;
      line-height: 19px;
      text-align: right;
    }
    &-bar {
      height: 6px;
      border-radius: 10px;
      background-color: $bg-neutral;
      &-completed {
        background-color: $success;
      }
    }
  }
  &__action {
    width: 16px;
    height: 16px;
    cursor: pointer;
    &-container {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
    &--disabled {
      opacity: 0.5;
      cursor: auto;
    }
  }
}
