.bl-bulk-summary {
  display: flex;
  flex-direction: column;
  // gap: 20px;

  &__header {
    display: flex;
    margin-top: 10px;
  }

  &__reason {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-top: $bg-white-gray;

    &-text {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 15.6px;
      text-align: left;

      & > span {
        font-weight: $font-weight-bolder;
      }
    }

    &-document {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;

      &-label {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-bolder;
        line-height: 15.6px;
        text-align: left;
      }

      &-value {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-default;
        line-height: 15.6px;
        text-align: left;
        color: $primary-blue;
        cursor: pointer;

        &:hover {
          text-decoration: 1px solid underline $primary-blue;
          text-underline-offset: 5px;
        }
      }
    }
  }

  &__back {
    display: flex;
    height: 100%;
    align-items: flex-start;
    & > img {
      max-width: 24px;
      object-fit: contain;
    }
  }

  &__left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
    margin-left: 20px;
  }

  &__right {
    flex: 0.6;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
  }

  &__bulkid {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    &-label {
      font-family: $lato;
      font-size: $font-size-lg;
      font-weight: $font-weight-default;
      line-height: 30px;
      text-align: left;
    }

    &-value {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      font-family: $lato;
      font-size: $font-size-lg;
      font-weight: $font-weight-default;
      line-height: 30px;
      text-align: left;
    }
  }

  &__bank {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    &-label {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 15.6px;
      text-align: right;
    }

    &-value {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 19.2px;
      text-align: left;
    }

    & > img {
      max-width: 16px;
      object-fit: contain;
    }
  }

  &__maker-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    &-label {
      display: inline-block;
      width: 136.75px;
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 15.6px;
      text-align: right;
    }

    &-value {
      display: inline-block;
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 19.2px;
      text-align: left;
    }
  }

  &__checker-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    &-label {
      display: inline-block;
      width: 136.75px;
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 15.6px;
      text-align: right;
    }

    &-value {
      display: inline-block;
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 19.2px;
      text-align: left;
    }
  }

  &__sb-count {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    &-label {
      font-family: $lato;
      font-size: $font-size-lg;
      font-weight: $font-weight-default;
      line-height: 30px;
      text-align: left;
    }

    &-value {
      font-family: $lato;
      font-size: $font-size-lg;
      font-weight: $font-weight-bolder;
      line-height: 30px;
      text-align: left;
    }
  }

  &__invoice {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    &-label {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 15.6px;
      text-align: left;
    }

    &-value {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 19.2px;
      text-align: left;
    }
  }

  &__progress {
    display: flex;
    flex-direction: column;
    gap: 5px;

    &-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
    }

    &-title {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 15.6px;
      text-align: left;

      & > span {
        font-size: $font-size-xs;
        font-weight: $font-weight-default;
        line-height: 13.2px;
      }
    }

    &-text {
      font-family: $lato;
      font-size: $font-size-xs;
      font-weight: $font-weight-default;
      line-height: 13.2px;
      text-align: left;
      color: $wood-smoke;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  &__instant {
    display: flex;
    padding: 10px 0px 0px 50px;
    margin-top: 10px;
    border-top: 1px solid $primary-blue;

    &-success {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;

      &-label {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-default;
        line-height: 15.6px;
        text-align: left;
      }

      &-value {
        font-family: $lato;
        font-size: $font-size-ml;
        font-weight: $font-weight-bolder;
        line-height: 19.2px;
        text-align: left;
      }
    }

    &-failed {
      flex: 0.6;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;

      &-label {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-default;
        line-height: 15.6px;
        text-align: left;
      }

      &-value {
        font-family: $lato;
        font-size: $font-size-ml;
        font-weight: $font-weight-bolder;
        line-height: 19.2px;
        text-align: left;
      }
    }
  }
}
