.sb-invoice {
  border: 1px solid $border-light-gray;
  border-radius: $radius-home-cards;

  &__visible {
    padding-bottom: 8px;
    display: grid;
    padding: 20px 75px 20px 75px;
    grid-template-columns: max-content max-content auto max-content max-content max-content;
  }

  &__row {
    display: grid;
    grid-template-columns: subgrid;
    padding: 7px 0px 7px 0px;
  }

  &__col {
    display: flex;
    gap: 4px;
    width: max-content;
    &-error {
      font-size: $font-size-md;
      font-weight: $font-weight-default;
      line-height: 150%;
      color: #e01e5a;
    }
  }

  &__text {
    font-size: $font-size-sm2;
    font-weight: $font-weight-bolder;

    & > span {
      color: $wood-smoke;
      white-space: break-spaces;
      font-weight: $font-weight-bold;
    }

    & > div {
      font-size: $font-size-xs;
      display: inline;
    }
  }

  &__hover {
    position: relative;

    &:hover &-text {
      visibility: visible;
    }
  }

  &__hover-img {
    width: 16px;
    height: 16px;
    cursor: pointer;
    position: relative;
    z-index: 1;
  }

  &__hover-text {
    visibility: hidden;
    position: absolute;
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    width: max-content;
    max-width: 300px;
    background-color: $focus-out;
    color: $white;
    font-size: $font-size-sm;
    padding: 4px 6px;
    border-radius: 6px;
  }

  &__hyperlink {
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
    color: $primary-blue;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: $primary-blue;
    }
  }

  &__toggle {
    width: max-content;
    grid-column: 1/7;
    grid-row: 4/5;
    // background: $toast-alert-success;
    background-color: $wood-grey;
    border-radius: $radius-home-cards;
    margin: 10px auto 10px auto;
    padding: 12px 42px;
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__box {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__img {
    width: 16px;
  }

  &__title {
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
  }

  &__number {
    font-size: $font-size-md;
    font-weight: $font-weight-bolder;
    & > span {
      font-size: $font-size-xs;
    }
  }

  &__currency {
    font-size: $font-size-xxs;
    font-weight: $font-weight-thin;
  }

  &__invoice-text {
    p {
      font-size: $font-size-md !important;
      font-weight: $font-weight-default !important;
      line-height: 125%;
      color: $border-black;
    }
  }
}
