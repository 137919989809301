.single-irm {
  width: 100%;
  background-color: $white;
  padding: 0px 20px 10px 20px;
  margin-top: 10px;
  border-radius: $radius-home-cards;
  box-shadow: $shadow-card;

  &__emptylist {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
    gap: 20px;

    &-icon {
      max-width: 156px;
      object-fit: contain;
      margin-left: -50px;
    }

    &-text {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 19.36px;
      text-align: center;
    }
  }

  &__table {
    margin-top: 40px;
  }

  &__modal-bg {
    z-index: 8;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(29, 28, 29, 0.7);
    cursor: auto;
  }
}
