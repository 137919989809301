.remind-alert {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    pointer-events: all;

    &__table {
        margin-top: 10px;
        max-height: 350px;
        overflow: auto;
    }

    &__action {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    &__cross {
        position: absolute;
        top: 15px;
        right: 15px;
        margin-bottom: 10px;
        max-width: 10px;
        object-fit: contain;
        cursor: pointer;
    }
}
