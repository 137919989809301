.s-sidebar {
  width: 100%;
  height: 100%;

  &__wrapper {
    width: 100%;
    height: 100%;
    padding: 0 10px 0px 0px;
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $radius-home-cards;
    padding-bottom: 24px;

    &-text {
      font-family: $aller;
      font-size: $font-size-xxxlg;
      padding-left: 10px;
      font-weight: $font-weight-boldest;
    }

    img {
      max-width: 184px;
      object-fit: contain;
      cursor: pointer;
    }
  }

  &__items {
    width: 100%;
    height: 90%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    font-size: $font-size-sm2;
    font-weight: $font-weight-bold;
  }

  &__top {
    display: flex;
    flex-direction: column;
    // gap: 0px;
    width: 100%;
    background-color: $bg-sidebar;
    // padding: 32px 14px 32px 14px;
    padding: 32px 0 10px 0;
    border-radius: $radius-home-cards;
    box-shadow: $shadow-card;
  }

  &__label {
    color: $white;

    &--selected {
      color: $primary-black;
    }
  }

  &__bottom {
    width: 100%;
    background-color: $bg-sidebar;
    padding: 16px 0;

    // padding: 32px 24px;
    // padding: 20px;
    border-radius: $radius-home-cards;
    box-shadow: $shadow-card;
  }

  &__item {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    gap: 18px;
    cursor: pointer;
    padding: 7px 20px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-left: 6px solid transparent;

    &--selected {
      // padding: 12px 20px;
      background-color: $white;
      border-left: 6px solid $primary-brand-blue;
      border-top: 1px solid $primary-brand-blue;
      border-bottom: 1px solid $primary-brand-blue;
      // border-radius: $radius-modal;
      box-shadow: 0 2px 2px 0 $primary-black;
    }

    &--disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    &:not(.s-sidebar__item--selected, .s-sidebar__item--disabled):hover {
      border-top: 1px solid $white;
      border-bottom: 1px solid $white;
      // border-radius: $radius-modal;
      border-left: 6px solid $white;
    }
  }

  &__icon {
    width: 20px;
    height: 20px;

    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
  }

  &__dropdown {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 5px;

    &--border {
      border-top: 1px solid $border-off-white;
    }
  }

  &__option-item {
    gap: 10px;
    width: 136px;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 5px 0px;
    margin-left: 40px;
    font-size: $font-size-sm;
    font-weight: $font-weight-default;
    line-height: 17px;
    justify-content: flex-start;
    color: $white;
    cursor: default;

    &--selected {
      background-color: $white;
      border-radius: $radius-alert;
      color: $primary-black;
    }
    &__icon {
      width: 16px;
      height: 16px;
    }
    &__button {
      border: 1px solid $white;
      border-radius: $radius-task;
      justify-content: center;
      cursor: pointer;
      &:hover {
        background: $white;
        color: $primary-black;
      }
      &--highlight {
        background: $white;
        color: $primary-black;
      }
    }
  }

  &__arrow {
    position: absolute;
    top: 16px;
    right: 12px;
    width: 16px;
    height: 16px;

    &--open {
      transform: rotate(180deg);
    }
  }
}
