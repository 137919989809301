.s-upload-list {
    // margin-top: 20px;

    &__header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid $solid-grey;
        font-weight: $font-weight-bolder;
    }

    &__list {
        max-height: 40vh;
        overflow-y: auto;
    }

    &__item {
        border-bottom: 1px solid $solid-grey;
    }
}
