.profile {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: $font-size-ml;
  font-weight: $font-weight-default;
  cursor: pointer;

  &__container {
    position: absolute;
    top: 64px;
    transform: translateY(-50%);
    right: -20px;
    z-index: 5;
    width: 190px;
    padding: 10px 16px;

    &--border {
      border: 1px solid $primary-blue;
      background-color: $white;
      border-radius: $radius-home-cards;
      box-shadow: $shadow-card;
    }
  }
  &__img {
    width: 32px;
    height: 32px;
    img {
      width: 32px;
      height: 32px;
      border-radius: $radius-circle;
    }
  }

  &__right {
    display: flex;
    justify-content: space-between;
    min-width: 120px;
    cursor: pointer;
  }

  &__dropdown {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-top: 12px;
    padding-top: 14px;
    border-top: 1px solid $summary-border-gray;

    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 14px;
      cursor: pointer;

      &-icon {
        width: 18px;
        height: 18px;
      }

      &-text {
        font-size: $font-size-sm;
        font-weight: $font-weight-default;

        &--pending:hover {
          text-decoration: 1px solid underline #e01e5a;
          text-underline-offset: 5px;
        }
      }
    }
    &-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
        margin-left: -5px;
      }
    }
  }

  &__text {
    color: $black;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 110px;

    &--blue {
      color: $primary-blue;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 110px;
    }
  }
}
