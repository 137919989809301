.s-loader {
  width: 350px;
  max-height: 260px;
  min-height: 162px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 20px 10px 10px 10px;
  border-radius: $radius-assistance;
  position: relative;

  &--more-padding {
    padding-top: 45px;
    gap: 60px;
  }

  &__cross {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 24px;
    cursor: pointer;
  }
  &__parent {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 500;
    background-color: rgba($color: $primary-black, $alpha: 0.8);
  }

  &__icon {
    position: relative;
    &-loader {
      position: absolute;
      top: -28px;
      left: -40px;
      max-width: 80px;
      object-fit: contain;
    }

    &-logo {
      max-width: 28px;
      object-fit: contain;
    }

    &-sucess {
      max-width: 62px;
      border-radius: 35px;
      overflow: visible;
      border: 2px solid $success-green;
      padding: 17px 17px 13px 13px;
    }
  }

  &__title {
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
    line-height: 24px;
    text-align: center;
    word-wrap: normal;
  }

  &__text {
    font-family: $lato;
    font-size: $font-size-ml;
    font-weight: $font-weight-default;
    line-height: 21px;
    text-align: center;
  }

  &--primary {
    background-color: $white;
  }
}
