.ebrc-summary {
  display: flex;
  flex-direction: column;
  padding: 11px 11px 0px 11px;
  background: $white;
  border-radius: $radius-alert-popup;
  min-width: 1100px;
  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 21px 0px 12px;
    background: $header-blue;
    border-bottom: 0.5px solid #d1d1d1;
    border-radius: 8px 8px 0px 0px;
    align-items: center;
    height: 45px;
    &-text {
      font-family: $lato;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 16px;
      color: $natural-black;
    }
    &-icon {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }
  &__heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 11px 72px 12.5px 23px;
    &-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 6px;

      &-text {
        & > span {
          font-family: $lato;
          font-size: $font-size-lg;
          font-weight: $font-weight-default;
          line-height: 30px;
          color: $border-black;
        }
        font-family: $lato;
        font-size: $font-size-lg;
        font-weight: $font-weight-default;
        line-height: 30px;
        color: $natural-black;
      }
      &-subtext {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-default;
        line-height: 19.5px;
        color: $natural-black;
      }
      &-bottom {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-bolder;
        line-height: 15.6px;
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 25.1px;
      &-top {
        display: flex;
        flex-direction: row;
      }
      &-text {
        font-family: $lato;
        font-size: $font-size-lg;
        font-weight: $font-weight-default;
        line-height: 30px;
        color: $natural-black;
        & > span {
          font-size: $font-size-sm2;
          line-height: 19.5px;
        }
      }
      &-label {
        font-family: $lato;
        font-size: $font-size-lg;
        font-weight: $font-weight-default;
        line-height: 30px;
        color: $border-black;
      }
      &-bottom {
        display: flex;
        flex-direction: row;
        align-items: center;

        gap: 6px;
        &-label {
          font-family: $lato;
          font-size: $font-size-sm2;
          font-weight: $font-weight-default;
          line-height: 15.6px;
        }
        &-icon {
          width: 16px;
          height: 16px;
        }
        &-text {
          font-family: $lato;
          font-size: $font-size-sm2;
          font-weight: $font-weight-bolder;
          line-height: 15.6px;
          color: $natural-black;
        }
      }
    }
  }
  &__table {
    padding: 8px 0px 16px 0px;
    &-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 60px;
      padding: 8px 15px 0px 0px;
      &--compact {
        padding-right: 115px;
      }
      &-label {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-bolder;
        line-height: 15.6px;
      }
      &-text {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-bolder;
        line-height: 19.2px;
        & > span {
          font-family: $lato;
          font-size: $font-size-xs;
          font-weight: $font-weight-bolder;
          line-height: 13.2px;
        }
      }
    }
  }
}
