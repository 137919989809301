.m-single-sb {
  width: 100%;
  height: 100vh;
  background-color: $white;
  padding: 32px 30px 0px 30px;

  &__status {
    margin-top: 36px;
    display: flex;
    flex-direction: column;
    gap: 36px;
    overflow: auto;
  }

  &__navbar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
    border-bottom: 1px solid $border-light-gray;
  }

  &__navtabs {
    font-family: $lato;
    font-size: $font-size-md;
    font-weight: $font-weight-bolder;
    padding: 0px 4px 10px 4px;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    cursor: pointer;

    &--active {
      border-bottom: 2px solid $primary-blue;
      color: $primary-blue;
    }
  }
}
