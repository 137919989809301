.onboarding {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
            to right,
            rgba(0, 105, 255, 0.2),
            rgba(255, 222, 88, 0.2),
            rgba(130, 99, 251, 0.2),
            rgba(152, 242, 128, 0.2)
        ),
        linear-gradient(to bottom, rgba(255, 255, 255, 0), 50%, rgba(255, 255, 255, 1)),
        linear-gradient(to top, rgba(255, 255, 255, 0), 50%, rgba(255, 255, 255, 1));
    &__card {
        border-radius: $radius-home-cards;
        box-shadow: 0px 4px 4px 0px rgba($color: $primary-black, $alpha: 0.25);
        background-color: $white;
        width: 100%;
        max-width: 1220px;
    }
}
