.s-textinput {
  position: relative;
  width: 100%;

  &__cont {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__input {
    width: 100%;
    height: 100%;
    padding: 8px 12px;
    border-radius: $radius-alert;
    border: 1px solid $summary-border-gray;
    outline: none;

    &:hover {
      border: 1px solid $primary-blue;
    }

    &:focus {
      border: 1px solid $primary-blue;
    }
    &__icon {
      position: absolute;
      width: 16px;
      top: 50%;
      transform: translate(-50%, -50%);

      &--left {
        left: 14px;
      }
      &--right {
        right: 0px;
      }
    }

    &--highlighted {
      border: 1px solid $primary-blue;
      color: $primary-blue;
    }

    &--disabled {
      color: $wood-smoke;
    }

    &--left {
      padding-left: 26px;
    }

    &--error {
      border-color: $primary-red;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  &__icon {
    position: absolute;
    width: 16px;
    top: 50%;
    transform: translate(-50%, -50%);

    &--left {
      left: 14px;
    }

    &--right {
      right: 14px;
    }

    &--cursor {
      cursor: pointer;
    }
  }

  &__fixed {
    position: absolute;
    z-index: 5;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 16px;
    font-size: $font-size-sm;
    font-family: $lato;
    color: $black;
    // padding-bottom: 2px;
  }
}
