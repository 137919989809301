.s-docsnap {
  display: flex;
  // justify-content: flex-start;
  align-items: center;
  // gap: 60px;

  &__left {
    flex: 0.4;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
  }

  &__srno {
    font-family: $lato;
    font-size: $font-size-md;
    font-weight: $font-weight-default;
    line-height: 19px;
    text-align: left;
    color: $placeholder-grey;
  }

  &__text {
    font-family: $lato;
    font-size: $font-size-md;
    font-weight: $font-weight-default;
    line-height: 19px;
    text-align: left;
  }

  &__right {
    flex: 0.6;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
