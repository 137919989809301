.s-task {
  padding: 6px 10px;
  display: flex;
  gap: 10px;
  position: relative;

  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 5px;

    & > img {
      max-width: 16px;
      object-fit: contain;
    }
  }

  &__center {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__text {
    font-family: $lato;
    font-size: $font-size-xs;
    font-weight: $font-weight-default;
    line-height: 18px;
    text-align: left;

    & > span {
      font-weight: $font-weight-bolder;
    }
  }

  &__button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-more {
      max-width: 20px;
      object-fit: contain;
      cursor: pointer;

      &-bg {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
        border-radius: $radius-circle;
        &:hover {
          background-color: $border-blue;

          & > img {
            filter: $filter-primary-blue;
          }
        }
      }
    }
  }

  &__duration {
    font-family: $lato;
    font-size: $font-size-xs;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: $wood-smoke;
  }

  &__modal {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 5;

    &-bg {
      padding: 6px 10px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: $white, $alpha: 0.8);
      border-radius: $radius-home-cards;
      border: 1px solid $primary-blue;
    }

    &-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;

      &--close {
        padding: 2px;
      }
    }

    &-icons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      padding-right: 5px;
    }

    &-icon {
      max-width: 16px;
      object-fit: contain;

      &-bg {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border: 1px solid $primary-black;
        border-radius: $radius-circle;
        cursor: pointer;

        &:hover {
          background-color: $primary-blue;
          border: none;

          & > img {
            filter: $filter-white;
          }
        }
      }

      &--cancel {
        max-width: 12px;
      }
    }
  }

  &--border {
    border: 1px solid $bg-white-gray;
    border-radius: $radius-home-cards;
  }
}
