.homeSummary {
  width: 100%;
  height: 194px;
  padding: 16px 21px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid $bg-white-gray;
  background-color: $white;
  border-radius: $radius-home-cards;
  cursor: pointer;

  &:hover {
    box-shadow: $shadow-card;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  &__title {
    font-family: $lato;
    font-size: $font-size-ml;
    font-weight: $font-weight-bolder;
    line-height: 20px;
  }

  &__link {
    cursor: pointer;
    transform: rotate(45deg);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    // background-color: transparent;
    // border-radius: $radius-circle;

    // &:hover {
    //   background-color: $border-blue;
    // }

    img {
      width: 24px;
      height: 24px;
    }
  }

  &__sb {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: max-content;
    align-self: center;
    gap: 8px;

    &-currencies {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 6px;
    }

    &-currency {
      height: 21px;
      padding: 2px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: $bg-white-gray;
      border-radius: $radius-task;
      color: $wood-smoke;

      & > p {
        font-family: $lato;
        font-size: $font-size-xs;
        font-weight: $font-weight-default;
        line-height: 20px;
        text-align: left;
      }

      &--active {
        background-color: $gray-white;

        & > p {
          color: $primary-blue;
        }
      }

      &:hover {
        & > p {
          color: $primary-blue;
        }
      }
    }

    &-distribution {
      display: none;
      justify-content: space-between;
      align-items: flex-start;
      gap: 10px;
      width: 100%;
      margin-bottom: 15px;
      border-radius: $radius-home-cards $radius-home-cards 0 0;
      &--active {
        display: flex;
      }
    }

    &-status {
      display: flex;
      align-items: center;
      gap: 30px;

      &-left {
        & > img {
          width: 51px;
          height: 54.5px;
          object-fit: contain;
        }
      }

      &-right {
        display: flex;
        flex-direction: column;
        gap: 2px;
        justify-content: flex-start;
        align-items: flex-start;
      }

      &-count {
        font-family: $lato;
        font-size: $font-size-sllg;
        font-weight: $font-weight-bolder;
        line-height: 24px;
        text-align: left;

        &--approved {
          color: $green;
        }
      }

      &-value {
        font-family: $lato;
        font-size: $font-size-sm2;
        // font-weight: $font-weight-bolder;
        line-height: 24px;
        text-align: left;

        & > span {
          font-family: $lato;
          font-size: $font-size-xs;
          font-weight: $font-weight-default;
          line-height: 24px;
          text-align: left;
        }
      }

      &-text {
        font-family: $lato;
        font-size: $font-size-xs;
        font-weight: $font-weight-default;
        line-height: 12px;
        text-align: left;
        color: $wood-smoke;
      }
    }

    &-total {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px 10px;
      background-color: $gray-white;
      border-top: 1px solid $primary-blue;
      border-radius: 0 0 $radius-home-cards $radius-home-cards;

      &-count {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-default;
        line-height: 18px;
        text-align: left;
        color: $border-black;

        & > span {
          font-size: $font-size-ml;
          font-weight: $font-weight-bolder;
          line-height: 18px;
          text-align: left;
          color: $primary-black;
        }
      }

      &-value {
        font-family: $lato;
        font-size: $font-size-ml;
        font-weight: $font-weight-bolder;
        line-height: 18px;
        text-align: right;

        & > span {
          font-family: $lato;
          font-size: $font-size-xs;
          font-weight: $font-weight-default;
        }
      }
    }
    &-categories {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 6px 12px;
      gap: 16px;
      background: $bg-white-gray;
      border-radius: $radius-search;
      width: max-content;
      &-text {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-default;
        height: 29px;
        text-align: left;
        padding: 6px 12px;
        background: $white;
        border-radius: 6px;
        display: flex;
        align-items: center;
        border: 1px solid $white;
        &:hover {
          border: 1px solid $primary-blue;
          color: $primary-blue;
        }
        &--active {
          border: 1px solid $primary-blue;
          color: $primary-blue;
        }
      }
    }
  }

  // Bar Graph CSS
  // &__sb {
  //     display: flex;
  //     flex-direction: column;
  //     // margin-top: 5px;
  //     border-top: 0.5px solid $wood-smoke;
  //     gap: 5px;

  //     &-top {
  //         display: flex;
  //         border-bottom: 1px solid $primary-black;
  //     }

  //     &-selector {
  //         display: flex;
  //         flex-direction: column;
  //         gap: 5px;
  //         padding: 5px 10px;
  //         border-right: 0.5px solid $wood-smoke;

  //         &-title {
  //             font-family: $lato;
  //             font-size: 9px;
  //             font-weight: $font-weight-default;
  //             line-height: 18px;
  //             text-align: center;
  //             color: $border-black;
  //         }
  //     }

  //     &-currencies {
  //         flex-grow: 1;
  //         display: flex;
  //         flex-direction: column;
  //         justify-content: space-between;
  //         align-items: center;
  //         gap: 5px;
  //     }

  //     &-currency {
  //         width: 88.8px;
  //         padding: 4px;
  //         display: flex;
  //         justify-content: flex-start;
  //         align-items: center;
  //         gap: 11px;

  //         & > img {
  //             max-width: 17px;
  //             object-fit: contain;
  //         }

  //         & > span {
  //             font-family: $lato;
  //             font-size: $font-size-xs;
  //             font-weight: $font-weight-default;
  //             line-height: 20px;
  //             text-align: left;
  //         }

  //         &--active {
  //             border-radius: $radius-home-cards;
  //             background-color: $bg-white-gray;

  //             &>span{
  //                 font-size: $font-size-sm2;
  //                 font-weight: $font-weight-bold;
  //             }
  //         }
  //     }

  //     &-bottom {
  //         display: flex;
  //         justify-content: flex-start;
  //         align-items: center;
  //     }

  //     &-total {
  //         display: flex;
  //         justify-content: center;
  //         align-items: center;
  //         padding: 0 10px;
  //         border-right: 0.5px solid $primary-black;

  //         &-distribution {
  //             flex-grow: 1;
  //             display: flex;
  //             justify-content: space-between;
  //             align-items: center;

  //             & > p {
  //                 flex: 1;
  //                 font-family: $lato;
  //                 font-size: $font-size-xs;
  //                 font-weight: $font-weight-default;
  //                 line-height: 18px;
  //                 text-align: center;
  //             }
  //         }

  //         &-count {
  //             width: 88.8px;
  //             font-family: $lato;
  //             font-size: $font-size-xs;
  //             font-weight: $font-weight-default;
  //             line-height: 18px;
  //             text-align: left;
  //             color: rgba($color: $primary-black, $alpha: 0.7);

  //             & > span {
  //                 color: $primary-black;
  //                 font-weight: $font-weight-bolder;
  //             }
  //         }

  //         &-open {
  //             display: inline-block;
  //             width: 7px;
  //             height: 7px;
  //             border-radius: 50%;
  //             background-color: $bg-white-gray;
  //         }

  //         &-submitted {
  //             display: inline-block;
  //             width: 7px;
  //             height: 7px;
  //             border-radius: 50%;
  //             background-color: $primary-blue;
  //         }

  //         &-approved {
  //             display: inline-block;
  //             width: 7px;
  //             height: 7px;
  //             border-radius: 50%;
  //             background-color: $success-green;
  //         }

  //         &-amt {
  //             font-weight: $font-weight-bolder;
  //         }
  //     }

  //     &-popup {
  //         width: 142px;
  //         padding: 6px 10px;
  //         display: flex;
  //         align-items: center;
  //         gap: 5px;
  //         border: 1px solid $bg-white-gray;
  //         border-radius: $radius-task;
  //         box-shadow: 0 4px 4px 0 rgba($color: $primary-black, $alpha: 0.25);
  //         background-color: $white;

  //         &-data {
  //             display: flex;
  //             flex-direction: column;
  //             justify-content: flex-start;
  //             align-items: center;
  //             gap: 5px;

  //             &-name {
  //                 font-family: $lato;
  //                 font-size: $font-size-xs;
  //                 font-weight: $font-weight-default;
  //                 line-height: 18px;
  //                 text-align: left;
  //                 color: $wood-smoke;
  //             }

  //             &-value {
  //                 font-family: $lato;
  //                 font-size: $font-size-xs;
  //                 font-weight: $font-weight-default;
  //                 line-height: 18px;
  //                 text-align: left;
  //                 & > span {
  //                     font-weight: $font-weight-boldest;
  //                 }
  //             }
  //         }

  //         &-bullet {
  //             width: 6px;
  //             height: 6px;
  //             border-radius: $radius-tag;
  //         }
  //     }
  // }

  &__separator {
    height: 1px;
    background-color: $primary-blue;
    width: 100%;
  }

  &__default {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 10px;
  }

  &__default-img {
    width: 100%;
    max-width: 100px;
    object-fit: contain;
    margin: 0px auto;
  }

  &__default-text {
    display: flex;
    gap: 4px;
    justify-content: center;
    > p {
      font-size: $font-size-md;
      color: $dotted-grey;
    }
  }

  &__irm {
    width: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;

    &-slide {
      width: 310px;
      padding: 0px 10px;
      border-radius: $radius-alert;
      background-color: $white;
      transform: scale(0.8);
      transition: all 0.3s ease-in-out;
      opacity: 0.25;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-bottom: 12px;

      &-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;

        & > img {
          max-width: 36px;
          object-fit: contain;
        }
      }

      &-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        gap: 5px;
      }
    }

    &-currency {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 24px;
      text-align: left;
    }

    &-amount {
      font-family: $lato;
      font-size: $font-size-sllg;
      font-weight: $font-weight-bolder;
      line-height: 24px;
      text-align: right;
    }

    &-count {
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 18px;
      text-align: right;
      color: $wood-smoke;

      & > span {
        color: $primary-black;
      }
    }

    &-middle {
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      padding: 12px 0;
    }

    &-progress {
      width: 100%;
      height: 6px;
      background-color: $bg-white-gray;
      border-radius: $radius-tag;
      overflow: hidden;

      &--utilised {
        height: 100%;
        background-color: $primary-blue;
        border-radius: $radius-tag 0 0 $radius-tag;
      }

      &--hovered {
        box-shadow: 0px 1px 4px 0px #00000040;
      }
    }

    &-utilised-info {
      font-family: $lato;
      font-size: $font-size-xs;
      font-weight: $font-weight-default;
      line-height: 24px;
      text-align: left;
      color: $wood-smoke;

      & > span {
        color: $green;

        & > strong {
          font-weight: $font-weight-bolder;
          font-size: $font-size-sm2;
        }
      }
    }

    &-bottom {
      display: flex;
      align-items: center;
      gap: 6px;

      &-bullet {
        width: 6px;
        height: 6px;
        border-radius: $radius-tag;
        background-color: $primary-blue;
      }
      &--disabled {
        opacity: 0.25;
      }
    }

    &-popup {
      // height: 54px;
      width: 142px;
      padding: 6px 10px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      border: 1px solid $bg-white-gray;
      border-radius: $radius-task;
      box-shadow: 0 4px 4px 0 rgba($color: $primary-black, $alpha: 0.25);
      background-color: $white;

      &-data {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;

        & > p {
          flex-grow: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 5px;
          font-family: $lato;
          font-size: $font-size-xs;
          font-weight: $font-weight-default;
          line-height: 18px;
          text-align: left;
          color: $wood-smoke;
        }
      }

      &-count {
        font-family: $lato;
        font-size: $font-size-sm2;
        font-weight: $font-weight-bolder;
        text-align: left;

        &--utilised {
          color: $green;
        }
      }

      &-bullet {
        width: 6px;
        height: 6px;
        border-radius: $radius-tag;
        background-color: $summary-border-gray;

        &--utilised {
          background-color: $primary-blue;
        }
      }
    }

    &-arrow {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: $radius-round;
      background-color: $bg-white-gray;

      &--prev {
        top: 30%;
        left: -34px;
      }

      &--next {
        top: 30%;
        right: -34px;
      }

      & > img {
        max-width: 5px;
        object-fit: contain;
      }

      &:hover {
        background-color: $blue-bg-light;
      }
    }
  }

  .slick-slider {
    padding: 10px;
    margin: 0 auto;
    width: 100%;
    overflow-x: hidden;
  }
  .slick-list {
    box-sizing: initial;
  }

  .slick-center .homeSummary__irm-slide {
    transform: scale(1);
    transition: all 0.3s ease-in-out;
    transition-delay: 0.2s;
    position: relative;
    opacity: 1;
  }
  .slick-slide:not(.slick-center) .homeSummary__irm-slide {
    transition-delay: 0s;
  }

  .slick-dots {
    position: relative;
    bottom: 5px;
  }
  .slick-dots li button:before {
    font-size: 7px;
  }
  .slick-dots li.slick-active button:before {
    top: 50%;
    transform: translateY(-50%);
    content: "";
    display: block;
    width: 20px;
    height: 7px;
    background-color: $primary-black;
    border-radius: 7px;
  }
}
