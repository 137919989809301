.s-irmDetails {
  width: 100%;
  min-height: 84px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $toast-alert-success;
  border: 1px solid $border-blue;
  border-radius: $radius-modal;
  padding: 0 28px;
  transition: display 1s ease;
  margin-top: 10px;

  &__left {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding-right: 70px;
  }

  &-container {
    flex-grow: 1;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
  }
  &__middle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  &__right {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    padding-left: 70px;
    border-left: 1px solid $border-light-gray;
  }

  &__title {
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: $dark-blue;
  }
  &__text {
    font-family: $lato;
    font-size: $font-size-md;
    font-weight: $font-weight-bolder;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
  }
  &--plain {
    color: $dark-blue;
  }
  &--primary {
    color: $dark-blue;
  }
  &--secondary {
    font-size: $font-size-sllg;
    color: $green;
  }

  &--hidden {
    display: none;
  }
}

.v2-irm {
  width: 100%;
  max-height: calc(100vh - 280px);
  overflow-y: scroll;

  border-top: 1.5px solid $primary-blue;

  &--hidden {
    display: none;
  }

  &__details {
    width: 100%;
    padding: 25px 40px;
    border: 1px solid $summary-border-gray;
    border-radius: $radius-home-cards;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-detail {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    &-label {
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 125%;
      color: $border-black;
    }

    &-icon {
      width: 24px;
      height: 24px;
    }

    &-value {
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 125%;

      > span {
        font-size: $font-size-xs;
        font-weight: $font-weight-default;
        line-height: 125%;
      }
    }

    &-card {
      display: flex;
      gap: 11px;
      align-items: center;
      justify-content: center;
    }

    &-img {
      width: 34px;
      height: 34px;
    }

    &-right {
      display: flex;
      flex-direction: column;
    }

    &-text {
      font-size: $font-size-sm2;
      line-height: 150%;
      color: $wood-smoke;
    }
  }

  &__codes {
    width: 100%;
    padding: 20px 30px;
    border: 1px solid $summary-border-gray;
    border-radius: $radius-home-cards;
    margin-top: 16px;

    &-top {
      display: flex;
      justify-content: space-between;
    }

    &-code {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-purpose-label {
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 150%;
    }

    &-purpose-value {
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 150%;
      padding-left: 6px;
    }

    &-ad-label {
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 125%;
      padding-right: 8px;
    }

    &-ad-icon {
      width: 24px;
      height: 24px;
    }

    &-ad-value {
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 125%;
      padding-left: 8px;
    }

    &-note {
      margin-top: 10px;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 180%;
      max-width: 700px;
      color: $border-black;
    }
  }

  &__sbDetails {
    width: 100%;
    margin-top: 16px;

    &-child {
      width: 100%;
      margin: 0px auto;
    }

    &-top {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    &-text {
      font-size: $font-size-sm;
      color: $wood-smoke;
    }

    &-count {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: $wood-grey;
      font-size: $font-size-md;
    }

    &-bottom {
      width: 100%;
      text-align: end;
      padding: 20px 14px;
      border-top: 1px solid $primary-black;
    }

    &-bottom-text {
      font-size: $font-size-sm2;

      & > span {
        font-size: $font-size-xxs;
        padding-left: 3px;
      }
    }
  }

  &-header {
    &__left {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__text {
      font-size: $font-size-sm2;
    }

    &__number-cont {
      position: relative;
      margin-top: 2px;
      display: flex;
      gap: 4px;
      align-items: center;
      height: 32px;
    }

    &__number {
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;

      & > span {
        font-size: $font-size-xs;
        font-weight: $font-weight-default;
      }
    }
  }

  &__bank-charges {
    margin-top: 16px;

    &-title {
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 150%;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &-edit {
      width: 18px;
      height: 18px;
      cursor: pointer;
      &:hover {
        filter: $filter-primary-blue;
      }
    }

    &-card {
      margin-top: 4px;
      width: max-content;
      padding: 10px 30px;
      border: 1px solid $summary-border-gray;
      border-radius: $radius-home-cards;
      background-color: $gray-white;
      display: flex;
      align-items: center;
      gap: 69px;

      &--newgap {
        gap: 45px;
      }
    }

    &-icon {
      width: 16px;
    }

    &-element {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    &-label {
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 125%;
    }

    &-value {
      font-size: $font-size-sm2;
      font-weight: $font-weight-bolder;
      line-height: 125%;

      > span {
        font-size: $font-size-xs;
        font-weight: $font-weight-default;
      }

      &--total {
        font-size: $font-size-lg;
        font-weight: $font-weight-bolder;
        line-height: 125%;

        > span {
          font-size: $font-size-sm2;
          font-weight: $font-weight-default;
        }
      }
    }
  }
  &__table {
    margin-top: 8px;
  }
}
