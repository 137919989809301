.s-alert-popup {
  position: relative;
  width: 400px;
  max-height: 260px;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 20px;
  gap: 16px;
  flex-direction: column;
  box-shadow: 0px 4px 4px 0px rgba($color: $primary-black, $alpha: 0.25);
  border-radius: $radius-alert-popup;
  background-color: $white;

  &__parent {
    position: relative;
    left: 0px;
    top: -30px;
    width: 100vw;
    height: 120vh;
    background-color: rgba(1, 1, 1, 0.5);
    padding-top: 98px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &--center {
    align-items: center;
    padding-top: 0px;
    height: 101vh;
  }

  &__header {
    margin: 0 20px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $radius-alert-popup $radius-alert-popup 0px 0px;
    gap: 10px;

    & img {
      max-width: 18px;
      object-fit: contain;
    }
  }

  &__title {
    font-family: $lato;
    font-size: $font-size-ml;
    font-weight: $font-weight-bolder;
    line-height: 24px;
    text-align: center;
  }

  &__content {
    padding: 0px 40px;
    text-align: center;
  }

  &__text {
    font-family: $lato;
    font-size: $font-size-sm2;
    font-weight: $font-weight-default;
    line-height: 20px;
    text-align: center;
  }

  &__action {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  &__cancel {
    position: absolute;
    top: 8px;
    right: 10px;

    & img {
      max-width: 25px;
      object-fit: contain;
      cursor: pointer;
    }
  }

  &--success {
    background-color: #d5ffe7;
  }

  &--error {
    background-color: #ffe4e8;
  }

  &--warning {
    background-color: #fdf9e9;
  }

  &--info {
    border-bottom: 2px solid $primary-blue;
  }
}
