.s-sbDetails {
  width: 100%;

  &__invoice {
    width: 100%;
    padding: 10px 0px;
    border-top: 1px solid $border-light-gray;

    &--blue {
      border-top: 1.5px solid $primary-blue;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
  }

  &__transport-docs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    &-heading {
      font-family: $lato;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
    }
    &-textfield {
      grid-row: 2;
      display: grid;
      grid-template-columns: subgrid;
      grid-column: 1/3;
      align-items: center;
      &-label {
        grid-column: 1;
        font-family: $lato;
        font-size: $font-size-sm2;
        line-height: 19px;
        text-align: left;
        color: $wood-smoke;
      }
    }
    &-parent {
      padding: 20px 0px;
      border: 1px solid $border-modal;
      border-radius: 10px;
      margin-bottom: 20px;
    }

    &-fields {
      display: flex;
      row-gap: 10px;
      justify-content: space-between;
      align-items: center;
      margin: 0px 75px;
    }
    &-container {
      display: flex;
      gap: 16px;
    }
    &-radio {
      grid-row: 1;
      grid-column: 2;
      grid-template-columns: subgrid;
      &-label {
        grid-row: 1;
        grid-column: 1;
        font-family: $lato;
        font-size: $font-size-sm2;
        color: $wood-smoke;
      }
    }

    &-input-radio {
      &:checked:disabled {
        color: $primary-blue;
      }
    }

    &__upload {
      grid-row: 2/3;
      grid-column: 3;
      grid-template-columns: subgrid;
      display: grid;
      flex-direction: row;
      align-items: flex-end;
      height: 100%;
      justify-content: space-between;
      justify-items: end;
      &-label {
        grid-column: 1;
        font-family: $lato;
        font-size: $font-size-ml;
        line-height: 19px;
        text-align: left;
      }
      &-card {
        grid-column: 3;
        width: max-content;
      }
    }
    &-dropdown {
      grid-row: 1;
      grid-column: 1/3;
      display: grid;
      grid-template-columns: subgrid;
      flex-direction: row;
      align-items: center;

      &-label {
        grid-column: 1;
        font-family: $lato;
        font-size: $font-size-sm2;
        color: $wood-smoke;
      }

      &-input {
        grid-column: 3;
      }
    }

    &-ecom {
      &-status {
        width: 100%;
        display: flex;
        font-size: $font-size-sm2;
        border-bottom: 1px solid $summary-border-gray;
        padding: 14px 75px;
        > span {
          color: $wood-smoke;
        }

        > p {
          padding-left: 6px;
        }
      }

      &-fields {
        display: grid;
        grid-gap: 14px;
        grid-template-columns: 1fr 1fr;
        padding: 14px 75px;
        > div {
          font-size: $font-size-sm2;
          display: flex;
          > span {
            color: $wood-smoke;
          }

          > p {
            font-weight: $font-weight-bolder;
            padding-left: 6px;
          }
        }
      }
    }
  }
  &__transport {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: center;

    &-doc-text {
      display: flex;
      align-items: center;
      gap: 4px;
      > span {
        width: max-content;
        font-size: $font-size-sm2;
        color: $wood-smoke;
      }

      > p {
        width: max-content;
        font-size: $font-size-md;
      }
    }
  }

  &__tr-text {
    font-size: $font-size-sm2;
    font-weight: $font-weight-bolder;

    & > span {
      color: $wood-smoke;
      font-weight: $font-weight-default;
    }
  }

  &__tr-icon {
    display: flex;
    gap: 8px;
    align-items: center;
    > img {
      height: 24px;
    }
    > span {
      font-size: $font-size-sm2;
      color: $natural-black;
      font-weight: $font-weight-default;
    }
  }

  &__tr-hr {
    width: 70px;
    height: 2px;
    background: $natural-black;
    outline: none;
    border: none;
  }

  &__tr-img {
    width: 60px;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 20px;

    & > p {
      font-family: Inter;
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
    }

    & > span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: $wood-grey;
      font-size: $font-size-sm2;
    }
  }

  &__ad-code {
    position: relative;
    font-size: $font-size-sm2;
    font-weight: $font-weight-bolder;
    padding-right: 56px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 2px;
    height: 16px;
    text-wrap: nowrap;

    & > img {
      height: 16px;
      padding: 0px 8px;
    }

    & > span {
      color: $wood-smoke;
    }

    & > div {
      position: absolute;
      right: 30px;
    }
  }

  &__invoice-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &--hidden {
    display: none;
  }

  &__ecom {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0px 0px 14px 0px;
    > p {
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 125%;
      color: $wood-smoke;
    }

    > span {
      font-size: $font-size-ml;
      font-weight: $font-weight-bolder;
      line-height: 125%;
    }
  }

  &__lodgement-buttons {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  &__bill-lodgement {
    padding: 20px 75px;
    align-items: center;
    display: flex;
    justify-content: space-between;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-text {
      font-size: $font-size-md;
    }
  }
}
