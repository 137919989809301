.s-add-irm {
  background-color: $white;
  width: 570px;
  padding: 20px 20px 15px 20px;
  border-radius: $radius-alert-popup;

  &__container {
    position: absolute;
    top: 0px;
    right: 0px;
    background: $focus-out;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  &__header {
    position: relative;
    width: 100%;
  }

  &__title {
    font-size: $font-size-lg;
    font-weight: $font-weight-bolder;
    line-height: 125%;
    padding-left: 10px;
  }

  &__close {
    position: absolute;
    right: 10px;
    top: 0px;
  }

  &__form {
    display: grid;
    gap: 24px 18px;
    padding: 20px;

    &-column {
      width: 100%;

      &--width {
        max-width: 231px;
      }
    }
  }

  &__buttons {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }

  &__uploaded {
    margin: 20px 20px 0px 20px;

    &-count {
      background: $light-green;
      padding: 10px 20px;
      width: 100%;
      font-family: $lato;
      font-size: $font-size-sm2;
      font-weight: $font-weight-default;
      line-height: 24px;
      text-align: left;
      border-radius: 5px;
    }
  }

  &__list {
    max-height: 260px;
    overflow-y: scroll;
    overflow-x: visible;
    padding: 12px 0px 12px 0px;
    border-bottom: 1px solid $primary-blue;
  }

  &__cta {
    width: max-content;
    padding-top: 11px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    gap: 8px;
    > img {
      width: 24px;
      height: 24px;
    }

    > p {
      font-size: $font-size-md;
      font-weight: $font-weight-bolder;
      line-height: 150%;
      color: $primary-blue;
    }
  }

  &--hide {
    display: none;
  }

  &__postupload {
    background-color: $white;
    box-shadow: none;
    border: none;
    padding: 0px 20px;

    &--inside {
      background-color: $white;
    }

    &--error {
      width: max-content;
      font-size: $font-size-xs;
      line-height: 150%;
      color: $primary-red;
      margin: 10px auto 0px auto;
    }

    &:hover {
      outline: none;
      border: none;
    }
  }
}
